import { useResourceUpdate, useLocale } from '@koopajs/react';
import { Button, IconButton } from '@mui/material';
import { useCallback } from 'react';
import { IMeeting } from 'types';
import { useSnackbar } from 'notistack';
import {
  Close as CloseIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon
} from '@mui/icons-material';
import { SystemStyleObject } from '@mui/system';

interface IMoveButton {
  direction: 'previous' | 'next';
  meetingId: string;
  topicToMoveId: string; // topic being clicked
  previousOrNextTopicId: string; // topic not being clicked, but being moved as a result
  sx?: SystemStyleObject;
}

export const MoveButton: React.FC<IMoveButton> = (props) => {
  const { direction, meetingId, topicToMoveId, previousOrNextTopicId, sx } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.TopicCard.MoveButton';

  const path = `/meetings/${meetingId}/topics/${topicToMoveId}/topics-switch?${
    direction === 'previous' ? 'switchWithPreviousTopicId' : 'switchWithNextTopicId'
  }=${previousOrNextTopicId}`;

  const { updateResource } = useResourceUpdate({
    path,
    id: '',
    customReducer: {
      path: {
        resourceType: '/meetings',
        resourceId: meetingId
      },
      mapping: (prevObj: unknown, newObj: unknown) => {
        return newObj as IMeeting;
      }
    }
  });

  // workaround to get the correct isProcessing state
  const { isProcessing } = useResourceUpdate({ path: '/meetings', id: meetingId });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleMoveTopic = useCallback(async () => {
    const res = await updateResource({});
    if (!res) {
      enqueueSnackbar(t(keyPrefix + '.snackbarMoveTopicFail'), {
        variant: 'error',
        action: (key) => (
          // eslint-disable-next-line react/jsx-no-bind
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        )
      });
    }
  }, [topicToMoveId, previousOrNextTopicId]);

  return (
    <Button
      onClick={handleMoveTopic}
      disabled={isProcessing}
      sx={sx}
      data-cy={'topic-card-meeting_move-' + (direction === 'previous' ? 'up' : 'down') + '-button'}
    >
      {direction === 'previous' ? <ArrowDropUpIcon opacity={0.6} /> : <ArrowDropDownIcon opacity={0.6} />}
    </Button>
  );
};

import { IMeeting } from 'types';
import { Stack, Typography, Divider, Box, Link as MUILink, Skeleton, IconButton } from '@mui/material';
import { DateTime } from 'luxon';
import { User } from '@koopajs/mui';
import { useLocale, useUserShow } from '@koopajs/react';
import { OpenInNew as OpenInNewIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { MeetingStateChip } from 'components/Meetings/MeetingStateChip';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { useScreenSize } from 'utils/useScreenSize';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import { MeetingCardButtons } from './MeetingCardButtons';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import { Link as RouterLink } from 'react-router-dom';
interface IMeetingCard {
  meeting: IMeeting;
}

export const MeetingCard: React.FC<IMeetingCard> = (props) => {
  const { meeting } = props;

  const { t } = useLocale();
  const keyPrefix = 'Dashboard.MeetingCard';
  const { user } = useUserShow();

  const {
    canUserContinueMeeting,
    hasMeetingStarted,
    isUserMeetingNoteTakerRole,
    meetingCommittee,
    canUserCurrentlyEditMeeting
  } = useMeetingStatus({
    meeting,
    user
  });

  const { isMobileScreen, isScreenAboveMd } = useScreenSize();

  const renderViewMeetingIconButton = (): JSX.Element => {
    return (
      <IconButton
        aria-label={t('common:viewMeeting')}
        component={RouterLink}
        to={{
          pathname: `/meetings/${meeting.id}`,
          state: { activePath: '/' }
        }}
        sx={{ color: 'primary.main' }}
      >
        <VisibilityIcon />
      </IconButton>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '24px',
        borderRadius: '4px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid #dddddd'
      }}
      data-cy="dashboard_meeting-card"
    >
      <Box>
        {/* HEADER */}
        <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ md: 'center' }}
            sx={{ fontSize: '16px', mb: { xs: 2, md: 0 } }}
          >
            {/* meeting state chip mobile (+ mobile view) */}
            <Stack flexDirection="row" justifyContent="space-between" sx={{ display: { md: 'none' } }}>
              <MeetingStateChip meeting={meeting} sx={{ mb: 2 }} />
              {/* mobile view button */}
              {canUserCurrentlyEditMeeting && (
                <Box sx={{ display: { sm: 'none' } }}>{renderViewMeetingIconButton()}</Box>
              )}
            </Stack>
            {/* Date & Time */}

            <Box>
              <DateTimeStartEnd
                isoStringDate={
                  meeting.startDateTime ||
                  (meeting?.startDate
                    ? DateTime.fromFormat(`${meeting.startDate}`, 'yyyy-MM-dd').toString()
                    : undefined)
                }
                separator={isMobileScreen ? undefined : ' | '}
                dateFormat="DATE_HUGE"
                isoStringStart={
                  meeting.startDateTime ||
                  (meeting.startTime &&
                    DateTime.fromFormat(meeting?.startTime as string, 'HH:mm', {
                      zone: 'UTC'
                    }).toString())
                }
                isoStringEnd={meeting.endDateTime}
                showNoDateNoTime={true}
              />
            </Box>

            {/* Chip desktop */}
            {isScreenAboveMd && <MeetingStateChip meeting={meeting} sx={{ alignSelf: 'center', ml: 2 }} />}
          </Stack>

          {/* Actions buttons desktop */}
          {isScreenAboveMd && <MeetingCardButtons meeting={meeting} />}
        </Stack>
        <Divider sx={{ mt: 2 }} />
      </Box>

      <Stack direction={{ xs: 'column', md: 'row' }} alignItems={{ md: 'flex-end' }}>
        <Box sx={{ width: '100%' }}>
          {/* Title */}
          <Typography
            sx={{ my: 2, fontSize: '16px', overflow: 'hidden', textOverflow: 'ellipsis' }}
            variant="body1"
            component="h3"
            data-cy="dashboard_meeting-card_meeting-title"
          >
            {meeting.title}
          </Typography>

          {/* Committee, location, participants */}
          <Typography
            variant="body2"
            sx={{
              opacity: '0.6',
              mb: 1,
              display: 'flex',
              flexWrap: 'wrap',
              minWidth: '0px',
              overflow: 'hidden',
              '&& > *': {
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }
            }}
          >
            {meetingCommittee && (
              <>
                <span>{meetingCommittee}</span>
                <span style={{ marginRight: '10px', marginLeft: '10px' }}>&#183;</span>
              </>
            )}

            <span>
              {meeting.location?.type
                ? t(`common:locationType.${meeting.location.type}`)
                : t('common:noLocation')}
            </span>

            <span
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                display: isMobileScreen ? 'none' : 'inline'
              }}
            >
              &#183;
            </span>
            <span style={{ flexBasis: isMobileScreen ? '100%' : '', marginLeft: 0 }}>
              {t(`${keyPrefix}.participantsInvited`, { count: meeting.participants?.length || 0 })}
            </span>
          </Typography>
          {meeting.location?.url && (
            <Box sx={{ mb: 1 }}>
              <MUILink
                href={meeting.location?.url}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: 'secondary.light'
                }}
                target="_blank"
                rel="noopener"
              >
                <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">{t(keyPrefix + '.meetingLink')}</Typography>
              </MUILink>
            </Box>
          )}
          {meeting.location?.address && (
            <Box sx={{ whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'end', mb: 1 }}>
              <LocationOnIcon fontSize="small" sx={{ mt: '2px', mr: 0.5, opacity: '0.6' }} />
              <Typography variant="body2" sx={{ opacity: '0.6' }} noWrap={true} width="350px">
                {meeting.location?.address}
              </Typography>
            </Box>
          )}
        </Box>

        {canUserCurrentlyEditMeeting && (
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>{renderViewMeetingIconButton()}</Box>
        )}

        {/* Message box: Someone is taking notes */}
        {hasMeetingStarted &&
          meeting.currentNoteTakerId &&
          isUserMeetingNoteTakerRole &&
          !canUserContinueMeeting && (
            <Box
              sx={{
                padding: '14px',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                border: '1px solid #dddddd',
                maxWidth: '450px',
                mt: { xs: 2, md: 4 }
              }}
              data-cy="dashboard_meeting-card_notetaker-banner"
            >
              <Stack direction="row" spacing={1}>
                <PersonIcon color="action" fontSize="small" />

                <Typography variant="caption" sx={{ color: '#777777', fontSize: '14px' }}>
                  <User
                    id={meeting.currentNoteTakerId}
                    // eslint-disable-next-line react/jsx-no-bind
                    render={(profile) => (
                      <>
                        {profile?.username || (
                          <Skeleton variant="text" sx={{ display: 'inline-block', width: '100px' }} />
                        )}
                      </>
                    )}
                  />{' '}
                  {t(keyPrefix + '.isTakingNotes')}
                </Typography>
              </Stack>
              <Typography variant="caption" sx={{ mt: 1, color: '#777777', fontSize: '14px' }}>
                {t(keyPrefix + '.AskTheUserToQuitMeeting')}
              </Typography>
            </Box>
          )}
      </Stack>

      {/* Actions buttons mobile and iPad */}
      {!isScreenAboveMd && (
        <Stack sx={{ mt: 2 }} direction={{ sm: 'row' }} justifyContent="space-between">
          <MeetingCardButtons meeting={meeting} />

          {/* iPad & desktop view button */}
          {canUserCurrentlyEditMeeting && (
            <Box sx={{ ml: 'auto', mt: { xs: 1, sm: 0 }, display: { xs: 'none', sm: 'block' } }}>
              {renderViewMeetingIconButton()}
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
};

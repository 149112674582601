import React, { useCallback } from 'react';
import { Dialog } from '@koopajs/mui';
import { useComponentVisibility, useLocale, useResourceList, useResourceShow } from '@koopajs/react';
import { ICommittee, IMeeting, VoteOutcome } from 'types';
import { Box, Alert, Stack, Button } from '@mui/material';
import { TextField } from 'components/temp/TextFieldTemp';
import { Switch } from 'components/temp/Switch';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTopic } from 'components/hooks/useTopic';
import { AutocompleteTemp } from 'components/temp/Autocomplete';
import { IUserPublicProfile } from '@koopajs/app';

export const EditProposition: React.FC = () => {
  const keyPrefix = 'Dialogs.EditProposition';
  const { t } = useLocale();

  const dialog = useComponentVisibility('editProposition');
  const { meeting, topicId, defaultValues } = dialog.getContext() as {
    meeting: IMeeting;
    topicId: string;
    defaultValues: {
      resolutionOutcomeMover: string | undefined;
      resolutionOutcomeSeconder: string | undefined;
      isResolution: boolean;
      resolutionVoteOutcome: VoteOutcome;
    };
  };

  const { resource: committee } = useResourceShow<ICommittee>({
    path: '/committees',
    id: meeting?.committeeId
  });

  const { resources: users } = useResourceList<IUserPublicProfile>({
    path: '/users',
    searchParams: { size: 50 }
  });

  const { updateTopic } = useTopic({ meetingId: meeting?.id, topicId, isMeetingStarted: true });

  const handleSubmit = useCallback(
    async (data: object): Promise<boolean> => {
      const formData = data as {
        resolutionOutcomeMover: string;
        resolutionOutcomeSeconder: string;
        isResolution: boolean;
      };

      try {
        if (formData.isResolution && defaultValues.resolutionVoteOutcome === 'reported') {
          formData.isResolution = false;
        }

        const res = await updateTopic(formData);
        return res;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    [updateTopic]
  );

  return (
    <>
      <Dialog.Form
        dialogKey="editProposition"
        onSubmit={handleSubmit}
        i18n={{ keyPrefix: keyPrefix + '.DialogForm' }}
        maxWidth="md"
        leftAction={<Button onClick={dialog.setHidden}>{t('common:labelCancel')}</Button>}
        dialogProps={{ className: 'rr-block' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} data-cy="edit-proposition-form">
          <Alert icon={<ErrorOutlineIcon fontSize="inherit" />} severity="info">
            {t(keyPrefix + '.alertWarning')}
          </Alert>
          <Stack direction="row" spacing={2}>
            <Box sx={{ width: '100%' }}>
              <AutocompleteTemp
                i18n={{ keyPrefix: keyPrefix + '.DialogForm.TextFieldMover' }}
                name="resolutionOutcomeMover"
                validationRules={{ maxLength: 100 }}
                isOptional={true}
                options={users?.map((u) => u.username || '')}
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <AutocompleteTemp
                i18n={{ keyPrefix: keyPrefix + '.DialogForm.TextFieldSeconder' }}
                name="resolutionOutcomeSeconder"
                validationRules={{ maxLength: 100 }}
                isOptional={true}
                options={users?.map((u) => u.username || '')}
              />
            </Box>
          </Stack>
          {defaultValues?.resolutionVoteOutcome !== 'reported' && committee?.isTakingResolutions && (
            <Switch
              testId="edit-proposition-isResolution-switch"
              isChecked={Boolean(defaultValues?.isResolution)}
              name="isResolution"
              i18n={{
                keyPrefix: 'MeetingStateInProgress.ResolutionFields.RecordVoteDialog.SwitchAddToResolutions'
              }}
            />
          )}
        </Box>
      </Dialog.Form>
    </>
  );
};

import { CommitteeMemberRole, CommitteeMemberTitle, ICommitteeMember } from 'types';
import { usePermissions, useResourceUpdate } from '@koopajs/react';
import { Box, Button } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { useCallback, useState } from 'react';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { EditCommitteeMemberFields } from './EditCommitteeMemberFields';
import { DateTime } from 'luxon';
import { committeeMemberRoles } from 'utils/committeeMembers/committeeMemberRoles';
import { useAppSnackbar } from 'components/hooks/useAppSnackbar';
import { CommitteeMemberAccordion } from './CommitteeMemberAccordion';
import { useScreenSize } from 'utils/useScreenSize';
import { CommitteeMemberTableRowCells } from './CommitteeMemberTableRowCells';
import EditIcon from '@mui/icons-material/Edit';

interface ICommitteeMemberRowProps {
  committeeMember: ICommitteeMember;
  isEditable: boolean;
  handleSetCommitteeMemberEditable: (committeeMemberId: string) => () => void;
  handleUnsetCommitteeMemberEditable: () => void;
  isLastItem?: boolean;
}

export const CommitteeMemberRow: React.FC<ICommitteeMemberRowProps> = (props) => {
  const {
    committeeMember,
    handleSetCommitteeMemberEditable,
    handleUnsetCommitteeMemberEditable,
    isEditable,
    isLastItem
  } = props;

  const { t } = useLocale();
  const keyPrefix = 'OrganizationCommittee.CommitteeMemberRow';

  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const { triggerSnackbar } = useAppSnackbar();

  const { isScreenAboveMd } = useScreenSize();

  const { updateResource } = useResourceUpdate({
    path: `/committees/${committeeMember.committeeId}/members`,
    id: committeeMember.id
  });

  const [isAccordionExpanded, setAccordionExpanded] = useState(false);

  const handleChangeAccordion = useCallback((event: React.SyntheticEvent, expanded: boolean) => {
    setAccordionExpanded(expanded);
  }, []);

  const handleSubmit = useCallback(async (formData: object): Promise<boolean> => {
    const payload = { ...formData } as {
      startAt: string;
      endAt: string;
      title: CommitteeMemberTitle;
      roles: {
        role: CommitteeMemberRole;
        isOptional?: boolean;
      }[];
    };

    // remove falsy roles
    let updatedRoles = payload.roles?.filter((role) => role.role);
    // sets reviewMeetings to be optional by default
    updatedRoles = updatedRoles?.map((role) =>
      role.role === 'reviewMeetings' ? { role: 'reviewMeetings', isOptional: true } : role
    );

    payload.roles = updatedRoles;

    if (payload.startAt) payload.startAt = new Date(payload.startAt + `T00:00:00Z`).toISOString();
    if (payload.endAt) payload.endAt = new Date(payload.endAt + `T00:00:00Z`).toISOString();

    const res = await updateResource(payload);

    if (res) {
      handleUnsetCommitteeMemberEditable();
    } else {
      triggerSnackbar({ snackbarText: t(keyPrefix + '.snackbarEditFail'), variant: 'error' });
    }

    return res;
  }, []);

  const defaultValues = {
    startAt:
      committeeMember.startAt && DateTime.fromISO(committeeMember.startAt).toUTC().toFormat('yyyy-MM-dd'),
    endAt: committeeMember.endAt && DateTime.fromISO(committeeMember.endAt).toUTC().toFormat('yyyy-MM-dd'),
    title: committeeMember.title,
    roles: committeeMemberRoles.map((role) => {
      const roleDefaultValue = committeeMember.roles?.find((r) => r.role === role);

      if (roleDefaultValue) {
        return roleDefaultValue;
      } else {
        return { role: '' };
      }
    })
  };

  return (
    <>
      {isEditable && isUserWorkspaceOwner ? (
        <FormControllerTemp
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
          onSubmitSuccessResetType="NO_RESET"
          style={
            isScreenAboveMd
              ? { display: 'table-row' }
              : { padding: '24px 0', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }
          }
          formProps={{
            role: isScreenAboveMd ? 'row' : undefined,
            'data-cy': 'committee-member-row_table-row-editable'
          }}
        >
          <EditCommitteeMemberFields userId={committeeMember.userId} />
        </FormControllerTemp>
      ) : (
        <>
          {isScreenAboveMd ? (
            // COMMITTEE MEMBER TABLE ROW (DESKTOP)
            <Box
              sx={{ display: { xs: 'none', md: 'table-row' }, '& > div': { p: '24px 12px' } }}
              role="row"
              data-cy="committee-member-row_table-row"
            >
              <CommitteeMemberTableRowCells
                committeeMember={committeeMember}
                handleSetCommitteeMemberEditable={handleSetCommitteeMemberEditable}
              />
            </Box>
          ) : (
            // COMMITTEE MEMBER ACCORDION (MOBILE)
            <Box
              sx={{
                display: { md: 'none' },
                py: 3,
                borderBottom: isLastItem ? '' : '1px solid rgba(0, 0, 0, 0.12)'
              }}
            >
              <CommitteeMemberAccordion
                committeeMember={committeeMember}
                editButton={
                  isUserWorkspaceOwner && (
                    <Button
                      onClick={
                        handleSetCommitteeMemberEditable &&
                        handleSetCommitteeMemberEditable(committeeMember.id)
                      }
                      startIcon={<EditIcon />}
                    >
                      {t(keyPrefix + '.labelEditPermissions')}
                    </Button>
                  )
                }
                isAccordionExpanded={isAccordionExpanded}
                handleChangeAccordion={handleChangeAccordion}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

import { IMeeting, ISignature } from 'types';
import { useCallback } from 'react';
import { Stack, Typography, Box, Button, Tooltip, Chip } from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import { useLocale } from '@koopajs/react';
import { Trans } from 'react-i18next';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { IUser } from '@koopajs/app';
import { Link } from 'react-router-dom';
import { useCommitteeName } from '../hooks/useCommitteeName';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { UserList } from 'components/UserList';
import _ from 'lodash';
import { LatestReviewsByUser } from './ToReview/LatestReviewsByUser';

export const i18nTransComponents: { [k: string]: React.ReactElement } = { span: <span /> };

interface IMeetingPendingReviewCardProps {
  meeting: IMeeting;
  user?: IUser;
}

export const MeetingPendingReviewCard: React.FC<IMeetingPendingReviewCardProps> = (props) => {
  const { meeting, user } = props;

  const { t } = useLocale();
  const keyPrefix = 'Dashboard.MeetingPendingReviewCard';

  const meetingType = useCommitteeName(meeting?.committeeId as string);

  const usersWhoReviewed = meeting?.reviews?.map((review) => review.userId) || [];
  const hasCurrentUserReviewed = usersWhoReviewed.includes(user?.id || '');

  const missingReviewersIds =
    meeting?.reviewers
      ?.filter((reviewer) => !reviewer.latestReview)
      .map((missingReviewer) => missingReviewer.userId) || [];

  const isUserReviewer = user?.id && meeting.reviewers?.some((reviewer) => reviewer.userId === user.id);

  const renderMinuteActionButton = useCallback(() => {
    if (isUserReviewer) {
      return (
        <Button
          component={Link}
          to={{
            pathname: `/meetings/${meeting.id}/review`,
            state: { activePath: '/pending-review' }
          }}
          variant={hasCurrentUserReviewed ? 'text' : 'contained'}
          data-cy="meeting-review-minute-pending-card_review-button"
        >
          {t(`${keyPrefix}.labelReviewMinutes`)}
        </Button>
      );
    } else {
      return (
        <Button
          component={Link}
          to={{
            pathname: `/meetings/${meeting.id}/review`,
            state: { activePath: '/pending-review' }
          }}
          data-cy="meeting-review-minute-pending-card_view-button"
        >
          {t(`${keyPrefix}.labelViewMinutes`)}
        </Button>
      );
    }
  }, [isUserReviewer, hasCurrentUserReviewed, meeting.id]);

  return (
    <Stack
      sx={{ backgroundColor: '#FFFFFF', padding: '24px', borderRadius: '4px', border: '1px solid #dddddd' }}
      data-cy="meeting-review-minute-pending-card"
    >
      {/* title & button */}
      <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Typography sx={{ marginBottom: '14px', fontSize: '16px' }} variant="h3">
          {meeting?.title}
        </Typography>

        <Chip label={t('common:boardMinutes')} sx={{ mr: 'auto', ml: 6, backgroundColor: '#EDF0FF' }} />

        {/* People who can review & approve the minutes */}
        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', alignItems: 'flex-end' }}>
          {renderMinuteActionButton()}
        </Box>
      </Stack>

      {/* details */}
      <Box sx={{ opacity: '0.6', mb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            gap: '16px'
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <CalendarTodayIcon fontSize="small" />
            <Typography variant="body2">
              {meeting?.startDateTime ? (
                <DateTimeStartEnd
                  isoStringDate={meeting.startDateTime}
                  dateFormat="DATE_HUGE"
                  separator={` ${t('common:at')} `}
                  isoStringStart={meeting.startDateTime}
                />
              ) : (
                <i>{t('common:noDate')}</i>
              )}
            </Typography>
          </Stack>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {/* Minutes type */}

            <Typography sx={{ display: { xs: 'none', md: 'block' } }}>&#183;</Typography>
            <Typography variant="body2">{meetingType}</Typography>

            {/* Minutes location */}
            <Typography>&#183;</Typography>
            <Typography variant="body2">
              {meeting.location?.type ? (
                t(`common:locationType.${meeting.location.type}`)
              ) : (
                <i>{t('common:noLocation')}</i>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Display people who has reviewed */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { md: 'space-between' },
          alignItems: 'baseline'
        }}
      >
        <Box sx={{ mb: { xs: 1, md: 0 } }}>
          <LatestReviewsByUser reviewers={meeting.reviewers} />
        </Box>
        {/* Display reviewers who didn't review yet */}
        {missingReviewersIds && missingReviewersIds.length > 0 ? (
          <Tooltip title={<UserList userIds={missingReviewersIds} icon={<PersonIcon color="action" />} />}>
            <Box sx={{ maxWidth: {}, display: 'flex', justifyContent: 'flex-start' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                <WarningAmberIcon sx={{ opacity: '0.6', mr: 0.5 }} fontSize="small" />
                <Typography variant="body2" sx={{ opacity: '0.6' }}>
                  {t(`${keyPrefix}.approvalsPending`, { count: missingReviewersIds?.length })}
                </Typography>
              </Box>
            </Box>
          </Tooltip>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'flex-start', md: 'flex-end' },
              flexBasis: { xs: '100%', md: '50%' }
            }}
            data-cy="meeting-review-minute-pending-card_warning-approval-missing"
          >
            <Box
              sx={{
                display: 'column',
                alignItems: '',
                border: '1px solid #cfcfcf',
                p: 1,
                borderRadius: '4px'
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <WarningAmberIcon sx={{ opacity: '0.6' }} fontSize="small" />
                <Typography variant="body2" sx={{ opacity: '0.6', ml: 0.5 }}>
                  {t(`${keyPrefix}.boardApprovalPending`)}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ opacity: '0.6', mt: 0.5 }}>
                <Trans i18nKey={`${keyPrefix}.uploadMinutes-html`} t={t} components={i18nTransComponents} />
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{ display: { xs: 'flex', md: 'none' }, flexDirection: 'column', alignItems: 'flex-start', mt: 2 }}
      >
        {renderMinuteActionButton()}
      </Box>
    </Stack>
  );
};

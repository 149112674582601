import { IMeeting } from 'types';
import { Stack, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocale, useUserShow } from '@koopajs/react';
import { useCallback, useState } from 'react';
import { StartMeeting } from 'components/Modals/StartMeeting';
import Tooltip from '@mui/material/Tooltip';
import { PlayArrow as PlayArrowIcon } from '@mui/icons-material';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import { WriteMinutesButton } from './WriteMinutesButton';

interface IMeetingCardButtons {
  meeting: IMeeting;
}

export const MeetingCardButtons: React.FC<IMeetingCardButtons> = (props) => {
  const { meeting } = props;

  const { t } = useLocale();
  const { user } = useUserShow();

  const { canUserContinueMeeting, hasMeetingStarted, canUserCurrentlyEditMeeting } = useMeetingStatus({
    meeting,
    user
  });

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {/* Edit / View buttons */}
      <Box>
        {canUserCurrentlyEditMeeting ? (
          <Button
            component={Link}
            to={{
              pathname: `/meetings/${meeting.id}/edit`,
              state: { activePath: '/' }
            }}
            data-cy="dashboard_meeting-card_edit-button"
          >
            {t('common:labelEdit')}
          </Button>
        ) : (
          <Button
            component={Link}
            to={{
              pathname: `/meetings/${meeting.id}`,
              state: { activePath: hasMeetingStarted ? '' : '/' }
            }}
            data-cy="dashboard_meeting-card_view-button"
          >
            {t('common:labelView')}
          </Button>
        )}
      </Box>

      {canUserContinueMeeting && (
        <>
          <WriteMinutesButton meeting={meeting} variant="outlined" />
        </>
      )}
    </Stack>
  );
};

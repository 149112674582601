import { useLocale } from '@koopajs/react';
import { TablePagination, Stack } from '@mui/material';
import { PastMeetingCard } from './PastMeetingCard';
import { useCallback } from 'react';
import { IMeeting } from 'types';

interface IPastMeetingsListProps {
  meetings: IMeeting[];
  size?: number;
  page?: number;
  totalCount?: number;
  isReloading: boolean;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
}

export const PastMeetingsList: React.FC<IPastMeetingsListProps> = (props) => {
  const { meetings, size, page, totalCount, isReloading, onPageChange, onSizeChange } = props;

  const { t } = useLocale();

  const handlePageChange = useCallback(
    (e: unknown, newPage: number) => {
      onPageChange(newPage);
    },
    [page]
  );

  const handleSizeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onSizeChange(Number(e.target.value)),
    []
  );

  return (
    <>
      <Stack sx={{ opacity: isReloading ? 0.35 : 1 }} spacing={3}>
        {meetings.map((meeting) => {
          return <PastMeetingCard key={meeting.id} pastMeeting={meeting} />;
        })}
      </Stack>
      <TablePagination
        rowsPerPageOptions={[5, 20, 50]}
        component="div"
        count={totalCount || 999}
        rowsPerPage={size || 20}
        page={page ? page : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleSizeChange}
        SelectProps={{
          native: true
        }}
        // eslint-disable-next-line react/jsx-no-bind
        labelDisplayedRows={({ from, to, count }) => {
          const transOptions = { from, to, count };

          return count !== -1
            ? t('common:TablePagination.labelDisplayedRowsCount', transOptions)
            : t('common:TablePagination.labelDisplayedRowsMoreThan', transOptions);
        }}
        labelRowsPerPage={t('common:TablePagination.labelRowsPerPage')}
      />
    </>
  );
};

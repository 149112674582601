import { RadioFieldTemp } from 'components/RadioFieldTemp';
import { ICommittee, IMeeting } from 'types';
import { useLocale, useResourceList } from '@koopajs/react';
import { Stack, Typography, Paper, Box, Button, Link } from '@mui/material';
import { formatDate } from 'utils/DateTime/formatDate';
import { useState } from 'react';

interface IAnotherMeetingRadioGroupProps {
  recentMeetings: IMeeting[];
}

export const AnotherMeetingRadioGroup: React.FC<IAnotherMeetingRadioGroupProps> = (props) => {
  const { recentMeetings } = props;
  const { t, locale } = useLocale();
  const keyPrefix = 'MeetingEdit.MeetingForm';

  const [meetingsShown, setMeetingsShown] = useState(5);

  const showMoreMeetings = (): void => {
    setMeetingsShown((s) => {
      return s + 5;
    });
  };

  const isShowMoreMeetingsVisible = recentMeetings.length > meetingsShown;

  const { resources: committees } = useResourceList<ICommittee>({ path: '/committees', useCache: true });

  const getCommitteeName = (committeeId: string): string | undefined => {
    const committee = committees.find((c) => c.id === committeeId);
    return committee?.name;
  };

  return (
    <Box
      sx={{
        pl: '30px',
        py: '6px',
        maxWidth: '100%'
      }}
    >
      <Paper>
        <RadioFieldTemp
          name="meetingTemplateId"
          isOptional={false}
          defaultValue=""
          sx={{
            m: 0,
            maxWidth: '100%',
            '&& .MuiFormControlLabel-label': { minWidth: '0px' }
          }}
          sxFormControlLabel={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
            '&:first-of-type': {
              borderTop: '1px solid rgba(0, 0, 0, 0.23)'
            },
            '&:last-child': {
              // negative margin to remove margin added by empty helper text
              ...(!isShowMoreMeetingsVisible ? { borderBottom: 0, mb: '-3px' } : {})
            },
            margin: 0,
            maxWidth: '100%'
          }}
          sxFormLabel={{ pl: 2.5, my: 0, py: 2 }}
          options={recentMeetings?.slice(0, meetingsShown).map((m) => ({
            id: m.id,
            label: (
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                sx={{
                  alignItems: { md: 'center' },
                  py: '14px'
                }}
                spacing={{ md: 3 }}
                key={m.id}
              >
                <Box
                  sx={{
                    width: { md: '300px' }
                  }}
                >
                  <Typography
                    sx={{
                      // maxWidth: '100%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      minWidth: '0px'
                    }}
                  >
                    {m.title}
                  </Typography>
                </Box>
                <Stack
                  direction={{ sm: 'row' }}
                  sx={{
                    alignItems: { sm: 'center' }
                  }}
                  spacing={{ md: 3 }}
                >
                  <Box sx={{ width: { md: '150px' }, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {m.committeeId && getCommitteeName(m.committeeId) && (
                      <>
                        <Typography variant="caption" sx={{ opacity: 0.6 }}>
                          {getCommitteeName(m.committeeId)}
                        </Typography>
                      </>
                    )}
                  </Box>
                  {m.committeeId && getCommitteeName(m.committeeId) && m.startDateTime && (
                    <Box sx={{ '&&': { mx: 3 }, display: { xs: 'none', sm: 'block', md: 'none' } }}>
                      &#183;
                    </Box>
                  )}
                  <Box>
                    {m.startDateTime && (
                      <>
                        <Typography variant="caption" sx={{ opacity: 0.6 }}>
                          {formatDate({
                            isoString: m.startDateTime,
                            locale,
                            format: 'DATE_FULL'
                          })}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Stack>
              </Stack>
            )
          }))}
          i18n={{ keyPrefix: keyPrefix + '.RadioPreviousMeetings' }}
        />
        {isShowMoreMeetingsVisible && (
          // negative margin to remove margin added by empty helper text
          <Box sx={{ p: '14px', mt: '-3px' }}>
            <Link
              component="button"
              type="button"
              sx={{
                display: 'inline',
                color: 'secondary.light',
                cursor: 'pointer',
                '&:disabled': {
                  color: 'black',
                  opacity: '0.6',
                  cursor: 'default'
                },
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: '400',
                textDecoration: 'none'
              }}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={showMoreMeetings}
            >
              {t(keyPrefix + '.seeMoreMeetingTemplates')}
            </Link>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

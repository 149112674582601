import { ICommitteeMember } from 'types';
import { useLocale, usePermissions } from '@koopajs/react';
import { useScreenSize } from 'utils/useScreenSize';
import { Typography, Paper, Stack, Box } from '@mui/material';
import { EmptyCommitteeState } from './EmptyCommitteeState';
import { Loading } from '@koopajs/mui';
import { useCallback, useState } from 'react';
import { visuallyHidden } from '@mui/utils';
import { CommitteeMemberRow } from './CommitteeMemberRow';

interface ICommitteeMembersListProps {
  committeeMembers: ICommitteeMember[];
  isProcessing: boolean;
}

export const CommitteeMembersList: React.FC<ICommitteeMembersListProps> = (props) => {
  const { committeeMembers, isProcessing } = props;

  const { t } = useLocale();
  const keyPrefix = 'OrganizationCommittee.CommitteeMembersList';

  const isUserWorkspaceOwner = usePermissions({ requiredPermissions: 'owner' });

  const { isScreenAboveMd } = useScreenSize();

  const [committeeMemberIdEditable, setCommitteeMemberIdEditable] = useState('');

  const handleSetCommitteeMemberEditable = useCallback((committeeMemberId: string) => {
    return () => setCommitteeMemberIdEditable(committeeMemberId);
  }, []);

  const handleUnsetCommitteeMemberEditable = useCallback(() => {
    setCommitteeMemberIdEditable('');
  }, []);

  if (isProcessing) {
    return (
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'static' }} />
      </Stack>
    );
  }

  return (
    <>
      <Paper variant="outlined" sx={{ p: 3, overflowX: 'auto' }}>
        <Box sx={{ display: { md: 'table' }, width: '100%' }} role={isScreenAboveMd ? 'table' : undefined}>
          {/* TABLE HEAD (DESKTOP) */}
          <Box
            sx={{
              display: { xs: 'none', md: 'table-row' },
              '& > div': {
                padding: '0 12px 24px 12px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
              }
            }}
            role="row"
          >
            <Box
              sx={{ display: 'table-cell', width: 230 + 24 + 'px' }}
              role={isScreenAboveMd ? 'columnheader' : undefined}
            >
              <Typography>
                {t(keyPrefix + `.membersTitle`)} ({committeeMembers?.length})
              </Typography>
            </Box>
            <Box
              sx={{ display: 'table-cell', width: 160 + 24 + 'px' }}
              role={isScreenAboveMd ? 'columnheader' : undefined}
            >
              <Typography>{t(keyPrefix + `.rolesTitle`)}</Typography>
            </Box>
            <Box sx={{ display: 'table-cell' }} role={isScreenAboveMd ? 'columnheader' : undefined}>
              <Typography>{t(keyPrefix + `.permissionsCreate`)}</Typography>
            </Box>
            <Box sx={{ display: 'table-cell' }} role={isScreenAboveMd ? 'columnheader' : undefined}>
              <Typography>{t(keyPrefix + `.permissionsWrite`)}</Typography>
            </Box>
            <Box sx={{ display: 'table-cell' }} role={isScreenAboveMd ? 'columnheader' : undefined}>
              <Typography>{t(keyPrefix + `.permissionsEdit`)}</Typography>
            </Box>
            <Box sx={{ display: 'table-cell' }} role={isScreenAboveMd ? 'columnheader' : undefined}>
              <Typography>{t(keyPrefix + `.permissionsSign`)}</Typography>
            </Box>
            {isUserWorkspaceOwner && (
              <Box sx={{ display: 'table-cell' }} role={isScreenAboveMd ? 'columnheader' : undefined}>
                <Typography sx={visuallyHidden}>{t('common:action')}</Typography>
              </Box>
            )}
          </Box>

          {/* TITLE (MOBILE) */}
          <Typography sx={{ display: { md: 'none' } }}>
            {t(keyPrefix + '.membersTitle')} ({committeeMembers?.length})
          </Typography>

          <>
            {committeeMembers.map((committeeMember, i, a) => {
              const isLast = i === a.length - 1;
              return (
                <CommitteeMemberRow
                  committeeMember={committeeMember}
                  isEditable={committeeMemberIdEditable === committeeMember.id}
                  handleSetCommitteeMemberEditable={handleSetCommitteeMemberEditable}
                  handleUnsetCommitteeMemberEditable={handleUnsetCommitteeMemberEditable}
                  isLastItem={isLast}
                  key={committeeMember.id}
                />
              );
            })}
          </>
        </Box>
        {committeeMembers.length ? (
          <Typography variant="body2" color="gray" sx={{ mt: 3, display: { xs: 'none', md: 'block' } }}>
            {t(keyPrefix + '.signatureRequired')}
          </Typography>
        ) : (
          <EmptyCommitteeState />
        )}
      </Paper>
    </>
  );
};

import { useLocale } from '@koopajs/react';
import { Chip } from '@mui/material';
import { IMeeting } from 'types';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface IMeetingStateChipProps {
  meeting: IMeeting;
  sx?: SxProps<Theme>;
}

export const MeetingStateChip: React.FC<IMeetingStateChipProps> = (props) => {
  const { meeting, sx } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.MeetingStateChip';

  if (meeting.minutesEndedAt) {
    // TODO: waiting for confirmation on the wording here
    return <Chip label={t(keyPrefix + '.chipEnded')} sx={sx} data-cy="meeting-state-chip" />;
  }

  if (meeting.minutesStartedAt) {
    return (
      <Chip label={t(keyPrefix + '.chipInProgress')} color="warning" sx={sx} data-cy="meeting-state-chip" />
    );
  }
  if (meeting.invitationSentAt && meeting.agendaSentAt) {
    return <Chip label={t(keyPrefix + '.chipSent')} color="success" sx={sx} data-cy="meeting-state-chip" />;
  }
  if (meeting.invitationSentAt && !meeting.agendaSentAt) {
    return (
      <>
        <Chip label={t(keyPrefix + '.chipNoticeSent')} color="success" sx={sx} data-cy="meeting-state-chip" />
        <Chip label={t(keyPrefix + '.chipAgendaInPreparation')} sx={sx} data-cy="meeting-state-chip" />
      </>
    );
  }

  return <Chip label={t(keyPrefix + '.chipInPreparation')} sx={sx} data-cy="meeting-state-chip" />;
};

import React, { useContext } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { FormContext } from '@koopajs/react';
import SwitchMUI from '@mui/material/Switch';
import { ICoreProps } from '@koopajs/mui';
import { useLocale } from '@koopajs/mui/dist/useCoreLocale';

export interface ISwitchProps extends ICoreProps {
  isRequired?: boolean;
  name: string;
  isChecked?: boolean;
  testId?: string;
}

export const Switch: React.FC<ISwitchProps> = (props) => {
  const { name, isRequired, isLoading, isDisabled, isChecked, i18n, testId } = props;
  const context = useContext(FormContext);
  const { t } = useLocale({ ...i18n, coreKeyPrefix: 'FormSwitch' });

  if (!context.form) {
    throw new Error('"Form.Button" component needs to be use inside a FormController');
  }

  const { ref, ...rest } = context.form.register(name, {
    required: isRequired,
    disabled: isDisabled
  });

  return (
    <FormControl fullWidth error={!!context.form.formState.errors[name]}>
      <FormControlLabel
        data-cy={testId || `koopa_switch-label_${name}`}
        disabled={context.isProcessing || isLoading || isDisabled}
        control={<SwitchMUI {...rest} inputRef={ref} color="primary" defaultChecked={isChecked} />}
        label={t('label')}
        componentsProps={{
          typography: {
            color: context.form.formState.errors[name] && 'error'
          }
        }}
      />

      <FormHelperText>
        {context.form.formState.errors[name] ? t('errorText') : t('helperText')}
      </FormHelperText>
    </FormControl>
  );
};

import { NewCheckbox } from './temp/NewCheckbox';
import { Paper, Box, Typography, Divider, Avatar } from '@mui/material';
import { IUserPublicProfile } from '@koopajs/app';
import { useLocale } from '@koopajs/react';
import { useScreenSize } from 'utils/useScreenSize';
import { useMemo } from 'react';
import { User } from '@koopajs/react';
import { SystemStyleObject } from '@mui/system';
import { CommitteeMemberTitle } from 'types';

interface IUsersCheckboxesRowsProps {
  usersIds: string[] | undefined;
  defaultValues?: string[];
  isCheckboxLabelAvatar?: boolean;
  formName: string;
  label?: string;
  sxProps?: SystemStyleObject;
  committeeMembersTitles?: { [k: string]: CommitteeMemberTitle };
}

export const UsersCheckboxesRows: React.FC<IUsersCheckboxesRowsProps> = (props) => {
  const { usersIds, defaultValues, isCheckboxLabelAvatar, formName, label, sxProps, committeeMembersTitles } =
    props;
  const { t } = useLocale();

  const { isScreenAboveSm } = useScreenSize();

  const memoizedDefaultValues = useMemo(() => {
    return defaultValues;
  }, []);

  const renderAvatar = (profile: IUserPublicProfile | undefined): React.ReactNode => {
    const title = profile?.id && committeeMembersTitles?.[profile?.id];
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Avatar alt={profile?.username} src={profile?.profilePicture} sx={{ mr: 1 }}>
            {(profile?.firstname ? profile?.firstname?.charAt(0) : '') +
              (profile?.lastname ? profile?.lastname?.charAt(0) : '')}
          </Avatar>
          <Box>
            {t('common:CheckboxLabelInterpolation.label', {
              label: profile?.username
            })}
            {title && (
              <Typography variant="body2" sx={{ opacity: '0.6' }}>
                {t(`common:committeeMemberTitles.${title}`)}
              </Typography>
            )}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box
      component={Paper}
      sx={{
        width: usersIds && usersIds?.length <= 5 ? '50%' : '100%',
        mt: 3,
        ...sxProps
      }}
      data-cy="user-checkboxes-rows"
    >
      <Typography sx={{ p: 2, pl: '40px', fontSize: '0.875rem', fontWeight: 500 }}>
        {label ? label : t('common:name')}
      </Typography>
      <Divider />

      <Box>
        {usersIds && usersIds.length > 5 && isScreenAboveSm
          ? usersIds?.map((userId: string, index: number) => {
              const userLeftColumnId = userId; //current user in the loop
              const userRightColumnId = usersIds[index + 1]; //next user in the loop
              //take every other user from the array and show 2 users in the same row
              if (index % 2 === 0) {
                return (
                  <Box key={index} sx={{ display: 'flex' }}>
                    <User id={userLeftColumnId}>
                      {(profile) => (
                        <>
                          <NewCheckbox
                            name={`${formName}[${userLeftColumnId}]`}
                            i18n={{
                              keyPrefix: 'common:CheckboxLabelInterpolation',
                              options: { label: profile?.username }
                            }}
                            isOptional={true}
                            sx={{ px: '16px' }}
                            key={userLeftColumnId}
                            isChecked={memoizedDefaultValues?.includes(userLeftColumnId)}
                            customLabel={isCheckboxLabelAvatar ? renderAvatar(profile) : null}
                          />
                        </>
                      )}
                    </User>

                    {userRightColumnId && (
                      <User id={userRightColumnId}>
                        {(profile) => (
                          <>
                            <NewCheckbox
                              name={`${formName}[${userRightColumnId}]`}
                              i18n={{
                                keyPrefix: 'common:CheckboxLabelInterpolation',
                                options: { label: profile?.username }
                              }}
                              isOptional={true}
                              sx={{ px: '16px' }}
                              key={userRightColumnId}
                              isChecked={memoizedDefaultValues?.includes(userRightColumnId)}
                              customLabel={isCheckboxLabelAvatar ? renderAvatar(profile) : null}
                            />
                          </>
                        )}
                      </User>
                    )}
                  </Box>
                );
              }
            })
          : usersIds?.map((userId) => {
              return (
                <Box key={userId}>
                  <User id={userId}>
                    {(profile) => (
                      <>
                        <NewCheckbox
                          name={`${formName}[${userId}]`}
                          i18n={{
                            keyPrefix: 'common:CheckboxLabelInterpolation',
                            options: { label: profile?.username }
                          }}
                          isOptional={true}
                          sx={{ px: '16px' }}
                          isChecked={memoizedDefaultValues?.includes(userId)}
                          customLabel={isCheckboxLabelAvatar ? renderAvatar(profile) : null}
                        />
                      </>
                    )}
                  </User>
                </Box>
              );
            })}
      </Box>
    </Box>
  );
};

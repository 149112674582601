import { Paper, Stack, Typography, Button, Avatar, Box, Link } from '@mui/material';
import { useLocale } from '@koopajs/react';
import GroupsIcon from '@mui/icons-material/Groups';
import { Api, IUserPublicProfile } from '@koopajs/app';
import { useCallback, useEffect, useState } from 'react';
import { ICommitteeMember } from 'types';
import { Link as RouterLink } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface IMemberCardProps {
  user: IUserPublicProfile;
}

export const MemberCard: React.FC<IMemberCardProps> = (props) => {
  const { user } = props;

  const [committeeMembers, setCommitteeMembers] = useState<ICommitteeMember[]>([]);

  // TODO: build a proper backend route for this
  useEffect(() => {
    const fetchCommitteeMembers = async (): Promise<unknown> => {
      const res = await Api.client.get(
        `/committee-members?size=50&filters[]=${encodeURIComponent(`userId:${user.id}`)}`
      );
      return res;
    };
    fetchCommitteeMembers()
      .then((res) => {
        setCommitteeMembers((res as { data: { resources: ICommitteeMember[] } }).data.resources);
      })
      .catch((e) => {
        console.log(e);
        setCommitteeMembers([]);
      });
  }, [user.id]);

  const { t } = useLocale();
  const keyPrefix = 'Organization.OrganizationMembers.MemberCard';

  const copyEmailToClipboard = useCallback(async () => {
    if (user.email) await navigator.clipboard.writeText(user.email);
  }, []);

  return (
    <Paper
      sx={{
        p: 3,
        my: 1,
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexBasis: '100%'
      }}
      variant="outlined"
      data-cy="organization_member-card"
    >
      <Box>
        <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
          <Avatar
            src={user.profilePicture}
            style={{ width: 40, height: 40, fontSize: '16px' }}
            alt={t('common:profilePhotoOf', { name: user.username })}
          >
            {user.firstname?.[0]}
            {user.lastname?.[0]}
          </Avatar>
          <Typography
            variant="body1"
            sx={{ fontSize: '18px', ml: '14px', minWidth: '280px', mr: '20px' }}
            data-cy="organization_member-card-username"
          >
            {user.username}
          </Typography>

          <Typography
            data-cy="organization_member-card-email"
            variant="body2"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {user.email}
            <Link
              component="button"
              color="inherit"
              onClick={copyEmailToClipboard}
              sx={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                '&:hover > *': { color: '#151E54' }
              }}
            >
              <ContentCopyIcon sx={{ fontSize: '16px', color: '#0B79D0', ml: 0.5 }} />
            </Link>
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <GroupsIcon color="action" />
          <Typography>{t(keyPrefix + '.groups', { count: committeeMembers.length })}</Typography>
        </Stack>
      </Box>
      <Button
        variant="text"
        component={RouterLink}
        to={`/organization/members/${user.id}`}
        sx={{ mt: { xs: 2, md: 0 } }}
        data-cy="organization_member-card-see-details-button"
      >
        {t(keyPrefix + '.seeDetails')}
      </Button>
    </Paper>
  );
};

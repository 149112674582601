import { useCallback, useState, useEffect, useRef } from 'react';
//import { FormController } from '@koopajs/react';
import { Stack, Button, Box, Typography } from '@mui/material';
import { ExitMeeting } from '../../../Modals/ExitMeeting';
import { NoVoteOutcomeModal } from '../../../Modals/NoVoteOutcomeModal';
import { NoResolutionBookSwitchModal } from '../../../Modals/NoResolutionBookSwitchModal';
import { ErrorMessage } from '@koopajs/mui';
import { IUseLiveMeetingResponse } from '../useLiveMeeting';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { EndMeeting } from 'components/Modals/EndMeeting';
import { checkIsDecisionTopicType, checkIsInCameraTopicType } from 'utils/topicTypeArrays';
import CloseIcon from '@mui/icons-material/Close';
import { MeetingInProgressHeader } from '../Header';
import LockIcon from '@mui/icons-material/Lock';

interface IMeetingInProgressFormController {
  children: React.ReactNode;
  liveMeeting: IUseLiveMeetingResponse;
}

export const MeetingInProgressFormController: React.FC<IMeetingInProgressFormController> = (props) => {
  const { liveMeeting, children } = props;
  const {
    topic,
    meeting,
    previousTopicId,
    nextTopicId,
    handleChangeCurrentTopic,
    handleMeetingPermanentlyFinished,
    isProcessing,
    errorMessage,
    t,
    currentTopicId,
    presentParticipants,
    isCommitteeTakingResolutions
    // formButtonsContainerWidth
  } = liveMeeting;

  const keyPrefix = 'MeetingStateInProgress';

  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isEndMeetingModalOpen, setIsEndMeetingModalOpen] = useState(false);
  const [isNoResBookModalOpen, setIsNoResBookModalOpen] = useState(false);
  const [isNoVoteModalOpen, setIsNoVoteModalOpen] = useState(false);

  const isHavingParticipants = topic?.quorumParticipants?.some((participant) => participant.isPresent);
  const [isFormParticipantsFilled, setIsFormParticipantsFilled] = useState(isHavingParticipants);

  const isHavingNominees = Boolean(topic?.meetingPresidentId) && Boolean(topic?.meetingSecretaryId);
  const [isNominationsFilled, setIsNominationsFilled] = useState(isHavingNominees);

  const handleCloseExitModal = useCallback(() => {
    setIsExitModalOpen(false);
  }, []);

  const handleCloseEndMeetingModal = useCallback(() => {
    setIsEndMeetingModalOpen(false);
  }, []);

  const handleCloseNoResBookModal = useCallback(() => {
    setIsNoResBookModalOpen(false);
  }, []);

  const handleCloseNoVoteModal = useCallback(() => {
    setIsNoVoteModalOpen(false);
  }, []);

  // If we have a resolution topic with a vote, but it won't be added to the resolution book
  const isResolutionTypeAndResBookUnchecked =
    topic?.type === 'resolution' &&
    !topic.isResolution &&
    isCommitteeTakingResolutions &&
    topic.resolutionOutcome?.outcome !== 'reported';

  const isDecisionWithNoVote =
    topic && checkIsDecisionTopicType(topic.type) && !topic?.resolutionOutcome?.outcome;

  const isQuorumWithNoVote = topic && topic.type === 'quorum' && !topic.quorumReachedAt;

  const handleNextPageFlow = (continueFunction: () => void): void => {
    if (isQuorumWithNoVote) {
      setIsNoVoteModalOpen(true);
      return;
    }

    if (isCommitteeTakingResolutions || topic?.type === 'pastMinutesApproval') {
      if (isResolutionTypeAndResBookUnchecked) {
        setIsNoResBookModalOpen(true);
        return;
      } else if (isDecisionWithNoVote) {
        setIsNoVoteModalOpen(true);
        return;
      }
    }

    continueFunction();
  };

  const handleFormSubmit = useCallback(
    async (formData: Record<string, unknown>, e?: React.BaseSyntheticEvent): Promise<boolean> => {
      const submitType: string = (e as { nativeEvent: { submitter: { name: string } } }).nativeEvent.submitter
        .name;

      const isInCamera = topic?.type && checkIsInCameraTopicType(topic.type);

      let isSuccess = false;

      // if it's an incamera topic, and the user doesn't see the visibleBy array (indicating they don't have access), skip this update
      if (!isInCamera || (isInCamera && topic?.visibleBy && topic?.visibleBy.length > 0)) {
        isSuccess = await liveMeeting.handleTopicUpdate(formData);
      } else {
        isSuccess = true;
      }

      if (isSuccess) {
        switch (submitType) {
          case 'exit':
            setIsExitModalOpen(true);
            break;

          case 'back':
            if (previousTopicId) {
              handleChangeCurrentTopic(previousTopicId);
            }
            break;

          case 'next':
            if (nextTopicId) {
              handleNextPageFlow(() => handleChangeCurrentTopic(nextTopicId));
            }
            break;

          case 'end':
            handleNextPageFlow(() => setIsEndMeetingModalOpen(true));
            break;
        }
      }

      return isSuccess;
    },
    [
      JSON.stringify(topic),
      liveMeeting.handleTopicUpdate,
      handleChangeCurrentTopic,
      handleMeetingPermanentlyFinished,
      nextTopicId,
      previousTopicId
    ]
  );

  const defaultValues: {
    notes?: string;
    meetingPresidentId?: { id: string; label: string };
    meetingSecretaryId?: { id: string; label: string };
  } = {};
  if (topic?.notes) defaultValues.notes = topic.notes;

  //only select president and secretary defaults if they are present
  const presentParticipantIds = meeting?.participants?.filter((p) => p.isPresent).map((p) => p.userId);

  if (topic?.meetingPresidentId && presentParticipantIds?.includes(topic?.meetingPresidentId)) {
    const meetingPresidentLabel = presentParticipants?.find(
      (p) => p.id === topic.meetingPresidentId
    )?.username;

    defaultValues.meetingPresidentId = {
      id: topic.meetingPresidentId,
      label: meetingPresidentLabel || ''
    };
  }
  if (topic?.meetingSecretaryId && presentParticipantIds?.includes(topic?.meetingSecretaryId)) {
    const meetingSecretaryLabel = presentParticipants?.find(
      (p) => p.id === topic.meetingSecretaryId
    )?.username;

    defaultValues.meetingSecretaryId = {
      id: topic.meetingSecretaryId,
      label: meetingSecretaryLabel || ''
    };
  }

  const watchForm = useCallback(
    (data: { [k: string]: unknown }): void => {
      const formData = data as {
        notes?: string;
        quorumParticipants?: { [k: string]: boolean };
        meetingPresidentId?: string;
        meetingSecretaryId?: string;
        visibleBy?: { [k: string]: boolean };
      };

      if (topic?.type === 'quorum') {
        if (formData.quorumParticipants) {
          const result = Object.values(formData.quorumParticipants).some((participant) => participant);
          setIsFormParticipantsFilled(result);
        }
      }

      if (topic?.type === 'nominations') {
        const isNomineeFilled = Boolean(formData.meetingPresidentId && formData.meetingSecretaryId);
        setIsNominationsFilled(isNomineeFilled);
      }
    },
    [topic?.type]
  );

  // Disable Next button conditions
  let isNextDisabled = !nextTopicId;
  let isPreviousDisabled = !previousTopicId;
  let isExitDisabled = false;
  let isFinishDisabled = !!nextTopicId;

  if (isProcessing) {
    isNextDisabled = true;
    isPreviousDisabled = true;
    isExitDisabled = true;
    isFinishDisabled = true;
  } else if (topic?.type === 'quorum' && !isFormParticipantsFilled) {
    isNextDisabled = true;
    isFinishDisabled = true;
  } else if (topic?.type === 'nominations' && !isNominationsFilled) {
    isNextDisabled = true;
    isFinishDisabled = true;
  }

  return (
    <FormControllerTemp
      onSubmit={handleFormSubmit}
      defaultValues={defaultValues}
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      onSubmitSuccessResetType="NO_RESET"
      watch={watchForm}
    >
      <MeetingInProgressHeader liveMeeting={liveMeeting} />

      {/* FIELDS */}
      {/* TODO: test with error */}
      <Stack
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          backgroundColor: '#FFFFFF',
          padding: 3,
          gap: 3
        }}
      >
        <ErrorMessage error={errorMessage} />
        {children}
      </Stack>

      {/* MODALS */}
      <NoVoteOutcomeModal
        isOpen={isNoVoteModalOpen}
        handleOnClose={handleCloseNoVoteModal}
        liveMeeting={liveMeeting}
        title={
          topic?.type === 'quorum'
            ? t('Modals.NoVoteOutcomeModal.titleRecordQuorum')
            : t('Modals.NoVoteOutcomeModal.titleRecordProposal')
        }
        description={topic?.type === 'resolution' ? t('Modals.NoVoteOutcomeModal.description') : ''}
        labelRecord={
          topic?.type === 'quorum'
            ? t('Modals.NoVoteOutcomeModal.labelRecordQuorum')
            : t('Modals.NoVoteOutcomeModal.labelRecordProposal')
        }
        isRecordVoteDialog={topic?.type === 'quorum' ? false : true}
      />
      <NoResolutionBookSwitchModal
        isOpen={isNoResBookModalOpen}
        handleOnClose={handleCloseNoResBookModal}
        liveMeeting={liveMeeting}
      />
      <EndMeeting
        isOpen={isEndMeetingModalOpen}
        handleOnClose={handleCloseEndMeetingModal}
        liveMeeting={liveMeeting}
      />

      {/* BUTTONS */}
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{ backgroundColor: '#F3F3F3', borderTop: '1px solid #BDBDBD', p: 2 }}
      >
        <Stack>
          <Button
            type="submit"
            color="primary"
            name="exit"
            disabled={isExitDisabled}
            data-cy="meeting-in-progress_exit-button"
            startIcon={<CloseIcon />}
          >
            {t('common:labelExit')}
          </Button>
          <ExitMeeting
            isOpen={isExitModalOpen}
            handleOnClose={handleCloseExitModal}
            liveMeeting={liveMeeting}
          />
        </Stack>

        <Stack direction="row" spacing={2}>
          <Button
            type="submit"
            name="back"
            variant="text"
            disabled={isPreviousDisabled}
            data-cy="meeting-in-progress_back-button"
          >
            {t('common:labelBack')}
          </Button>
          {nextTopicId ? (
            <Button
              type="submit"
              name="next"
              variant="contained"
              disabled={isNextDisabled}
              data-cy="meeting-in-progress_next-button"
            >
              {t('common:labelNext')}
            </Button>
          ) : (
            <>
              <Button
                type="submit"
                name="end"
                variant="contained"
                disabled={isFinishDisabled}
                data-cy="meeting-in-progress_end-meeting-button"
              >
                {t(keyPrefix + '.labelEndMeeting')}
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </FormControllerTemp>
  );
};

import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useComponentVisibility, useLocale } from '@koopajs/react';
import { useEffect } from 'react';
import { IDocumentRecord } from 'types';
import { useDocumentInMemoryDropzone } from 'components/hooks/useDocumentInMemoryDropzone';

interface IAddDocumentToHistoryButtonProps {
  documentRecord: IDocumentRecord;
}

export const AddDocumentToHistoryButton: React.FC<IAddDocumentToHistoryButtonProps> = (props) => {
  const { documentRecord } = props;

  const { t } = useLocale();
  const keyPrefix = 'DocumentRecords.AddDocumentToHistoryButton';

  const { dropzone, isProcessing, blobUrl, file } = useDocumentInMemoryDropzone({
    isStoringFileInMemory: true
  });

  const addDocumentToHistoryDialog = useComponentVisibility('addDocumentToHistoryDialog');

  useEffect(() => {
    if (file) {
      addDocumentToHistoryDialog.setVisibleWithContext({
        blobUrl,
        file,
        defaultValues: {
          title: documentRecord.lastDocumentTitle
        },
        documentRecord
      });
    }
  }, [blobUrl, file]);

  return (
    <Box
      {...dropzone.getRootProps()}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '2px dashed #8187A9',
        mx: 3,
        my: 1,
        py: 1.75,
        px: 3
      }}
      data-cy="add-document-to-history-input_container"
    >
      <input {...dropzone.getInputProps()} data-cy="add-document-to-history-input" />
      <LoadingButton
        onClick={dropzone.open}
        startIcon={<AttachFileIcon />}
        loading={isProcessing}
        loadingPosition="start"
        variant="outlined"
      >
        <span>{t(keyPrefix + '.addDocumentToHistory')}</span>
      </LoadingButton>
    </Box>
  );
};

import { DateCalendar, PickersDay } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Stack, Box } from '@mui/material';
import { DateTime } from 'luxon';
import { IMeeting } from 'types';
import { useState } from 'react';
import { useLocale } from '@koopajs/react';
import { CalendarViewMeetingCard } from './CalendarViewMeetingCard';
import EventBusy from '@mui/icons-material/EventBusy';

interface ICalendarViewProps {
  meetings: IMeeting[];
}

export const CalendarView: React.FC<ICalendarViewProps> = (props) => {
  const { meetings } = props;

  const meetingsWithDate = meetings.filter((meeting) => meeting.startDate);

  const meetingDates = Array.from(new Set(meetingsWithDate.map((meeting) => meeting.startDate))).sort(); // array of dates without duplicates

  // default = today or later
  const defaultSelectedDayIso = meetingDates.find(
    (date) => DateTime.fromISO(date) >= DateTime.now().startOf('day')
  );

  const [selectedDay, setSelectedDay] = useState<DateTime | null>(
    defaultSelectedDayIso ? DateTime.fromISO(defaultSelectedDayIso) : DateTime.now().startOf('day')
  );

  console.log(selectedDay);

  const { t, locale } = useLocale();
  const keyPrefix = 'Dashboard.CalendarView';

  const meetingsOnSelectedDay = meetingsWithDate.filter((meeting) => {
    return meeting.startDate === selectedDay?.toISODate();
  });

  const isTwoOrMoreMeetingsOnSelectedDay = meetingsOnSelectedDay.length > 1;

  return (
    <>
      <Stack
        direction={{ md: 'row' }}
        flexWrap={isTwoOrMoreMeetingsOnSelectedDay ? 'wrap' : undefined}
        gap="24px"
      >
        <Box
          sx={{
            ...(isTwoOrMoreMeetingsOnSelectedDay
              ? {
                  flex: { md: '0 1 calc((100% - 24px) / 2)', lg: '0 1 calc((100% - 48px) / 3)' }, // 24/48px is the total size of the gaps
                  maxWidth: '100%',
                  minWidth: '0px'
                }
              : {})
          }}
        >
          <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
            <DateCalendar
              sx={{
                mx: 0,
                border: '1px solid #dddddd',
                backgroundColor: '#ffffff',
                borderRadius: '4px',
                maxWidth: '100%',
                minWidth: '0px'
              }}
              value={selectedDay}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={(newValue) => setSelectedDay(newValue)}
              slots={{
                day: (dayProps) => {
                  const { day, selected, ...other } = dayProps;
                  const isToday = DateTime.now().hasSame(day, 'day');
                  const isMeetingDay = meetingDates.includes(day.toISODate());

                  let sx = {};

                  if (isToday) {
                    sx = {
                      position: 'relative',
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        bottom: '3px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '4px',
                        height: '4px',
                        borderRadius: '50%',
                        backgroundColor: '#151E54'
                      }
                    };
                  } else if (isMeetingDay) {
                    sx = {
                      border: '1px solid #8187A9'
                    };
                  }

                  return (
                    <PickersDay
                      {...other}
                      day={day}
                      sx={{
                        ...sx,
                        backgroundColor: selected ? '#EDF0FF' : undefined,
                        '&:hover, &:focus': { backgroundColor: '#EDF0FF' }
                      }}
                      disableHighlightToday
                    />
                  );
                }
              }}
            />
          </LocalizationProvider>
        </Box>
        {meetingsOnSelectedDay.length ? (
          meetingsOnSelectedDay.map((meeting) => {
            return (
              <CalendarViewMeetingCard
                key={meeting.id}
                meeting={meeting}
                isSmallCardOnDesktop={isTwoOrMoreMeetingsOnSelectedDay}
                sx={{
                  ...(isTwoOrMoreMeetingsOnSelectedDay
                    ? {
                        flex: { md: '0 1 calc((100% - 24px) / 2)', lg: '0 1 calc((100% - 48px) / 3)' },
                        height: undefined
                      }
                    : undefined),
                  maxWidth: '100%',
                  minWidth: '0px'
                }}
              />
            );
          })
        ) : (
          <Stack sx={{ justifyContent: 'center', pl: 3 }}>
            <Stack direction="row" sx={{ gap: 1 }}>
              <EventBusy sx={{ opacity: 0.54 }} />
              {t(keyPrefix + '.noMeeting')}
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
};

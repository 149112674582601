import { useState, useCallback } from 'react';
import {
  Button,
  Stack,
  Chip,
  Typography,
  IconButton,
  Box,
  Tooltip,
  Link,
  Alert,
  Collapse
} from '@mui/material';
import { Edit as EditIcon, WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import { useLocale, useComponentVisibility, useResourceUpdate } from '@koopajs/react';
import { NominationsField } from './NominationsField';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import { ITopicCardProps } from 'types';
import { useTopic } from 'components/hooks/useTopic';
import { formatTime } from 'utils/DateTime/formatTime';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { DocumentList } from 'components/DocumentList';
import { ApprovedMinutesList } from 'components/Minutes/ApprovedMinutesList';
import { ResolutionOutcomeIcon } from 'components/ResolutionOutcome/ResolutionOutcomeIcon';
import RichTextMultiLine from 'components/temp/RichTextMultiLine';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { TextField } from 'components/temp/TextFieldTemp';
import { Link as RouterLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import { checkIsGenericTopicType } from 'utils/topicTypeArrays';
import { transformMinutesTimestamp } from 'utils/transformMinutesTimestamp';
import _ from 'lodash';
import { DateTimePickerWithButton } from 'components/temp/DateTimePickerWithButton';
import { ErrorMessage } from '@koopajs/mui';
import { PastMeetingsApprovedList } from './PastMinutesApprovalField/PastMeetingsApprovedList';

interface ITopicCardMinutesProps extends ITopicCardProps {
  isMeeting?: boolean;
  isCustomResNum?: boolean;
  isBeingReviewedInsideMeeting?: boolean;
}

interface IFormData {
  visibleBy: { [k: string]: string };
  pastMeetingsToApprove: { [k: string]: string };
  notes: string;
  minutesStartedAtOverwrittenTime: string;
  minutesEndedAtOverwrittenTime: string;
  quorumReachedAtOverwrittenTime: string;
  quorumReachedAtOverwritten: string;
}

export const TopicCardMinutes: React.FC<ITopicCardMinutesProps> = (props) => {
  const {
    topic,
    order,
    isEditable,
    handleSetTopicEditable,
    userHasEditAccess,
    onSubmitSuccess,
    meeting,
    isMeeting,
    isCustomResNum,
    isBeingReviewedInsideMeeting
  } = props;
  const { title, assignedTo, durationSeconds, type, notes, visibleBy, resolutionNumber } = topic;

  const [isMinutesDocumentAlertDismissed, setIsMinutesDocumentAlertDismissed] = useState(
    localStorage.getItem('isMinutesDocumentAlertDismissed') === 'true'
  );

  const handleCloseDocumentAlert = useCallback(() => {
    localStorage.setItem('isMinutesDocumentAlertDismissed', 'true');
    setIsMinutesDocumentAlertDismissed(true);
  }, []);

  const { onSubmit } = useTopic({
    topicId: topic.id,
    meetingId: topic.meetingId,
    isMeetingStarted: true
  });

  const { updateResource: updateMeeting, errorMessage } = useResourceUpdate({
    path: '/meetings',
    id: meeting?.id as string
  });

  const handleOnSubmit = useCallback(
    async (formData: object) => {
      const formDataTyped = formData as IFormData;

      const topicUpdates = _.omit(formDataTyped, [
        'minutesStartedAtOverwrittenTime',
        'minutesEndedAtOverwrittenTime'
      ]);

      let updateMeetingResponse = true;
      if (topic.type === 'quorum' || topic.type === 'adjournment') {
        const meetingUpdates = transformMinutesTimestamp({
          minutesStartedAtOverwrittenTime: formDataTyped.minutesStartedAtOverwrittenTime,
          minutesStartedAt: meeting?.minutesStartedAt as string,
          minutesEndedAtOverwrittenTime: formDataTyped.minutesEndedAtOverwrittenTime,
          minutesEndedAt: meeting?.minutesEndedAt as string
        });
        updateMeetingResponse = await updateMeeting(meetingUpdates);
      }
      const updateTopicResponse = await onSubmit(topicUpdates);

      if (updateMeetingResponse && updateTopicResponse && onSubmitSuccess) {
        onSubmitSuccess(); // close edit mode
      }

      return Boolean(updateTopicResponse && updateMeetingResponse);
    },
    [updateMeeting, onSubmit, meeting?.id, onSubmitSuccess, topic.id]
  );

  const { t, locale } = useLocale();
  const keyPrefix = 'Components.TopicCard';

  const showEditPropositionDialog = useComponentVisibility('editProposition');

  const { membersAgainst, membersAbstained, membersFor } = topic?.resolutionOutcome || {};
  const showResolutionOutcomeVotes = membersFor?.length || membersAbstained?.length || membersAgainst?.length;

  const isReviewMode = isMeeting;

  const visibleByObject = visibleBy?.reduce((acc: { [k: string]: boolean }, id, i) => {
    acc[id] = true;
    return acc;
  }, {});

  const defaultValues = {
    // ...topic
    title,
    resolutionNumber,
    assignedTo,
    durationSeconds,
    type,
    notes,
    visibleBy: visibleByObject,
    ...(type === 'quorum'
      ? {
          minutesStartedAtOverwrittenTime: meeting?.minutesStartedAt
            ? DateTime.fromISO(meeting?.minutesStartedAt).toFormat('HH:mm')
            : '',
          quorumReachedAtOverwrittenTime: topic.quorumReachedAt
            ? DateTime.fromISO(topic.quorumReachedAt).toFormat('HH:mm')
            : ''
        }
      : {}),
    ...(type === 'adjournment'
      ? {
          minutesEndedAtOverwrittenTime: meeting?.minutesEndedAt
            ? DateTime.fromISO(meeting?.minutesEndedAt).toFormat('HH:mm')
            : ''
        }
      : {})
  };

  const handleShowEditResolutionDialog = useCallback(() => {
    showEditPropositionDialog.setVisibleWithContext({
      meeting,
      topicId: topic?.id,
      defaultValues: {
        resolutionOutcomeMover: topic?.resolutionOutcome?.mover,
        resolutionOutcomeSeconder: topic?.resolutionOutcome?.seconder,
        isResolution: topic?.isResolution,
        resolutionVoteOutcome: topic.resolutionOutcome?.outcome
      }
    });
  }, [meeting?.id, topic?.id, topic?.resolutionOutcome, topic?.isResolution, showEditPropositionDialog]);

  let adjournmentAt = meeting?.minutesEndedAt;
  if (topic.type === 'adjournment' && topic.version === 1) {
    if (topic.resolutionOutcome?.recordedAt && topic.resolutionOutcome?.outcome === 'approved') {
      adjournmentAt = topic.resolutionOutcome?.recordedAt;
    } else {
      adjournmentAt = '';
    }
  }
  const hasDocuments = topic.documentsIds && topic.documentsIds.length > 0;

  return (
    <FormControllerTemp
      onSubmit={handleOnSubmit}
      defaultValues={defaultValues}
      onSubmitSuccessResetType="FORM_PAYLOAD"
    >
      <Box
        sx={{ px: 3, py: 2 }}
        data-cy="topic-card-minutes"
        className={`topic-card-minutes_${topic.type}${isEditable ? ' is-editable' : ''}`}
      >
        <ErrorMessage error={errorMessage} />
        {/* RESOLUTION - Not displayed on print view */}
        {['nominations', 'agendaApproval', 'resolution', 'pastMinutesApproval', 'adjournment'].includes(
          topic.type
        ) &&
          topic?.resolutionOutcome?.outcome &&
          topic?.isResolution && (
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography
                  sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: '12px', opacity: 0.8 }}
                  data-cy="topic-card-minutes_resolution-number"
                >
                  {t('common:resolution_one')}
                  {topic.resolutionNumber ? ` #${topic.resolutionNumber}` : ''}
                </Typography>
                {/* ADDING CUSTOM RESOLUTION NUMBER */}
                {isCustomResNum &&
                  userHasEditAccess &&
                  (isEditable ? (
                    <TextField
                      i18n={{ keyPrefix: keyPrefix + '.ResolutionNumberField' }}
                      name="resolutionNumber"
                      validationRules={{ maxLength: 250 }}
                      isOptional
                    />
                  ) : (
                    !topic.resolutionNumber && (
                      <Link
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          color: 'secondary.light',
                          textTransform: 'none',
                          fontSize: '1rem',
                          fontWeight: '400',
                          textDecoration: 'none',
                          mt: 1
                        }}
                        onClick={handleSetTopicEditable && handleSetTopicEditable(topic.id)}
                        component="button"
                        data-cy="topic-card-minutes_add-resolution-number-button"
                      >
                        <WarningAmberIcon sx={{ mr: 1 }} />
                        {t(keyPrefix + '.customResolutionNumberWarning')}
                      </Link>
                    )
                  ))}
              </Box>
              {/* EDIT BUTTON */}
              <Stack direction="row">
                {userHasEditAccess && (
                  <>
                    {isEditable && (
                      <>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ ml: 2 }}
                          data-cy="topic-card-minutes_save-button"
                        >
                          {t('common:labelSave')}
                        </Button>
                      </>
                    )}

                    {!isEditable && !(topic.type === 'inCamera') && (
                      <Tooltip title={t('common:labelEdit')}>
                        <IconButton
                          onClick={handleSetTopicEditable && handleSetTopicEditable(topic.id)}
                          data-cy="topic-card-minutes_edit-button"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
              </Stack>
            </Box>
          )}
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-evenly" flexGrow={1}>
              {/* ORDER & TITLE */}
              <Chip label={order} />

              {isEditable && checkIsGenericTopicType(topic.type) ? (
                <TextField
                  i18n={{ keyPrefix: keyPrefix + '.FieldTitle' }}
                  name="title"
                  validationRules={{ maxLength: 150 }}
                  sx={{ width: '100%' }}
                />
              ) : (
                <Typography sx={{ width: '100%', fontWeight: 'bold' }} data-cy="topic-card-minutes_title">
                  <>{topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}</>
                </Typography>
              )}
            </Stack>

            {/* EDIT BUTTON */}
            <Stack direction="row" display={topic.isResolution ? 'none' : 'flex'}>
              {userHasEditAccess && (
                <>
                  {isEditable && (
                    <>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ ml: 2 }}
                        data-cy="topic-card-minutes_save-button"
                      >
                        {t('common:labelSave')}
                      </Button>
                    </>
                  )}

                  {!isEditable && !(topic.type === 'inCamera') && (
                    <Tooltip title={t('common:labelEdit')}>
                      <IconButton
                        onClick={handleSetTopicEditable && handleSetTopicEditable(topic.id)}
                        data-cy="topic-card-minutes_edit-button"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </Stack>
          </Stack>
          {/* QUORUM  */}
          {topic.type === 'quorum' && (
            <Box>
              {/* Edit minutesStartedAt */}
              {meeting?.minutesStartedAt && isEditable ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: { sm: 3 },
                    flexDirection: { xs: 'column', sm: 'row' },
                    mb: { xs: 1, sm: 0 }
                  }}
                >
                  <Typography sx={{ width: '165px' }}>{t('MeetingStateReview.minutesStartedAt')}</Typography>
                  <DateTimePickerWithButton
                    dateTimePickerProps={{
                      name: 'minutesStartedAtOverwrittenTime',
                      i18n: { keyPrefix: keyPrefix + '.DateTimePickerMinutesStartedAtField' },
                      type: 'time',
                      validationRules: {
                        pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/g
                      },
                      sx: { width: '200px', mb: 0 }
                    }}
                    originalTimeValue={DateTime.fromISO(meeting?.minutesStartedAtOriginal || '').toFormat(
                      'HH:mm'
                    )}
                  />
                </Box>
              ) : (
                <Typography data-cy="topic-card-minutes_minutes-started-at">
                  {t('MeetingStateReview.meetingStarted', {
                    time: formatTime({ isoString: meeting?.minutesStartedAt || '', locale })
                  })}
                </Typography>
              )}

              {topic.quorumReachedAt ? (
                isEditable ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'baseline',
                      gap: { sm: 3 },
                      flexDirection: { xs: 'column', sm: 'row' },
                      mb: { xs: 1, sm: 0 }
                    }}
                  >
                    <Typography sx={{ width: '165px' }}>{t('MeetingStateReview.quorumReachedAt')}</Typography>
                    <DateTimePickerWithButton
                      dateTimePickerProps={{
                        name: 'quorumReachedAtOverwrittenTime',
                        i18n: { keyPrefix: keyPrefix + '.DateTimePickerQuorumReachedAtField' },
                        type: 'time',
                        validationRules: {
                          pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/g
                        },
                        sx: { width: '200px', mb: 0 }
                      }}
                      originalTimeValue={DateTime.fromISO(topic.quorumReachedAtOriginal || '').toFormat(
                        'HH:mm'
                      )}
                    />
                  </Box>
                ) : (
                  <Typography data-cy="topic-card-minutes_quorum-reached-at">
                    {t('MeetingStateReview.quorumReached', {
                      time: formatTime({ isoString: topic?.quorumReachedAt, locale })
                    })}
                  </Typography>
                )
              ) : null}
            </Box>
          )}
          {/* NOMINATIONS */}
          {topic.type === 'nominations' && <NominationsField topic={topic} isReview={true} />}
          {/* PAST MINUTES THAT HAVE BEEN APPROVED */}
          {topic.type === 'pastMinutesApproval' && isReviewMode ? (
            <PastMeetingsApprovedList meetingId={topic.meetingId} topicId={topic.id} topic={topic} />
          ) : (
            <ApprovedMinutesList approvedMinutes={topic.pastMeetingsToApprove} />
          )}
          {/* ADJOURNMENT */}
          {topic.type === 'adjournment' && (
            <Box>
              {meeting?.minutesEndedAt && isEditable ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: { sm: 3 },
                    flexDirection: { xs: 'column', sm: 'row' },
                    mb: { xs: 1, sm: 0 }
                  }}
                >
                  <Typography sx={{ width: '165px' }}>{t('MeetingStateReview.minutesEndedAt')}</Typography>
                  <DateTimePickerWithButton
                    dateTimePickerProps={{
                      name: 'minutesEndedAtOverwrittenTime',
                      i18n: { keyPrefix: keyPrefix + '.DateTimePickerMinutesEndedAtField' },
                      type: 'time',
                      validationRules: {
                        pattern: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/g
                      },
                      sx: { width: '200px', mb: 0 }
                    }}
                    originalTimeValue={DateTime.fromISO(meeting?.minutesEndedAtOriginal || '').toFormat(
                      'HH:mm'
                    )}
                  />
                </Box>
              ) : adjournmentAt ? (
                <Typography data-cy="topic-card-minutes_adjournment-at">
                  {t('MeetingStateReview.adjournmentAt', {
                    time: formatTime({
                      isoString: adjournmentAt,
                      locale
                    })
                  })}
                </Typography>
              ) : null}
            </Box>
          )}
          {/* RESOLUTION OUTCOME */}
          {['nominations', 'agendaApproval', 'resolution', 'pastMinutesApproval', 'adjournment'].includes(
            topic.type
          ) &&
            topic?.resolutionOutcome?.outcome && (
              <Box sx={{ marginTop: '12px', marginBottom: '12px' }}>
                <Stack spacing={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      border: isEditable ? '1px solid #dddddd' : '',
                      borderRadius: '4px',
                      alignItems: 'center',
                      padding: isEditable ? '8px 14px' : ''
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      {topic.isResolution && (
                        <ResolutionOutcomeIcon voteOutcome={topic?.resolutionOutcome?.outcome} />
                      )}

                      <ResolutionOutcomeText topic={topic} />
                    </Box>
                    {isEditable && (
                      <IconButton
                        onClick={handleShowEditResolutionDialog}
                        data-cy="topic-card-minutes_edit-proposition-button"
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </Box>
                  {showResolutionOutcomeVotes && <ResolutionOutcomeVotes topic={topic} />}
                </Stack>
              </Box>
            )}
          {/* BODY FIELD */}
          {[
            'quorum',
            'nominations',
            'agendaApproval',
            'pastMinutesApproval',
            'ceoReport',
            'information',
            'discussion',
            'resolution',
            'miscellaneous',
            'adjournment'
          ].includes(topic.type) && (
            <>
              {isEditable ? (
                <Box sx={{ mx: 1 }}>
                  <RichTextMultiLine
                    name="notes"
                    validationRules={{ maxLength: 20000 }}
                    isOptional={true}
                    height="unset"
                    i18n={{ keyPrefix: 'MeetingStateInProgress.RichTextMultiLineNotes' }}
                  />
                </Box>
              ) : (
                topic.notes && (
                  <RichTextReadOnly
                    value={topic.notes}
                    style="padding: 16px 0; margin-top: 8px; border-width: 0;"
                  />
                )
              )}
            </>
          )}
        </Stack>

        {/* DOCUMENTS LIST */}
        {[
          'pastMinutesApproval',
          'ceoReport',
          'information',
          'discussion',
          'resolution',
          'miscellaneous'
        ].includes(topic.type) &&
          (isReviewMode ? (
            //REVIEW - no approved topic
            <Stack sx={{ mt: 1 }} spacing={1}>
              {isEditable && (
                <Collapse in={!isMinutesDocumentAlertDismissed}>
                  <Alert
                    severity="info"
                    variant="outlined"
                    onClose={handleCloseDocumentAlert}
                    sx={{
                      backgroundColor: '#f6fbfa',
                      mb: 0.5
                    }}
                  >
                    <Box display="inline">{t(keyPrefix + '.minutesDocumentsAlertText') + ' '}</Box>
                    {!isBeingReviewedInsideMeeting && (
                      <Link
                        component={RouterLink}
                        sx={{
                          cursor: 'pointer',
                          color: 'secondary.light',
                          verticalAlign: 'inherit',
                          textDecoration: 'none'
                        }}
                        to={{
                          pathname: `/meetings/${topic.meetingId}/review/agenda`,
                          state: { activePath: '/pending-review' }
                        }}
                        type="button"
                      >
                        {t('common:viewMeeting')}
                      </Link>
                    )}
                  </Alert>
                </Collapse>
              )}
              <DocumentList
                isEditable={isEditable}
                path={`/meetings/${topic.meetingId}/topics/${topic.id}/minutes-documents`}
              />
            </Stack>
          ) : (
            hasDocuments && (
              //MINUTES AND TO SIGN PAGE - approved topic
              <DocumentList
                isEditable={false}
                path={`/approved-topics/${topic.id}/documents`}
                isAccordion={true}
                accordionOptions={{
                  defaultExpanded: true,
                  documentsVisible: 0
                }}
              />
            )
          ))}
      </Box>
    </FormControllerTemp>
  );
};

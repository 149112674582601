import { IMeeting } from 'types';
import { Typography, Box, Button } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { DocumentList } from 'components/DocumentList';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useScreenSize } from 'utils/useScreenSize';
import { RenderButtonLink } from 'components/RenderButtonLink';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface IPastMeetingCard {
  pastMeeting: IMeeting;
}
export const PastMeetingCard: React.FC<IPastMeetingCard> = (props) => {
  const { pastMeeting } = props;

  const { t } = useLocale();
  const history = useHistory();
  const meetingType = useCommitteeName(pastMeeting.committeeId as string);
  const { isMobileScreen } = useScreenSize();

  const hasDocuments = pastMeeting.topics?.some(
    (topic) => topic?.documentsIds && topic.documentsIds.length > 0
  );

  const keyPrefix = 'PastMeetings.PastMeetingCard';

  const renderViewMeetingButton = (): React.ReactNode => {
    return (
      <Button
        variant="text"
        component={RouterLink}
        to={{
          pathname: `/past-meetings/${pastMeeting.id}`,
          search: history.location?.search
        }}
        data-cy="past-meeting-card_view-meeting-button"
      >
        {t(keyPrefix + '.viewMeetingLabel')}
      </Button>
    );
  };

  return (
    <Box
      data-cy="past-meeting-card"
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '24px',
        borderRadius: '4px',
        border: '1px solid #dddddd',
        display: 'flex',
        flexDirection: 'column'
      }}
      gap="16px"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <DateTimeStartEnd
            isoStringDate={pastMeeting.startDateTime}
            separator={isMobileScreen ? undefined : ' | '}
            dateFormat="DATE_HUGE"
            isoStringStart={pastMeeting.startDateTime}
            isoStringEnd={pastMeeting.endDateTime}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', alignItems: 'flex-end' }}>
          {renderViewMeetingButton()}
        </Box>
      </Box>
      <Typography
        sx={{ fontSize: '16px', overflow: 'hidden', textOverflow: 'ellipsis' }}
        variant="body1"
        component="h3"
      >
        {pastMeeting.title}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: { md: 'center' },
          gap: { xs: 0.5, md: 2 },
          flexDirection: { xs: 'column', md: 'row' }
        }}
      >
        <Typography variant="body2" sx={{ opacity: '0.6' }}>
          {meetingType}
        </Typography>
        <Typography sx={{ display: { xs: 'none', md: 'block' } }}>&#183;</Typography>

        <Typography variant="body2" sx={{ opacity: '0.6' }}>
          {t(`common:locationType.${pastMeeting.location?.type}`)}
        </Typography>
        <Typography sx={{ display: { xs: 'none', md: 'block' } }}>&#183;</Typography>

        <Typography variant="body2" sx={{ opacity: '0.6' }}>
          {t(keyPrefix + '.participantsInvited', { count: pastMeeting.participants?.length })}
        </Typography>
      </Box>

      <Box>
        {pastMeeting?.minuteId ? (
          <RenderButtonLink
            to={{
              pathname: `/past-meetings/minutes/${pastMeeting.minuteId}`,
              search: history.location?.search
            }}
            label={t('common:boardMinutes')}
            icon={<AccessTimeIcon />}
            size="small"
          />
        ) : (
          <RenderButtonLink
            to={''}
            label={t(keyPrefix + '.viewMinutesLabelDisabled')}
            icon={<AccessTimeIcon />}
            size="small"
            disabled
          />
        )}
      </Box>

      {hasDocuments && (
        <Box>
          <DocumentList
            path={`/meetings/${pastMeeting.id}/documents`}
            showDivider
            isAccordion
            accordionOptions={{ documentsVisible: 0, label: t(keyPrefix + '.documentationLabel') }}
            sxDivider={{ mt: '0px' }}
          />
        </Box>
      )}

      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        {renderViewMeetingButton()}
      </Box>
    </Box>
  );
};

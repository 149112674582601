import React, { useEffect, useState } from 'react';
import { App as AppCore, Loading, Pages } from '@koopajs/mui';
import {
  useComponentVisibility,
  useLocale,
  useUserShow,
  useResourceList,
  useWorkspaceShow
} from '@koopajs/react';
import { useScreenSize } from 'utils/useScreenSize';
import { Dialogs } from 'components/Dialogs';
import { Onboarding } from './Onboarding';
import { UserProfile } from './UserProfile';
import { Router } from 'pages/Router';
import { Header } from './Header';
import { theme } from './theme';
import { Drawer, Box, Stack } from '@mui/material';
import { useTimezone } from 'components/temp/useTimezoneTemp';
import { ICommittee, IWorkspace, ICommitteeMember } from 'types';
import { Helmet } from 'react-helmet';
import { WorkspaceNotApproved } from '../Modals/WorkspaceNotApproved';
import { useRouteMatch } from 'react-router-dom';
import { InvitationPageTemp } from 'components/temp/inviation/InvitationTemp';
import { IUserPublicProfile } from '@koopajs/app';
import { NavDrawer } from './Header/NavDrawer';
import { DemoWorkspaceBanner } from 'components/DemoWorkspaceBanner';
import { StatsigClient } from '@statsig/js-client';
import { StatsigProvider } from '@statsig/react-bindings';
import { runStatsigSessionReplay } from '@statsig/session-replay';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { ReferralBanner } from 'components/ReferralBanner';
import { Api } from '@koopajs/app';
import { DateTime, Interval } from 'luxon';
import { WarningMandateBanner } from 'components/WarningMandateBanner';

const statsigClient: StatsigClient = new StatsigClient(
  process.env.REACT_APP_STATSIG_API_KEY!,
  { userID: '' },
  { environment: { tier: process.env.REACT_APP_STATSIG_ENVIRONMENT! } }
);

runStatsigSessionReplay(statsigClient);
runStatsigAutoCapture(statsigClient);
statsigClient.initializeSync();

const Authentication: React.FC = () => <Pages.AuthenticationPage appName="Panorama" />;

export const TransparentLoading = (): JSX.Element => {
  return <Loading sx={{ backgroundColor: 'transparent' }} />;
};

const AppInternal = (): JSX.Element => {
  const userMenu = useComponentVisibility('userMenu');
  const { isScreenAboveSm } = useScreenSize();
  const { workspace } = useWorkspaceShow<IWorkspace>();
  const match = useRouteMatch('/invitation/:token');
  const urlParams = match?.params as { token?: string };
  const token = urlParams?.token;

  const [committeeNamesExpiredMandate, setCommitteeNamesExpiredMandate] = useState<string[]>([]);
  const [committeeNamesExpiringMandate, setCommitteeNamesExpiringMandate] = useState<string[]>([]);

  const { resources: committees } = useResourceList<ICommittee>({ path: '/committees' });

  useResourceList<IUserPublicProfile>({ path: '/users', searchParams: { size: 50 } });

  // TODO: move locale logic into app context
  const { setLocale, locale } = useLocale();
  const user = useUserShow();
  const { timezone, setTimezone } = useTimezone();

  useEffect(() => {
    const updateLocale = async (locale: string): Promise<void> => {
      await setLocale(locale);
    };
    updateLocale((user?.user?.locale as string) || locale).catch(console.error);
  }, [user?.user?.locale]);

  // TODO: I think similar to updating locale, there is probably a more appropriate place to do this
  useEffect(() => {
    const updateTimezone = async (timezone: string): Promise<void> => {
      await setTimezone(timezone, { skipUpdateUser: true });
    };
    updateTimezone((user?.user?.timezone as string) || timezone).catch(console.error);
  }, [user?.user?.timezone]);

  useEffect(() => {
    statsigClient.updateUserAsync({ userID: user.user?.id }).catch((e) => {
      throw e;
    });
  }, []);

  //checks if user has expired or expiring mandates
  useEffect(() => {
    const fetchCommitteeMembers = async () => {
      const res = await Api.client.get(
        `/committee-members?size=50&filters[]=${encodeURIComponent(`userId:"${user?.user?.id}"`)}`
      );
      return res;
    };

    const getCommitteeData = async (): Promise<void> => {
      const startOfToday = DateTime.now().toUTC().startOf('day');
      const startOfTodayPlus60Days = DateTime.now().toUTC().startOf('day').plus({ days: 60 });
      const committeeMembers = await fetchCommitteeMembers();
      const expiringMandateDateInterval = Interval.fromDateTimes(startOfToday, startOfTodayPlus60Days);

      const expiredMandates = committeeMembers.data.resources.filter((r: ICommitteeMember) => {
        return r.endAt && DateTime.fromISO(r.endAt).toUTC() < startOfToday;
      });

      const expiringMandates = committeeMembers.data.resources.filter((r: ICommitteeMember) => {
        return r.endAt && expiringMandateDateInterval.contains(DateTime.fromISO(r.endAt).toUTC());
      });

      if (expiredMandates.length > 0) {
        const committeeIds = expiredMandates.map((r: ICommitteeMember) => r.committeeId);
        const committeeNames = committees?.filter((c) => committeeIds.includes(c.id)).map((c) => c.name);
        setCommitteeNamesExpiredMandate(committeeNames);
      }

      if (expiringMandates.length > 0) {
        const committeeIds = expiringMandates.map((r: ICommitteeMember) => r.committeeId);
        const committeeNames = committees?.filter((c) => committeeIds.includes(c.id)).map((c) => c.name);
        setCommitteeNamesExpiringMandate(committeeNames);
      }
    };

    getCommitteeData().catch(console.error);
  }, [user?.user?.id, committees.length]);

  if (token) {
    return <InvitationPageTemp />;
  }

  if (!workspace?.isApproved) {
    return <WorkspaceNotApproved />;
  }
  return (
    <>
      <Helmet>
        <html lang={locale.slice(0, 2)} />
        <title>Panorama</title>
      </Helmet>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <NavDrawer />
        <Stack flexDirection="column" sx={{ width: '100%', minWidth: '0px' }}>
          <>
            {workspace?.isDemo && <DemoWorkspaceBanner />}
            {committeeNamesExpiredMandate && committeeNamesExpiredMandate.length > 0 ? (
              <WarningMandateBanner
                committeeNames={committeeNamesExpiredMandate}
                userId={user?.user?.id}
                mandateStatus="expired"
              />
            ) : (
              committeeNamesExpiringMandate &&
              committeeNamesExpiringMandate.length > 0 && (
                <WarningMandateBanner
                  committeeNames={committeeNamesExpiringMandate}
                  userId={user?.user?.id}
                  mandateStatus="expiring"
                />
              )
            )}

            <Box sx={{ display: { lg: 'none' } }}>
              <ReferralBanner />
            </Box>
            <Router />
          </>
        </Stack>
      </Box>
      <Dialogs />
      <Drawer
        anchor={isScreenAboveSm ? 'right' : 'bottom'}
        open={userMenu.isVisible}
        onClose={userMenu.setHidden}
      >
        <UserProfile />
      </Drawer>
    </>
  );
};

export const App: React.FC = () => (
  <AppCore.Provider
    muiTheme={theme}
    componentOnboarding={Onboarding}
    componentAuthentication={Authentication}
    componentLoading={TransparentLoading}
    header={Header}
  >
    <StatsigProvider client={statsigClient}>
      <div style={{ height: '100%' }}>
        <AppInternal />
      </div>
    </StatsigProvider>
  </AppCore.Provider>
);

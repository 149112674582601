import { IMeeting, IMinute, ITopic } from 'types';
import { Stack, Typography, Paper, Box, List, Tooltip, IconButton, Button } from '@mui/material';
import { useLocale, useResourceUpdate } from '@koopajs/react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { UserList } from './UserList';
import { useCommitteeName } from './hooks/useCommitteeName';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Edit as EditIcon } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import { FormControllerTemp } from './temp/FormControllerTemp';
import { UsersCheckboxesRows } from './UsersCheckboxesRows';
import { getCommitteeMembersTitlesObj } from 'utils/getCommitteeMembersTitlesObj';

interface IMinutesHeaderCard {
  minutes: IMeeting | IMinute;
  isViewPrint?: boolean;
  userHasEditAccess?: boolean;
}

//Component used for Review meeting, Sign minutes, Dialog View minutes book

export const MinutesHeaderCard: React.FC<IMinutesHeaderCard> = (props) => {
  const { minutes, isViewPrint, userHasEditAccess } = props;

  const [isEditable, setIsEditable] = useState(false);

  const { t } = useLocale();
  const keyPrefix = 'MeetingEdit.MeetingStaticCard';
  const presentParticipants = minutes.participants?.filter((p) => p.isPresent);
  const absentParticipants = minutes.participants?.filter((p) => !p.isPresent);

  const presentParticipantsIds = presentParticipants?.map((p) => p.userId);

  const minutesParticipantsIds = minutes.participants?.map((p) => p.userId);

  const minutesType = useCommitteeName(minutes.committeeId as string);

  const setToEditMode = useCallback(() => {
    setIsEditable(true);
  }, []);

  //if review - the type is IMeeting (there is topics array)
  const isIMeeting = (obj: any): obj is IMeeting => {
    return obj && obj.topics !== undefined;
  };
  let quorumId;
  if (isIMeeting(minutes)) {
    quorumId = minutes.topics?.find((t: ITopic) => t.type === 'quorum')?.id;
  }

  const { updateResource: updateQuorumTopic } = useResourceUpdate({
    path: `/meetings/${minutes.id}/topics`,
    id: quorumId as string,
    customReducer: {
      path: {
        resourceType: `/meetings`,
        resourceId: minutes.id
      },
      mapping: (prevObj: unknown, newObj: unknown) => {
        const meeting = prevObj as IMeeting;
        const editedTopic = newObj as ITopic;

        const updatedMeeting: IMeeting = {
          ...meeting,
          topics: meeting?.topics?.map((t: ITopic) => {
            if (t.id === editedTopic.id) {
              return editedTopic;
            } else {
              return t;
            }
          })
        };

        if (editedTopic.quorumParticipants?.length === updatedMeeting.participants?.length) {
          updatedMeeting.participants = editedTopic.quorumParticipants;
        }
        return updatedMeeting;
      }
    }
  });
  const handleSubmit = useCallback(
    async (formData: object) => {
      const formDataTyped = formData as {
        participants?: { [k: string]: boolean };
      };
      const updatedParticipants =
        formDataTyped.participants &&
        Object.entries(formDataTyped.participants).map((p) => ({
          userId: p[0],
          isPresent: Boolean(p[1])
        }));

      const topicUpdateResponse = await updateQuorumTopic({ quorumParticipants: updatedParticipants });

      if (topicUpdateResponse) {
        setIsEditable(false);
      }
      return topicUpdateResponse;
    },
    [updateQuorumTopic]
  );

  const committeeMembersTitlesObj = getCommitteeMembersTitlesObj(minutes.activeCommitteeMembers);

  const defaultValParticipantsObj = presentParticipantsIds?.reduce((acc: { [k: string]: boolean }, id, i) => {
    acc[id] = true;
    return acc;
  }, {});

  return (
    <FormControllerTemp
      onSubmit={handleSubmit}
      defaultValues={defaultValParticipantsObj}
      onSubmitSuccessResetType="FORM_PAYLOAD"
    >
      <Paper
        sx={{
          pt: isViewPrint ? 1 : 3,
          pb: 1,
          px: 3,
          my: 2,
          display: 'flex',
          border: isViewPrint ? 0 : undefined
        }}
        variant="outlined"
        data-cy="minutes-header-card"
      >
        <Stack sx={{ flexGrow: 1 }}>
          {/* desktop - title */}
          <Typography
            sx={{
              display: isViewPrint ? 'flex' : { xs: 'none', md: 'flex' },
              marginBottom: '14px'
            }}
            variant="h6"
          >
            <Box component="span" sx={{ display: 'inline' }}>
              {t('common:boardMinutes')}
            </Box>{' '}
            {minutesType && (
              <>
                <Box component="span">&nbsp; | &nbsp;</Box>
                <Box component="span">{minutesType}</Box>
              </>
            )}
          </Typography>
          {/* mobile - title: 2 lines */}
          <Box
            sx={{
              display: isViewPrint ? 'none' : { xs: 'flex', md: 'none' },
              marginBottom: '14px',
              flexDirection: 'column'
            }}
          >
            <Typography variant="h6">{t('common:boardMinutes')}</Typography>
            <Typography variant="h6">{minutesType}</Typography>
          </Box>

          {/* minutes title */}
          <Typography
            sx={{ marginBottom: '14px', fontSize: '18px' }}
            variant="body1"
            data-cy="minutes-header-card_minutes-title"
          >
            {minutes.title}
          </Typography>

          {/* DATE */}
          <Stack key="date" sx={{ flexDirection: 'row', alignItems: 'center' }}>
            {!isViewPrint && <CalendarTodayIcon color="action" sx={{ mr: { xs: 0.5, md: 2 }, opacity: 1 }} />}
            <Typography variant="body2" sx={{ display: 'inline' }}>
              <DateTimeStartEnd
                isoStringDate={minutes.startDateTime}
                dateFormat="DATE_HUGE"
                separator={` ${t('common:labelFrom2')} `}
                isoStringStart={minutes.startDateTime}
                isoStringEnd={minutes.endDateTime}
              />
            </Typography>

            {/* Desktop location when there is no address (Row) */}
            {minutes?.location && minutes.location?.type === 'remote' && (
              <Box sx={{ display: isViewPrint ? 'flex' : { xs: 'none', md: 'flex' } }}>
                <Box sx={{ mx: 4 }}>&#183;</Box>
                {!isViewPrint && (
                  <LocationOnIcon color="action" sx={{ mr: { xs: 0.5, md: 2 }, opacity: 1 }} />
                )}
                <Typography key="locationType" variant="body2">
                  {t(`common:locationType.${minutes.location?.type}`)}
                </Typography>
              </Box>
            )}
          </Stack>

          {/* Mobile location when there is no address (Column) */}
          {minutes?.location && minutes.location?.type === 'remote' && (
            <Box sx={{ display: isViewPrint ? 'none' : { xs: 'flex', md: 'none' }, mt: 1 }}>
              {!isViewPrint && <LocationOnIcon color="action" sx={{ mr: { xs: 0.5, md: 2 }, opacity: 1 }} />}
              <Typography key="locationType" variant="body2">
                {t(`common:locationType.${minutes.location?.type}`)}
              </Typography>
            </Box>
          )}

          {/* ADDRESS */}
          <Stack
            sx={{
              flexDirection: { xs: 'colum', sm: 'row' },
              alignItems: { xs: 'left', sm: 'center' },
              mt: { xs: 1, md: minutes?.location?.type !== 'remote' ? 1 : 0 }
            }}
          >
            {minutes.location?.address && (
              <>
                <Box
                  sx={{
                    whiteSpace: 'pre-wrap',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: '0px'
                  }}
                >
                  {!isViewPrint && (
                    <LocationOnIcon color="action" sx={{ mr: { xs: 0.5, md: 2 }, opacity: 1 }} />
                  )}
                  <Typography variant="body2" sx={{ minWidth: '0px', wordWrap: 'break-word' }}>
                    {minutes.location?.address}
                  </Typography>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'block' }, mx: 4, my: 1 }}>&#183;</Box>
              </>
            )}

            {/* Mobile location when there is address */}
            {minutes?.location && minutes.location?.type !== 'remote' && (
              <Typography
                key="locationType"
                variant="body2"
                sx={{ ml: { xs: isViewPrint ? 0 : 3.5, sm: 0 } }}
              >
                {t(`common:locationType.${minutes.location?.type}`)}
              </Typography>
            )}
          </Stack>

          {isEditable && userHasEditAccess ? (
            <UsersCheckboxesRows
              usersIds={minutesParticipantsIds}
              defaultValues={presentParticipantsIds}
              isCheckboxLabelAvatar
              formName="participants"
              label={t('common:attendance')}
              sxProps={{ border: '1px solid #BDBDBD', boxShadow: 'none', mb: 3 }}
              committeeMembersTitles={committeeMembersTitlesObj}
            />
          ) : (
            <Box sx={{ display: 'flex', mt: 3, flexDirection: 'column' }}>
              <Box>
                {presentParticipants && presentParticipants?.length > 0 && (
                  <>
                    <Typography variant="body2">
                      {t(keyPrefix + '.presentParticipants', { count: presentParticipants.length })}
                    </Typography>
                    <List sx={{ pt: 0 }}>
                      <UserList
                        userIds={presentParticipants?.map((user) => user.userId)}
                        icon={<FiberManualRecordIcon color="action" sx={{ fontSize: '12px', mr: 1 }} />}
                        listSx={{
                          display: 'grid',
                          gridTemplateColumns: isViewPrint
                            ? '1fr 1fr 1fr'
                            : { xs: '1fr', sm: '1fr 1fr', lg: '1fr 1fr 1fr' }
                        }}
                      />
                    </List>
                  </>
                )}
              </Box>
              <Box>
                {absentParticipants && absentParticipants?.length > 0 && (
                  <>
                    <Typography variant="body2">
                      {t(keyPrefix + '.absentParticipants', { count: absentParticipants.length })}
                    </Typography>
                    <List sx={{ pt: 0 }}>
                      <UserList
                        userIds={absentParticipants?.map((user) => user.userId)}
                        icon={<FiberManualRecordIcon color="action" sx={{ fontSize: '12px', mr: 1 }} />}
                        listSx={{
                          display: 'grid',
                          gridTemplateColumns: isViewPrint
                            ? '1fr 1fr 1fr'
                            : { xs: '1fr', sm: '1fr 1fr', lg: '1fr 1fr 1fr' }
                        }}
                      />
                    </List>
                  </>
                )}
              </Box>
            </Box>
          )}
        </Stack>
        <Box>
          {userHasEditAccess &&
            (isEditable ? (
              <Button type="submit" variant="contained">
                {t('common:labelSave')}
              </Button>
            ) : (
              <Tooltip title={t('common:labelEdit')}>
                <IconButton onClick={setToEditMode}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ))}
        </Box>
      </Paper>
    </FormControllerTemp>
  );
};

import React, { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import { RichTextMultiLineMenuBar } from './MenuBar';
import './style.css';
import { FormState, FieldValues } from 'react-hook-form';
import { FormHelperText, Box, Typography } from '@mui/material';
import { ICoreProps } from '@koopajs/mui';
import { useLocale } from '@koopajs/react';
import { Editor } from '@tiptap/core/dist/packages/core/src/Editor';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';

export interface ITipTapEditorEditableProps extends ICoreProps {
  helperText?: string;
  height?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  isOptional?: boolean;
  onBlur?: (v: string) => void;
  onFocus?: (v: string) => void;
  sxEditorContainer?: SxProps<Theme>;
  isLabelVisible?: boolean;
  textFieldVariant?: 'default' | 'transparent';
  isAutofocused?: boolean;
}

export interface ITipTapEditorProps extends ITipTapEditorEditableProps {
  value: string;
  onChange: (...event: unknown[]) => void;
  formState: FormState<FieldValues>;
  isError?: boolean;
  testId: string;
}

export const TipTapEditor: React.FC<ITipTapEditorProps> = (props) => {
  const {
    value,
    onChange,
    formState,
    helperText,
    isError,
    height,
    minHeight,
    maxHeight,
    isOptional,
    isDisabled,
    i18n,
    onBlur,
    onFocus,
    sx,
    sxEditorContainer,
    testId,
    isLabelVisible,
    textFieldVariant,
    isAutofocused
  } = props;

  const { isDirty, isSubmitSuccessful } = formState;

  const { t } = useLocale();
  // const coreKeyPrefix = 'DialogCreate';
  const keyPrefix = i18n?.keyPrefix;
  // || coreKeyPrefix;

  const generateEditorAttributes = (): Record<string, string> => {
    let style = '';
    const generateValue = (value: string | number): string => {
      if (typeof value === 'number') return value + 'px';
      return value;
    };
    if (height) style = style + `height: ${generateValue(height)};`;
    if (minHeight) style = style + `min-height: ${generateValue(minHeight)};`;
    if (maxHeight) style = style + `max-height: ${generateValue(maxHeight)};`;

    return {
      class: `tiptap-editor`,
      role: 'textbox',
      'aria-multiline': 'true',
      ...(isLabelVisible ? {} : { 'aria-label': t(keyPrefix + '.label') }),
      ...(isLabelVisible ? { 'aria-labelledby': testId + '_label' } : {}),
      'aria-required': isOptional ? 'true' : 'false',
      style,
      'data-cy': testId
    };
  };

  const processEditorContent = (editor: Editor): string => {
    let content = editor.getHTML();
    // sets empty p tag to be empty string
    if (content === '<p></p>') content = '';

    return content;
  };

  const editor = useEditor({
    extensions: [
      Link.extend({
        inclusive: false // link ends on space
      }),
      StarterKit.configure({ heading: false, codeBlock: false, horizontalRule: false }),
      Placeholder.configure({
        placeholder: t(keyPrefix + '.placeholder')
      }),
      Table,
      TableRow,
      TableHeader,
      TableCell
    ],
    content: value,
    parseOptions: {
      preserveWhitespace: 'full'
    },
    onCreate: ({ editor }) => {
      let processedContent = processEditorContent(editor);
      // replaces new lines (\n) with `<br>` when editor loads
      processedContent = processedContent.replace(/(?:\r\n|\r|\n)/g, '<br>');
      onChange(processedContent);
      editor.commands.setContent(processedContent);
    },
    onUpdate: ({ editor }) => onChange(processEditorContent(editor)),
    onFocus: ({ editor }) => {
      if (onFocus) onFocus(processEditorContent(editor));
    },
    onBlur: ({ editor }) => {
      if (onBlur) onBlur(processEditorContent(editor));
    },
    editorProps: {
      attributes: generateEditorAttributes()
    },
    editable: !(formState.isSubmitting || isDisabled)
  });

  useEffect(() => {
    if (isAutofocused) editor?.commands.focus();
  }, [editor]);

  // simulates react hook form's reset behaviour
  useEffect(() => {
    if (isSubmitSuccessful && !isDirty) {
      editor?.commands.setContent(value);
    }
  }, [isDirty]);

  // updates classes/style
  useEffect(() => {
    editor?.setOptions({
      editorProps: {
        attributes: generateEditorAttributes()
      }
    });
  }, [height, maxHeight, minHeight]);

  // sets disabled/editable attribute
  useEffect(() => {
    editor?.setOptions({ editable: !(formState.isSubmitting || isDisabled) });
  }, [formState.isSubmitting, isDisabled]);

  return (
    <Box sx={sx}>
      {isLabelVisible && (
        <Typography variant="caption" component="div" sx={{ mb: 1 }} id={testId + '_label'}>
          {t(keyPrefix + '.label')}
        </Typography>
      )}
      <RichTextMultiLineMenuBar
        editor={editor}
        isDisabled={formState.isSubmitting || Boolean(isDisabled)}
        i18n={{ keyPrefix: `${keyPrefix}.MenuBar` }}
        isError={isError}
      />
      <Box
        sx={{
          fontSize: '1rem',
          lineHeight: '1.4375em',
          letterSpacing: '0.00938em',
          color: 'rgba(0, 0, 0, 0.87)',
          ...sxEditorContainer
        }}
        className={`tiptap-editor-container variant-${textFieldVariant} ${isError ? 'validation-error' : ''}`}
      >
        <EditorContent editor={editor} />
      </Box>
      <FormHelperText error={isError}>{helperText}</FormHelperText>
    </Box>
  );
};

export default TipTapEditor;

import {
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { IUseLiveMeetingResponse } from './useLiveMeeting';
import { Person } from '@mui/icons-material';
import { User } from '@koopajs/mui';
import { checkIsGenericTopicType } from 'utils/topicTypeArrays';
import { TopicChip } from 'components/TopicChip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { DocumentListInProgress } from './Form/Fields/DocumentListInProgress';
import { ApproveMinutesList } from './Header/ApproveMinutes/List';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs } from 'components/Breadcrumbs';

export interface IMeetingInProgressSidebarProps {
  liveMeeting: IUseLiveMeetingResponse;
}

export const MeetingInProgressSidebar: React.FC<IMeetingInProgressSidebarProps> = (props) => {
  const { meeting, currentTopicIndex, t } = props.liveMeeting;

  return (
    <Stack height="100%" gap={3} sx={{ padding: '24px' }} data-cy="meeting-in-progress_sidebar">
      <Breadcrumbs
        hierarchyArray={[{ path: '/', text: t('common:navigation.dashboard') }]}
        pageTitle={meeting?.title || ''}
        sx={{ mb: 3, display: { xs: 'none', md: 'block' } }}
      />

      <Typography variant="h6" color="initial">
        {t('common:agenda')}
      </Typography>

      <Stepper orientation="vertical" nonLinear activeStep={currentTopicIndex} sx={{ pb: 3 }}>
        {meeting.topics?.map((topic, index) => (
          <Step key={topic?.id} completed={index < currentTopicIndex}>
            <StepLabel
              sx={{ overflow: 'hidden' }}
              StepIconProps={{
                sx: { '&.Mui-completed': { color: '#565D87' }, '&.Mui-active': { color: '#565D87' } }
              }}
              data-cy="meeting-in-progress_sidebar-topic_title"
            >
              {topic?.title || t('common:topicTypes.' + topic?.type) || topic?.type}
            </StepLabel>
            <StepContent>
              {/* OWNER & TOPIC CHIP */}
              <Stack direction="row" gap={'14px 24px'} flexWrap="wrap" sx={{ pt: 0.75 }}>
                {/* topic chip */}
                {topic?.type && checkIsGenericTopicType(topic?.type) && (
                  <TopicChip
                    topicType={topic.type}
                    variant="outlined"
                    color="default"
                    sx={{ backgroundColor: '#FFF' }}
                  />
                )}
                {topic?.type &&
                  ['nominations', 'agendaApproval', 'pastMinutesApproval', 'adjournment'].includes(
                    topic.type
                  ) && (
                    <TopicChip
                      topicType="resolution"
                      variant="outlined"
                      color="default"
                      sx={{ backgroundColor: '#FFF' }}
                    />
                  )}
                {topic?.type === 'ceoReport' && (
                  <TopicChip
                    topicType="information"
                    variant="outlined"
                    color="default"
                    sx={{ backgroundColor: '#FFF' }}
                  />
                )}
                {/* assigned to */}
                {topic.assignedTo && (
                  <Stack alignItems="center" direction="row" gap={1}>
                    <Person sx={{ opacity: '0.54', fontSize: 18 }} />
                    <User
                      variant="text-only"
                      variantText="caption"
                      sxText={{ opacity: 0.6 }}
                      id={topic?.assignedTo || ''}
                    />
                  </Stack>
                )}
              </Stack>

              {/* PAST MEETINGS */}
              {topic?.type === 'pastMinutesApproval' && (
                <Box sx={{ mt: '14px' }}>
                  <ApproveMinutesList liveMeeting={props.liveMeeting} isSidebarSection={true} />
                </Box>
              )}

              {/* DOCUMENTS */}
              {
                <DocumentListInProgress
                  liveMeeting={props.liveMeeting}
                  sx={{ mt: '14px' }}
                  isAgendaDocuments={true}
                />
              }

              {/* DESCRIPTION */}
              {topic.description && (
                <Accordion
                  elevation={0}
                  sx={{
                    mt: '14px!important',
                    backgroundColor: 'transparent',
                    '&:before': {
                      display: 'none'
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ fontSize: 14 }} />}
                    aria-controls="meeting-details_content"
                    id="meeting-details_content"
                    sx={{
                      gap: 0.5,
                      minHeight: '0px!important',
                      padding: 0,
                      justifyContent: 'start',
                      '& .MuiAccordionSummary-content': { m: '0px!important', flexGrow: 0 }
                    }}
                  >
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      {t('common:details')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0, pt: 0.5 }}>
                    <RichTextReadOnly
                      value={topic.description}
                      sxEditorContainer={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: '4px',
                        padding: '14px',
                        fontSize: '14px'
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

import { Stack, Box, Button, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { useCallback } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { BodyField } from './Fields/BodyField';
import { IUseLiveMeetingResponse } from '../useLiveMeeting';
import { MeetingInProgressFormController } from './FormController';
import { formatTime } from 'utils/DateTime/formatTime';
import { UsersCheckboxesRows } from 'components/UsersCheckboxesRows';
import { getCommitteeMembersTitlesObj } from 'utils/getCommitteeMembersTitlesObj';
import { sxButtonContainedLight } from 'utils/muiStyles/Button/containedLight';
import { TopicTitle } from '../Header/TopicTitle';

interface IQuorumFields {
  liveMeeting: IUseLiveMeetingResponse;
}

export const QuorumFields: React.FC<IQuorumFields> = (props) => {
  const { topic, handleTopicUpdate, t, locale, meeting } = props.liveMeeting;
  const keyPrefix = 'MeetingStateInProgress.QuorumFields';

  const handleAddQuorum = useCallback(async () => {
    await handleTopicUpdate({
      quorumReachedAtOriginal: new Date().toISOString()
    });
  }, [handleTopicUpdate]);

  const committeeMembersTitlesObj = getCommitteeMembersTitlesObj(meeting.activeCommitteeMembers);

  return (
    <MeetingInProgressFormController liveMeeting={props.liveMeeting}>
      <TopicTitle liveMeeting={props.liveMeeting} />
      <Stack direction="column" spacing={3}>
        <Box sx={{ display: 'flex', mt: 1 }}>
          {meeting?.minutesStartedAt && (
            <Typography variant="body1" color="initial">
              {t('MeetingStateReview.meetingStarted', {
                time: formatTime({ isoString: meeting.minutesStartedAt, locale })
              })}
            </Typography>
          )}
        </Box>

        <Box sx={{ display: 'flex' }}>
          {!topic?.quorumReachedAt ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddQuorum}
              startIcon={<CheckBoxOutlineBlankIcon />}
              data-cy="meeting-in-progress_record-quorum-button"
              sx={{ ...sxButtonContainedLight }}
            >
              {t(keyPrefix + '.recordQuorum')}
            </Button>
          ) : (
            <Stack
              flexDirection="row"
              sx={{ display: 'flex', width: '100%' }}
              data-cy="meeting-in-progress_quorum-reached-banner"
            >
              <CheckCircle color="success" sx={{ mr: 1.5 }} />
              <Typography variant="body1" color="initial">
                {t(keyPrefix + '.quorumReachedAt', {
                  time: formatTime({ isoString: topic.quorumReachedAt, locale })
                })}
              </Typography>
            </Stack>
          )}
        </Box>
        <UsersCheckboxesRows
          usersIds={meeting?.participants?.map((p) => p.userId)}
          defaultValues={topic?.quorumParticipants?.filter((p) => p.isPresent).map((p) => p.userId)}
          formName="quorumParticipants"
          label={t(keyPrefix + '.attendance')}
          isCheckboxLabelAvatar
          sxProps={{ border: '1px solid #0000001f', boxShadow: 'none' }}
          committeeMembersTitles={committeeMembersTitlesObj}
        />
      </Stack>
      <BodyField variant="small" isNotAutofocused />
    </MeetingInProgressFormController>
  );
};

import { IMeeting } from 'types';
import { useCallback, useMemo, Dispatch, SetStateAction } from 'react';
import { useResourceUpdate, useLocale, useUserShow } from '@koopajs/react';
import { ErrorMessage } from '@koopajs/mui';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Divider,
  Tooltip,
  Stack
} from '@mui/material';
import { DateTime } from 'luxon';
import { IUserPublicProfile } from '@koopajs/app';
import { OpenInNew as OpenInNewIcon, Edit as EditIcon } from '@mui/icons-material';
import { processUsersForInput } from 'utils/processUsersForInput';
import { ParticipantsList } from '../../ParticipantsList';
import { useMeetingCreateForm } from 'components/hooks/useMeetingCreateForm';
import { MeetingCreateForm } from '../MeetingCreateForm';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { TimeStartEnd } from '../../TimeStartEnd';
import { formatDate } from 'utils/DateTime/formatDate';
import { formatTime } from 'utils/DateTime/formatTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { VisibleByAccordion } from 'components/VisibleByAccordion';
import { MailOutline as MailOutlineIcon } from '@mui/icons-material';

interface IMeetingEditHeaderCardProps {
  meeting: IMeeting;
  users: IUserPublicProfile[];
  showRequiredErrors?: boolean;
  isMeetingEditable: boolean;
  setMeetingEditable: Dispatch<SetStateAction<boolean>>;
}

export const MeetingEditHeaderCard: React.FC<IMeetingEditHeaderCardProps> = (props) => {
  const { meeting, users, showRequiredErrors, isMeetingEditable, setMeetingEditable } = props;

  const { handleFormData } = useMeetingCreateForm();

  const { t, locale } = useLocale();
  const keyPrefix = 'MeetingEdit';

  const { user: currentUser } = useUserShow();
  const isUserMeetingCreator = Boolean(currentUser?.id && meeting.meetingCreatorIds.includes(currentUser.id));

  const handleEditMeetingView = useCallback((value: boolean) => {
    return () => {
      setMeetingEditable(value);
    };
  }, []);
  const meetingType = useCommitteeName(meeting?.committeeId as string);

  const { updateResource, errorMessage } = useResourceUpdate({
    path: '/meetings',
    id: meeting?.id
  });

  const onSubmitMeeting = useCallback(
    async (formData: object): Promise<boolean> => {
      const form = handleFormData(formData);

      const res = await updateResource({
        ...form,
        participants: form.participants && form.participants.length ? form.participants : []
      });

      if (res) setMeetingEditable(false);
      return res;
    },
    [meeting?.id]
  );

  const defaultParticipants = useMemo(() => {
    return processUsersForInput({ users, selectedUserIds: meeting.participants?.map((user) => user.userId) });
  }, [JSON.stringify(users), JSON.stringify(meeting.participants)]);

  const showMeetingUrlField = meeting.location?.type === 'hybrid' || meeting.location?.type === 'remote';
  const showMeetingAddressField =
    meeting.location?.type === 'hybrid' || meeting.location?.type === 'inPerson';

  const startTimeISOString =
    meeting.startTime &&
    DateTime.fromFormat(meeting?.startTime as string, 'HH:mm', {
      zone: 'UTC'
    }).toString();
  const endTimeISOString =
    meeting.endTime &&
    DateTime.fromFormat(meeting?.endTime as string, 'HH:mm', {
      zone: 'UTC'
    }).toString();

  const renderDateTimeError = useCallback((): React.ReactNode => {
    const isMissingDateTime = meeting.errorMessages?.includes('missing_date_time');
    const isMissingDate = meeting.errorMessages?.includes('missing_date');
    const isMissingTimeStart = meeting.errorMessages?.includes('missing_time_start');
    const isMissingTimeEnd = meeting.errorMessages?.includes('missing_time_end');

    if (!showRequiredErrors) return;

    // missing everything
    if (isMissingDateTime && isMissingDate && isMissingTimeStart && isMissingTimeEnd) {
      return (
        <ErrorMessage
          sx={{ width: '100%', my: 1 }}
          error={t(keyPrefix + '.MeetingForm.errorNoDateAndTime')}
        />
      );
      //missing date only
    } else if (isMissingDateTime && isMissingDate && !isMissingTimeStart && !isMissingTimeEnd) {
      return <ErrorMessage sx={{ width: '100%', my: 1 }} error={t(keyPrefix + '.MeetingForm.errorNoDate')} />;
      //missing date and end time
    } else if (isMissingDateTime && isMissingDate && !isMissingTimeStart && isMissingTimeEnd) {
      return (
        <ErrorMessage
          sx={{ width: '100%', my: 1 }}
          error={t(keyPrefix + '.MeetingForm.errorNoDateAndTimeEnd')}
        />
      );
      //missing only time
    } else if (isMissingDateTime && !isMissingDate && isMissingTimeStart && isMissingTimeEnd) {
      return <ErrorMessage sx={{ width: '100%', my: 1 }} error={t(keyPrefix + '.MeetingForm.errorNoTime')} />;

      //missing end time only
    } else if (!isMissingDateTime && !isMissingDate && !isMissingTimeStart && isMissingTimeEnd) {
      return (
        <ErrorMessage sx={{ width: '100%', my: 1 }} error={t(keyPrefix + '.MeetingForm.errorNoTimeEnd')} />
      );
    }
  }, [meeting.errorMessages, showRequiredErrors]);

  const renderTime = useCallback((): React.ReactNode => {
    if (meeting.startTime && meeting.endTime) {
      return (
        <Typography sx={{ display: 'inline' }} component="span" color="text.primary">
          <TimeStartEnd
            isoStringStart={meeting.startDateTime || startTimeISOString}
            isoStringEnd={meeting.endDateTime || endTimeISOString}
          />
        </Typography>
      );
    }

    if (meeting?.startDateTime || startTimeISOString) {
      return (
        <Typography sx={{ display: 'inline' }} component="span" color="text.primary">
          {formatTime({
            isoString: meeting.startDateTime || startTimeISOString || '',
            locale
          })}
        </Typography>
      );
    }
  }, [JSON.stringify(meeting)]);

  const EditMeetingLink = ({ label }: { label: string }): React.ReactElement | null => {
    return (
      <Link
        sx={{
          display: 'inline',
          cursor: 'pointer',
          color: 'secondary.light',
          textTransform: 'none',
          fontSize: '1rem',
          fontWeight: '400',
          textDecoration: 'none'
        }}
        onClick={handleEditMeetingView(true)}
      >
        {label}
      </Link>
    );
  };

  return (
    <Paper sx={{ p: { md: 3, xs: 2 } }} variant="outlined" data-cy="meeting-card-editable">
      {!meeting.minutesStartedAt && (
        <Box>
          <VisibleByAccordion
            title={t(keyPrefix + '.titleNotice')}
            chip={
              meeting.invitationSentAt
                ? {
                    color: 'success',
                    label: t(keyPrefix + '.chipNoticeSent')
                  }
                : {
                    color: 'default',
                    label: t(keyPrefix + '.chipNoticeInPreparation')
                  }
            }
            usersVisibleByIds={meeting.meetingCreatorIds}
            visibleByState={meeting.invitationSentAt ? 'all' : 'some'}
            prefixVisibleToSome={t(keyPrefix + '.prefixNoticeVisibleToSome')}
            prefixVisibleToAll={t(keyPrefix + '.prefixNoticeVisibleToAll')}
            isVisibleByAllMessageHidden={!isUserMeetingCreator}
            icon={<MailOutlineIcon />}
          />
          <Divider sx={{ my: 3 }} />
        </Box>
      )}
      {isMeetingEditable ? (
        <MeetingCreateForm
          defaultValues={{
            committeeId: meeting.committeeId,
            title: meeting.title,
            location: meeting.location,
            startDate: meeting?.startDateTime
              ? DateTime.fromISO(meeting?.startDateTime).toFormat('yyyy-MM-dd')
              : meeting?.startDate,
            startTime: meeting.startDateTime
              ? DateTime.fromISO(meeting?.startDateTime).toFormat('HH:mm')
              : meeting.startTime
              ? DateTime.fromISO(
                  DateTime.fromFormat(meeting.startTime, 'HH:mm', { zone: 'UTC' }).toString()
                ).toFormat('HH:mm')
              : null,
            endTime: meeting.endDateTime
              ? DateTime.fromISO(meeting?.endDateTime).toFormat('HH:mm')
              : meeting.endTime
              ? DateTime.fromISO(
                  DateTime.fromFormat(meeting.endTime, 'HH:mm', { zone: 'UTC' }).toString()
                ).toFormat('HH:mm')
              : null
          }}
          onSubmit={onSubmitMeeting}
          defaultParticipants={defaultParticipants as { id: string; label: string }[]}
          errorMessage={errorMessage}
          meetingState="edit"
          showRequiredErrors={showRequiredErrors}
          meeting={meeting}
        />
      ) : (
        <Box sx={{ display: { sm: 'flex' } }}>
          <List sx={{ width: '100%', p: 0 }}>
            <ListItem alignItems="flex-start" sx={{ px: 0 }}>
              <ListItemText
                primary={t('common:title')}
                primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                secondary={
                  <Box
                    sx={{
                      display: { xs: 'flex', sm: 'block' },
                      justifyContent: { xs: 'space-between' },
                      alignItems: { xs: 'center' }
                    }}
                  >
                    <Typography
                      sx={{
                        display: 'inline',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        minWidth: '0px'
                      }}
                      component="span"
                      variant="h6"
                      color="text.primary"
                    >
                      {meeting.title}
                    </Typography>

                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                      <Tooltip title={t('common:labelEdit')}>
                        <IconButton
                          sx={{ alignSelf: 'flex-start', ml: 2 }}
                          onClick={handleEditMeetingView(true)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                }
                sx={{ px: 0 }}
              />
            </ListItem>
            <ListItem alignItems="flex-start" sx={{ px: 0 }}>
              <ListItemText
                primary={t('common:type')}
                primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                sx={{
                  overflow: 'hidden'
                }}
                secondary={
                  <>
                    <Typography
                      sx={{
                        display: 'inline-block',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                      }}
                      component="span"
                      color="text.primary"
                    >
                      <>{meetingType}</>
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <ListItem sx={{ flexDirection: 'column', px: 0 }}>
              {renderDateTimeError()}

              <Stack direction="row" sx={{ width: '100%' }}>
                <ListItemText
                  primary={t('common:date')}
                  primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                  secondary={
                    <>
                      {meeting?.startDateTime || meeting.startDate ? (
                        <Typography sx={{ display: 'inline' }} component="span" color="text.primary">
                          <>
                            {formatDate({
                              isoString: meeting.startDateTime
                                ? meeting.startDateTime
                                : DateTime.fromFormat(`${meeting?.startDate}`, 'yyyy-MM-dd').toString(),
                              locale,
                              format: 'DATE_FULL'
                            })}
                          </>
                        </Typography>
                      ) : (
                        <EditMeetingLink label={t(keyPrefix + '.selectDate')} />
                      )}
                    </>
                  }
                />
                <ListItemText
                  primary={t('common:time')}
                  primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                  secondary={
                    <>
                      {meeting.startTime && meeting.endTime ? (
                        renderTime()
                      ) : !meeting.startTime && !meeting.endTime ? (
                        <EditMeetingLink label={t(keyPrefix + '.selectTime')} />
                      ) : meeting.startTime && !meeting.endTime ? (
                        <Box sx={{ display: 'flex' }}>
                          <Typography sx={{ display: 'inline' }} component="span" color="text.primary">
                            {formatTime({
                              isoString: meeting.startDateTime || startTimeISOString || '',
                              locale
                            })}
                          </Typography>
                          <Typography sx={{ mx: 2 }}>-</Typography>
                          <EditMeetingLink label={t(keyPrefix + '.selectTimeEnd')} />
                        </Box>
                      ) : null}
                    </>
                  }
                />
              </Stack>
            </ListItem>
            <ListItem alignItems="flex-start" sx={{ flexDirection: 'column', px: 0 }}>
              {showRequiredErrors && meeting.errorMessages?.includes('missing_location_type') && (
                <ErrorMessage
                  sx={{ width: '100%', my: 1 }}
                  error={t(keyPrefix + '.MeetingForm.errorNoLocationType')}
                />
              )}
              <ListItemText
                primary={t('common:location')}
                primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                secondary={
                  <>
                    {meeting.location?.type ? (
                      <Typography sx={{ display: 'inline' }} component="span" color="text.primary">
                        {t(`common:locationType.${meeting.location?.type}`)}
                      </Typography>
                    ) : (
                      <EditMeetingLink label={t(keyPrefix + '.selectLocation')} />
                    )}

                    {showMeetingUrlField && meeting.location?.url && (
                      <Typography>
                        <Box sx={{ mt: 0.5 }}>
                          <Link
                            href={meeting.location?.url}
                            sx={{ display: 'flex', alignItems: 'center' }}
                            target="_blank"
                            rel="noopener"
                            color="secondary.light"
                          >
                            <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />
                            {meeting.location?.url}
                          </Link>
                        </Box>
                      </Typography>
                    )}
                    {showMeetingAddressField && meeting.location?.address && (
                      <Typography sx={{ display: 'inline' }} component="span" color="text.primary">
                        <Box sx={{ whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'start', mt: 0.5 }}>
                          <LocationOnIcon fontSize="small" sx={{ mt: '2px', mr: 0.5, opacity: '0.6' }} />
                          <Box>{meeting.location?.address}</Box>
                        </Box>
                      </Typography>
                    )}
                  </>
                }
              />
            </ListItem>
            {showMeetingUrlField && !meeting.location?.url && (
              <>
                <ListItem sx={{ px: 0 }}>
                  <ListItemText
                    primary={t(keyPrefix + '.meetingLink')}
                    primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                    secondary={<EditMeetingLink label={t(keyPrefix + '.addLink')} />}
                  />
                </ListItem>
              </>
            )}

            {showMeetingAddressField && !meeting.location?.address && (
              <>
                <ListItem alignItems="flex-start" sx={{ flexDirection: 'column', px: 0 }}>
                  {showRequiredErrors && meeting.errorMessages?.includes('missing_location_address') && (
                    <ErrorMessage
                      sx={{ width: '100%', my: 1 }}
                      error={t(keyPrefix + '.MeetingForm.errorNoLocationAddress')}
                    />
                  )}
                  <ListItemText
                    primary={t('common:address')}
                    primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
                    secondary={<>{<EditMeetingLink label={t(keyPrefix + '.addAddress')} />}</>}
                  />
                </ListItem>
              </>
            )}

            <ListItem alignItems="flex-start" sx={{ flexDirection: 'column', px: 0 }}>
              {showRequiredErrors && meeting.errorMessages?.includes('missing_participants') && (
                <ErrorMessage
                  sx={{ width: '100%', my: 1 }}
                  error={t(keyPrefix + '.MeetingForm.errorNoParticipants')}
                />
              )}
              <ListItemText
                primary={t('common:participants')}
                primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
              />
              {meeting.participants && meeting.participants?.length > 0 ? (
                <ParticipantsList participants={meeting.participants} />
              ) : (
                <EditMeetingLink label={t(keyPrefix + '.selectParticipants')} />
              )}
            </ListItem>
          </List>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Tooltip title={t('common:labelEdit')}>
              <IconButton
                sx={{ alignSelf: 'flex-start', ml: 2 }}
                onClick={handleEditMeetingView(true)}
                data-cy="meeting-prepare_edit-meeting-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

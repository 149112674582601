import React, { useCallback } from 'react';
import { Dialog } from '@koopajs/mui';
import { useResourceCreate, useLocale, useComponentVisibility } from '@koopajs/react';
import { Button, Box, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { FormControllerTemp } from 'components/temp/FormControllerTemp';
import { Form, ErrorMessage } from '@koopajs/mui';
import { useResourceList, useUserShow } from '@koopajs/react';
import { ICommittee, ICommitteeMember } from 'types';
import { DateTime } from 'luxon';
import { IUserPublicProfile } from '@koopajs/app';
import { TextField } from 'components/temp/TextFieldTemp';
import { Trans } from 'react-i18next';
import ResourcePicker from 'components/ResourcePicker';
import { CopyResolutionField } from 'components/WrittenResolution/CopyResolutionField.tsx';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

interface ICreateMeetingDialogProps {
  isOpen: boolean;
  onClose: (() => Promise<void>) | (() => void);
}

export const CreateWrittenResolutionDialog: React.FC<ICreateMeetingDialogProps> = (props) => {
  const { isOpen, onClose } = props;

  const { t } = useLocale();
  const navMenu = useComponentVisibility('navMenu');

  const keyPrefix = 'Dialogs.CreateWrittenResolution';

  const history = useHistory();
  const { createResource, errorMessage } = useResourceCreate({ path: '/resolutions' });

  const { resources: users } = useResourceList<IUserPublicProfile>({ path: '/users' });

  const handleSubmit = useCallback(async (formData: object): Promise<boolean> => {
    const formDataTyped = formData as {
      templateType: 'none' | 'another-resolution';
      resolutionTemplateId: string | undefined;
      notes: string | undefined;
    };

    if (formDataTyped.templateType === 'none') {
      formDataTyped.resolutionTemplateId = undefined;
    } else if (formDataTyped.templateType === 'another-resolution') {
      formDataTyped.notes = undefined;
    }

    let resolutionId = '';
    const res = await createResource(formDataTyped, (id) => {
      resolutionId = id;
    });

    if (res) {
      navMenu.setHidden();
      await onClose();
      history.push(`/resolutions/${resolutionId}`);
    }

    return res;
  }, []);

  const today = DateTime.now().toISODate();
  const { user } = useUserShow();
  const { resources: committees } = useResourceList<ICommittee>({
    path: '/committees'
  });

  const { resources: currentUserCommitteeMembersActiveMandateCreatorRole } =
    useResourceList<ICommitteeMember>({
      path: '/committee-members',
      searchParams: {
        filters: [
          `userId:"${user?.id}"`,
          // getting both then filtering due to redux conflict issues with create button
          '(roles.role:"createMeetings" OR roles.role:"createResolutionProjects")',
          `startAt:[* TO ${today}]`,
          `(endAt:[${today} TO *] OR (NOT _exists_:endAt))`
        ]
      }
    });

  const committeeIdsUserCanCreateResolutionsIn = currentUserCommitteeMembersActiveMandateCreatorRole
    .filter((cm) => {
      return cm.roles?.some((role) => role.role === 'createResolutionProjects');
    })
    .map((member) => member.committeeId);

  const filteredCommittees = committees.filter(
    (c) => c.isTakingResolutions && committeeIdsUserCanCreateResolutionsIn.includes(c.id)
  );

  const defaultCommitteeId = filteredCommittees.length === 1 ? filteredCommittees[0].id : undefined;

  return (
    <Dialog.View
      isCloseVisible={false}
      isOpen={isOpen}
      onClose={onClose}
      i18n={{ keyPrefix: keyPrefix + '.DialogView' }}
      maxWidth="md"
      forcedFullScreen={false}
      dialogProps={{ className: 'rr-block' }}
    >
      <FormControllerTemp
        onSubmit={handleSubmit}
        onSubmitSuccessResetType="FORM_PAYLOAD"
        testId="resolution-create-form"
        defaultValues={{ templateType: 'none', committeeId: defaultCommitteeId }}
      >
        <ErrorMessage error={errorMessage} />

        <TextField
          name="title"
          validationRules={{ maxLength: 150 }}
          i18n={{ keyPrefix: keyPrefix + '.Form.FieldTitle' }}
        />

        <Form.Select
          name="committeeId"
          i18n={{ keyPrefix: keyPrefix + '.Form.FieldCommitteeType' }}
          options={filteredCommittees
            ?.sort((committeeA, committeeB) => {
              return committeeA.name > committeeB.name ? 1 : -1;
            })
            .map((committee) => ({
              id: committee.id,
              label: committee.name
            }))}
        />

        <Typography variant="caption" sx={{ display: 'block', mb: 1, mt: 1.5 }}>
          {t('common:signatories')}
        </Typography>

        <Box sx={{ backgroundColor: '#FFEED8', p: 0.5, width: 'fit-content' }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <LightbulbIcon fontSize="small" sx={{ color: '#F8981C' }} />
            <Box data-cy="create-written-resolution-dialog_unanimous-alert">
              <Trans i18nKey={keyPrefix + '.Form.signatoriesAlert-html'} t={t} />
            </Box>
          </Stack>
        </Box>

        <Box sx={{ mt: 1.5, mb: 2 }} data-cy="create-written-resolution-dialog_signatories-field">
          <ResourcePicker
            i18n={{ keyPrefix: keyPrefix + '.Form.FormResourcePicker' }}
            name="recipientIds"
            isOptional
            defaultValue={undefined}
            resources={users.map((user) => ({ id: user?.id, label: user?.username }))}
            isOpenOnFocus
            isDisabledCloseOnSelect
            isMultiple
            translatedLabel=""
            translatedLabelMultipleSelected=""
            translatedErrorText=""
            sx={{ mt: 1 }}
          />
        </Box>

        <CopyResolutionField />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            backgroundColor: '#fff',
            px: 3,
            pb: 2,
            pt: 1,
            right: 0,
            left: 0,
            zIndex: 100
          }}
        >
          <Button onClick={onClose}>{t('common:labelCancel')}</Button>
          <Form.ButtonSubmit i18n={{ keyPrefix: keyPrefix + '.Form.ButtonSubmit' }} />
        </Box>
      </FormControllerTemp>
      <Box sx={{ p: 2 }} />
    </Dialog.View>
  );
};

import { useLocale, useSearchParams } from '@koopajs/react';
import { Typography, Box, List, ListItem, ListItemButton, ListItemText, Divider } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

interface ISortByProps {
  sortOptions: { key: string; direction: 'asc' | 'desc'; label: string }[];
}

export const SortBySidebar: React.FC<ISortByProps> = (props) => {
  const { sortOptions } = props;
  const search = useSearchParams();

  const { t } = useLocale();
  const keyPrefix = 'Sidebars.SortBy';

  const [sortedBy, setSortedBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    search.setSearchParams({ sort: sortedBy ? `${sortedBy}:${sortDirection}` : undefined });
  }, [sortedBy, sortDirection]);

  const handleSortChange = useCallback(
    (key: string, direction: 'asc' | 'desc') => {
      if (sortedBy === key && direction === sortDirection) {
        // setSortDirection(undefined);
        setSortedBy(undefined);
      } else {
        setSortDirection(direction);
        setSortedBy(key);
      }
    },
    [sortedBy, sortDirection]
  );

  const handleClick = (key: string, direction?: 'asc' | 'desc'): (() => void) => {
    return () => {
      handleSortChange(key, direction || 'asc');
    };
  };

  return (
    <>
      <Box>
        <Box>
          <Box>
            <Box sx={{ my: '12px' }}>
              <Typography variant="button">{t(keyPrefix + '.title')}</Typography>
            </Box>
          </Box>
          <List sx={{ my: -0.5 }}>
            {sortOptions.map((option) => {
              const { key, direction, label } = option;

              const selected = key === sortedBy && direction === sortDirection;

              return (
                <ListItem key={key + direction} sx={{ display: 'block', py: 0.5 }} disablePadding>
                  <ListItemButton onClick={handleClick(key, direction)} selected={selected} sx={{ p: 0 }}>
                    <ListItemText primary={label} primaryTypographyProps={{ variant: 'body2' }} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </>
  );
};

import { useFacet, useLocale } from '@koopajs/react';
import { Box, Button, Menu, Divider, TextField, Typography } from '@mui/material';
import { useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import React, { useCallback, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { dateFilters } from 'utils/dateRangeFilters';
import { useSearchParams } from '@koopajs/react';
import { DateTime } from 'luxon';

export interface IDateRangeFacetProps {
  path: string;
  facetKey: string;
  testId?: string;
  label?: string;
}

export const DateRangeFacet: React.FC<IDateRangeFacetProps> = (props) => {
  const { path, facetKey = '$createdAt', testId, label } = props;

  const { t } = useLocale();
  const [dateRange, setDateRange] = React.useState<{ to: string; from: string }>({ from: '', to: '' });

  const facet = useFacet({ facetKey, path, isSingleValue: true });
  const { addFilter, searchParams } = useSearchParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const menuOpen = Boolean(anchorEl);

  const handleClickSelectGroup = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelectOption = useCallback(
    (itemId: string) => {
      return () => {
        addFilter(`${facetKey}:[${itemId}]`, { uniqueForKey: true });

        handleCloseMenu();
      };
    },
    [facet, handleCloseMenu]
  );

  const [buttonWidth, setButtonWidth] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const button = buttonRef.current;
    if (button) {
      const resizeObserver = new ResizeObserver(() => {
        setButtonWidth(button.offsetWidth);
      });
      resizeObserver.observe(button);
      return () => resizeObserver.disconnect();
    }
  }, []);

  const handleApplyCustomRangeFilter = useCallback(() => {
    const today = DateTime.now().endOf('day').toFormat('yyyy-MM-dd');

    // if no end date is selected, set it to today
    if (!dateRange.to) {
      setDateRange((preState) => ({ ...preState, to: today }));
    }

    if (dateRange.from) {
      const filter =
        DateTime.fromISO(dateRange.from as string).toMillis() +
        ' TO ' +
        DateTime.fromISO(dateRange.to || today)
          .endOf('day')
          .toMillis();

      handleSelectOption(filter)();
    }
  }, [JSON.stringify(dateRange)]);

  useEffect(() => {
    const foundDateFilter = searchParams.filters?.some((filter) => filter.startsWith(facetKey));
    if (!foundDateFilter) {
      setDateRange({ from: '', to: '' });
    }
  }, [searchParams.filters]);

  const onDateFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const { name, value } = e.target;
      setDateRange((previousDateRange) => ({ ...previousDateRange, [name]: value }));
    },
    []
  );

  const renderDateField = ({
    name,
    label,
    value,
    marginTop
  }: {
    name: string;
    label: string;
    value: string;
    marginTop?: boolean;
  }): JSX.Element => {
    return (
      <TextField
        type="date"
        name={name}
        label={label}
        value={value}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          inputProps: { max: DateTime.now().toFormat('yyyy-MM-dd'), sx: { typography: 'body2' } }
        }}
        onChange={onDateFieldChange}
        sx={{ width: '180px', marginTop: marginTop ? '1rem' : 0, typography: 'body2' }}
      />
    );
  };

  if (!facet.values || facet?.values?.length === 0) {
    return null;
  }
  return (
    <>
      <Button
        aria-controls={menuOpen ? 'date-range-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        variant="text"
        onClick={handleClickSelectGroup}
        data-cy={testId}
        ref={buttonRef}
        sx={{
          color: 'inherit',
          typography: 'body2',
          textTransform: 'none'
        }}
        id="date-range-button"
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2">{label || t('Components.Facets.dateRange')}</Typography>
          <KeyboardArrowDownIcon fontSize="small" />
        </Box>
      </Button>

      {facet.values && (
        <Menu
          id="date-range-menu"
          MenuListProps={{
            'aria-labelledby': 'date-range-button'
          }}
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleCloseMenu}
          PaperProps={{ style: { minWidth: `${buttonWidth}px` }, elevation: 0, variant: 'outlined' }}
        >
          {dateFilters?.map((dateFilter) => {
            const selectedValue = facet.selectedValue ? /\[(.*?)\]/.exec(facet.selectedValue) : '';
            return (
              <MenuItem
                key={dateFilter.key}
                sx={{
                  backgroundColor:
                    selectedValue && selectedValue[1] === dateFilter.generateFilter() ? '#edf0ff' : 'inherit',
                  typography: 'body2'
                }}
                onClick={handleSelectOption(dateFilter.generateFilter())}
              >
                {t(`common:dateRange.${dateFilter.key}`) || dateFilter.label}
              </MenuItem>
            );
          })}
          <Divider />

          <MenuItem
            disableRipple
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 2,
              typography: 'body2',
              '&:hover': {
                backgroundColor: 'transparent',
                cursor: 'default'
              }
            }}
          >
            {t('common:dateRange.customRange')}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {renderDateField({
                name: 'from',
                label: t('common:labelFrom'),
                value: dateRange.from
              })}
              {renderDateField({
                name: 'to',
                label: t('common:labelTo'),
                value: dateRange.to,
                marginTop: true
              })}
              <Button sx={{ alignSelf: 'flex-end', mt: 1 }} onClick={handleApplyCustomRangeFilter}>
                {t('Components.Facets.DateRange.labelApply')}
              </Button>
            </Box>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

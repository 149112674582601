import { ITopicResolutionOutcome } from 'types';
import { Box, Paper, Stack, Typography, Button } from '@mui/material';
import { useComponentVisibility } from '@koopajs/react';
import { useCallback, useMemo } from 'react';
import { Edit as EditIcon } from '@mui/icons-material';
import { processUsersForInput } from 'utils/processUsersForInput';
import { ResolutionOutcomeText } from 'components/ResolutionOutcome/ResolutionOutcomeText';
import { ResolutionOutcomeVotes } from 'components/ResolutionOutcome/ResolutionOutcomeVotes';
import { IUseLiveMeetingResponse } from '../../useLiveMeeting';
import { ResolutionOutcomeIcon } from 'components/ResolutionOutcome/ResolutionOutcomeIcon';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { sxHtmlButtonReset } from 'utils/muiStyles/htmlButtonReset';
interface IRecordVoteBannerProps {
  liveMeeting: IUseLiveMeetingResponse;
  sx?: SxProps<Theme>;
}

export const RecordVoteBanner: React.FC<IRecordVoteBannerProps> = (props) => {
  const { sx, liveMeeting } = props;
  const { topic, t, users } = liveMeeting;

  type UserArrayForInput = { label: string; id: string }[];
  let dialogDefaultValues: {
    title?: string;
    notes?: string;
    resolutionOutcome?: ITopicResolutionOutcome<UserArrayForInput>;
  } = {};
  // formatting for resource picker default values
  const membersVotes = useMemo(() => {
    const returnObj: {
      membersFor?: UserArrayForInput;
      membersAbstained?: UserArrayForInput;
      membersAgainst?: UserArrayForInput;
    } = {};
    if (topic?.resolutionOutcome?.membersFor?.length)
      returnObj.membersFor = processUsersForInput({
        users,
        selectedUserIds: topic?.resolutionOutcome?.membersFor
      });
    if (topic?.resolutionOutcome?.membersAbstained?.length)
      returnObj.membersAbstained = processUsersForInput({
        users,
        selectedUserIds: topic?.resolutionOutcome?.membersAbstained
      });
    if (topic?.resolutionOutcome?.membersAgainst?.length)
      returnObj.membersAgainst = processUsersForInput({
        users,
        selectedUserIds: topic?.resolutionOutcome?.membersAgainst
      });
    return returnObj;
  }, [users.length, JSON.stringify(topic?.resolutionOutcome)]);

  dialogDefaultValues = {
    resolutionOutcome: {
      isUnanimous: topic?.resolutionOutcome?.isUnanimous,
      outcome: topic?.resolutionOutcome?.outcome || 'approved',
      mover: topic?.resolutionOutcome?.mover,
      seconder: topic?.resolutionOutcome?.seconder,
      ...membersVotes
    }
  };

  const showDialog = useComponentVisibility('recordVote');
  const handleShowDialog = useCallback(() => {
    showDialog.setVisibleWithContext({
      liveMeeting: props.liveMeeting,
      defaultValues: dialogDefaultValues
    });
  }, [JSON.stringify(props.liveMeeting)]);

  const outcome = topic?.resolutionOutcome?.outcome;

  const { membersAgainst, membersAbstained, membersFor } = topic?.resolutionOutcome || {};
  const showResolutionOutcomeVotes = membersFor?.length || membersAbstained?.length || membersAgainst?.length;

  return (
    <Button
      data-cy="record-vote-banner"
      sx={{
        ...sxHtmlButtonReset,
        display: 'block',
        width: '100%',
        ...sx
      }}
      onClick={handleShowDialog}
    >
      <Paper variant="outlined" sx={{ p: '14px 24px' }}>
        {topic?.isResolution ? (
          <>
            <Typography variant="caption" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
              {t('common:resolution_one')}
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex' }}>
                <ResolutionOutcomeIcon voteOutcome={outcome} />
                <ResolutionOutcomeText topic={topic} />
              </Box>
              <EditIcon sx={{ opacity: 0.54 }} />
            </Box>
          </>
        ) : (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box sx={{ display: 'flex' }}>
              <ResolutionOutcomeIcon voteOutcome={outcome} />
              <ResolutionOutcomeText topic={topic} />
            </Box>
            <EditIcon sx={{ opacity: 0.54 }} />
          </Stack>
        )}
        {showResolutionOutcomeVotes && (
          <Box sx={{ mt: 2 }}>
            <ResolutionOutcomeVotes topic={topic} />
          </Box>
        )}
      </Paper>
    </Button>
  );
};

import { useLocale } from '@koopajs/react';
import { IResolution } from 'types';
import React from 'react';
import { VisibleByAccordion } from 'components/VisibleByAccordion';

interface IResolutionVisibleByProps {
  resolution: IResolution;
  isUserWrittenResolutionCreatorRole: boolean;
}

export const ResolutionVisibleByAccordion: React.FC<IResolutionVisibleByProps> = (props) => {
  const { resolution, isUserWrittenResolutionCreatorRole } = props;

  const { t } = useLocale();
  const keyPrefix = 'WrittenResolution';

  const activeCommitteeMembersWithCreateResolutionRole = resolution?.activeCommitteeMembers?.filter((acm) => {
    return acm.roles?.some((role) => role.role === 'createResolutionProjects');
  });

  const usersVisibleByIds = activeCommitteeMembersWithCreateResolutionRole?.map((acm) => acm.userId);

  const isDraft = resolution.state === 'draft';
  const isAttached = resolution.state === 'attached';
  const isSealed = resolution.state === 'sealed';
  const isDeclined = isSealed && resolution.resolutionOutcome?.outcome === 'declined';

  const generateChipProperties = (): { label: string; color?: 'success' | 'warning' | 'default' } => {
    if (isDraft) {
      return { label: t(keyPrefix + '.accordionChipToReviewLabel') };
    } else {
      let label = undefined;

      if (isAttached) {
        label = t(keyPrefix + '.accordionChipToSignLabel');
      } else if (isDeclined) {
        label = t(keyPrefix + '.accordionChipDeclinedLabel');
      } else {
        label = t(keyPrefix + '.accordionChipAdoptedLabel');
      }

      return {
        color: isSealed && !isDeclined ? 'success' : isDeclined ? 'warning' : 'default',
        label
      };
    }
  };

  return (
    <VisibleByAccordion
      title={t('common:writtenResolution')}
      usersVisibleByIds={usersVisibleByIds}
      visibleByState={isDraft ? 'some' : 'all'}
      chip={generateChipProperties()}
      prefixVisibleToSome={t(keyPrefix + '.prefixVisibleToSome')}
      prefixVisibleToAll={t(keyPrefix + '.prefixVisibleToAll')}
      isVisibleByAllMessageHidden={!isUserWrittenResolutionCreatorRole}
    />
  );
};

import { AllTopicType, checkIsGenericTopicType } from 'utils/topicTypeArrays';
import { TopicChip } from './TopicChip';

interface ITopicChipConditionsProps {
  topicType: AllTopicType;
  variant?: 'filled' | 'outlined';
  color?: 'default' | 'primary' | 'secondary' | 'error';
}

export const TopicChipConditions: React.FC<ITopicChipConditionsProps> = (props) => {
  const { topicType, variant, color } = props;
  if (checkIsGenericTopicType(topicType)) {
    return <TopicChip topicType={topicType} variant={variant} color={color} />;
  } else if (topicType === 'ceoReport') {
    return <TopicChip topicType="information" variant={variant} color={color} />;
  } else if (['nominations', 'agendaApproval', 'pastMinutesApproval', 'adjournment'].includes(topicType)) {
    return <TopicChip topicType="resolution" variant={variant} color={color} />;
  } else {
    return null;
  }
};

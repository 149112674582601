import React, { useEffect, useState, useCallback } from 'react';
import { Api } from '@koopajs/app';
import { Box, Card, Stack, Button, Typography } from '@mui/material';
import star from './invitation.png';
import { ICoreProps } from '@koopajs/mui';
import { useLocale as useAppLocale } from '@koopajs/react';
import { useLocale } from '@koopajs/mui/dist/useCoreLocale';
import { Loading } from '@koopajs/mui';
import { useRouteMatch } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export interface IInvitationPageProps extends ICoreProps {}

export const InvitationPageTemp: React.FC<IInvitationPageProps> = (props) => {
  const { sx, i18n } = props;

  const coreKeyPrefix = 'Invitation';
  const keyPrefix = i18n?.keyPrefix || coreKeyPrefix;

  const { t } = useLocale({ ...i18n, coreKeyPrefix: 'Invitation' });
  const { t: tApp } = useAppLocale();

  const match = useRouteMatch('/invitation/:token');
  const urlParams = match?.params as { token?: string };
  const token = urlParams?.token;

  const [workspaceName, setWorkspaceName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const loadInvitation = async (): Promise<void> => {
      const { data } = await Api.client.get(`/invitations/${token}`);
      setWorkspaceName(data.invitation.workspaceName);
    };

    loadInvitation().catch((e) => {
      setErrorMessage(e?.toJSON()?.message);
      console.error(e);
    });
  }, [token]);

  const handleAccept = useCallback(async () => {
    if (token) {
      await Api.client.post(`/invitations/${token}/accepts`);
      Api.setActiveWorkspaceId(token.split('.')[0]);
      location.replace('/');
    }
  }, []);

  const handleDecline = useCallback(async () => {
    await Api.client.post(`/invitations/${token}/rejects`);
    location.replace('/');
  }, []);

  if (errorMessage) {
    return (
      <>
        <Box
          sx={{
            textAlign: 'center',
            height: '100%',
            display: 'flex',
            // alignItems: 'center',
            marginTop: '80px',
            justifyContent: 'center'
          }}
        >
          <Box>
            <ErrorOutlineIcon fontSize="large" />
            <Typography variant="h5" gutterBottom>
              {tApp('Components.InvitationTemp.errorTitle')}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography sx={{ mr: 1 }}>{tApp('Components.InvitationTemp.errorDescription')}</Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  } else if (!workspaceName) {
    return <Loading i18n={{ keyPrefix: `${keyPrefix}.Loading` }} sx={{ backgroundColor: 'transparent' }} />;
  }

  return (
    <Box
      sx={{ width: '100%', mt: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', ...sx }}
    >
      <Card sx={{ py: '20px', px: '30px', textAlign: 'center' }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
          <Box sx={{ p: 1 }}>
            <img src={star} alt="" height="100" />
          </Box>

          <Box>
            <Typography variant="h4" sx={{ fontSize: { xs: '1.65rem', sm: '2.125rem' } }}>
              {t('title')}
              <Typography variant="inherit" color="secondary">
                {workspaceName}
              </Typography>
            </Typography>

            <Typography
              style={{
                maxWidth: 320,
                textAlign: 'center',
                margin: '0 auto',
                paddingTop: '10px',
                paddingBottom: '10px'
              }}
            >
              {t('invitationText')}
            </Typography>

            <Box sx={{ mt: 2 }}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="center"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Button variant="contained" disableElevation onClick={handleAccept}>
                  {t('accept')}
                </Button>
                <Button onClick={handleDecline}>{t('decline')}</Button>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};

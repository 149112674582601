import { useComponentVisibility, useLocale, useSearchParams, useParamsKey } from '@koopajs/react';
import { Typography, Box, Stack } from '@mui/material';
import { IMeeting, IApprovedTopic } from 'types';
import { Loading } from '@koopajs/mui';
import { CreateButton } from 'components/CreateButton';
import { FilterChips } from '../components/FilterChips';
import { MobileFloatingButtons } from 'components/MobileFloatingButtons';
import { InCameraList } from 'components/InCamera/InCameraList';
import { useCallback, useEffect } from 'react';
import { ViewInCamera } from '../components/Dialogs/ViewInCamera';
import { Helmet } from 'react-helmet';
import { EmptyState } from 'components/EmptyState';
import { NoResultsResetFilters } from 'components/NoResultsResetFilters';
import { PageContainer } from 'components/temp/PageContainer';
import { useResourceList } from 'components/temp/useResourceListTemp';
import { FacetListInCameraInline } from 'components/InCamera/FacetListInCameraInline';
import { FacetListInCameraSidebar } from 'components/Sidebars/InCamera/FacetListInCameraSidebar';
import { Sidebar } from 'components/Sidebars/Sidebar';

export const InCamera: React.FC = () => {
  const { t } = useLocale();
  const keyPrefix = 'InCamera';

  const search = useSearchParams();

  const { state: meetingsState } = useResourceList<IMeeting>({
    path: '/meetings',
    searchParams: {
      size: 5
    }
  });

  const inCameraId = useParamsKey('id');

  const sidebarVisibility = useComponentVisibility('sidebar');

  const handlePageChange = useCallback((page: number): void => {
    search.setSearchParams({ page });
  }, []);

  const handleSizeChange = useCallback((size: number): void => {
    search.setSearchParams({ size, page: undefined });
  }, []);

  useEffect(() => {
    search.setSearchParams({ page: undefined });
  }, [JSON.stringify(search?.searchParams?.filters)]);

  const {
    resources: topicsList,
    state: topicsState,
    isProcessing,
    totalCount
  } = useResourceList<IApprovedTopic>({
    path: '/in-camera',
    searchParams: { sort: 'meetingStartDateTime:DESC', ...search.searchParams }
  });

  const hasNoMeetings = meetingsState === 'loaded-empty' || meetingsState === 'loaded-no-search-result';

  if (meetingsState === 'loading' || meetingsState === 'reloading' || topicsState === 'loading')
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );

  if (hasNoMeetings && topicsState === 'loaded-empty')
    return <EmptyState button={<CreateButton otherButtonProps={{ disableElevation: true }} />} />;
  if (topicsState === 'loaded-empty')
    return (
      <EmptyState
        subTitle={t(keyPrefix + '.emptyStateSubTitleNoTopics')}
        description={t(keyPrefix + '.emptyStateDescriptionNoTopics')}
      />
    );

  return (
    <>
      <Helmet>
        <title>{`${t('common:navigation.inCamera')} - Panorama`}</title>
      </Helmet>
      <PageContainer sxChildren={{ px: { xs: '24px', lg: '48px' }, py: '24px' }} className="rr-block">
        <Sidebar facetList={<FacetListInCameraSidebar />} onHideSidebar={sidebarVisibility.setHidden} />
        <MobileFloatingButtons
          fabData={[
            {
              label: t('common:filters'),
              onClick: sidebarVisibility.setVisible
            }
          ]}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant="h1" sx={{ fontSize: '24px' }}>
            {t(keyPrefix + '.title')}
          </Typography>
          <FacetListInCameraInline />
        </Box>
        <Box sx={{ my: '24px' }}>
          <FilterChips isProcessing={isProcessing} />
        </Box>
        {(topicsState === 'loaded' || topicsState === 'reloading') && (
          <InCameraList
            inCameraList={topicsList}
            totalCount={totalCount}
            size={search.searchParams.size}
            page={search.searchParams.page}
            isReloading={topicsState === 'reloading'}
            onPageChange={handlePageChange}
            onSizeChange={handleSizeChange}
          />
        )}
        {topicsState === 'loaded-no-search-result' && <NoResultsResetFilters />}
        {/* Dialog */}
        <ViewInCamera inCameraId={inCameraId} />
      </PageContainer>
    </>
  );
};

import { Box, Tooltip, IconButton, CircularProgress, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useLocale } from '@koopajs/react';
import { useComponentVisibility } from '@koopajs/react';
import { useEffect } from 'react';
import { ICommittee } from 'types';
import { useDocumentInMemoryDropzone } from 'components/hooks/useDocumentInMemoryDropzone';
import { DateTime } from 'luxon';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface IAddDocumentRecordButtonProps {
  isIconButton?: boolean;
  committees: ICommittee[];
  sxContainer?: SxProps<Theme>;
  isDropTextVisible?: boolean;
  defaultValues?: { committeeId?: string; category?: string };
  isCommitteeFieldDisabled?: boolean;
  isCategoryFieldDisabled?: boolean;
}

export const AddDocumentRecordButton: React.FC<IAddDocumentRecordButtonProps> = (props) => {
  const {
    isIconButton,
    committees,
    sxContainer,
    isDropTextVisible,
    defaultValues,
    isCommitteeFieldDisabled,
    isCategoryFieldDisabled
  } = props;

  const { t } = useLocale();
  const keyPrefix = 'DocumentRecords.AddDocumentRecordButton';

  const addDocumentRecordDialog = useComponentVisibility('addDocumentRecordDialog');

  const { dropzone, isProcessing, blobUrl, file } = useDocumentInMemoryDropzone({
    isStoringFileInMemory: true
  });

  const newTitle = file?.name.replace(/\.[^/.]+$/, '');

  useEffect(() => {
    if (file) {
      addDocumentRecordDialog.setVisibleWithContext({
        blobUrl,
        file,
        committees,
        isCategoryFieldDisabled,
        isCommitteeFieldDisabled,
        defaultValues: {
          lastDocumentTitle: newTitle,
          lastDocumentActiveSince: DateTime.now().toFormat('yyyy-MM-dd'),
          reviewInterval: 'none',
          ...(committees?.length === 1 ? { committeeId: committees[0].id } : {}),
          ...(defaultValues ? defaultValues : {})
        }
      });
    }
  }, [blobUrl, file]);

  return (
    <Box
      {...dropzone.getRootProps()}
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...sxContainer }}
      data-cy="add-document-record-input_container"
    >
      <input {...dropzone.getInputProps()} data-cy="add-document-record-input" />
      {isDropTextVisible && (
        <Typography sx={{ mb: '14px', textAlign: 'center' }}>{t(keyPrefix + '.dropDocumentHere')}</Typography>
      )}
      {isIconButton ? (
        <Tooltip title={t('common:labelAddDocument')}>
          <IconButton onClick={dropzone.open} disabled={isProcessing}>
            {isProcessing ? <CircularProgress size={24} color="inherit" /> : <AttachFileIcon />}
          </IconButton>
        </Tooltip>
      ) : (
        <LoadingButton
          onClick={dropzone.open}
          startIcon={<AttachFileIcon />}
          loading={isProcessing}
          loadingPosition="start"
          variant="outlined"
        >
          <span>{t('common:labelAddDocument')}</span>
        </LoadingButton>
      )}
    </Box>
  );
};

import React, { useCallback, useEffect } from 'react';
import { useFacet, useLocale, useSearchParams } from '@koopajs/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  TextField,
  List,
  Divider
} from '@mui/material';
import { DateTime } from 'luxon';
import { dateFilters } from 'utils/dateRangeFilters';

export interface IDateRangeFacetProps {
  path: string;
  facetKey?: string;
  title?: string;
}

export const DateRangeFacetSidebar: React.FC<IDateRangeFacetProps> = (props) => {
  const { path, facetKey = '$createdAt', title } = props;

  const { t } = useLocale();
  const keyPrefix = 'Sidebars.Facets.DateRangeFacet';

  const [customRangeVisibility, setCustomRangeVisibility] = React.useState(false);
  const toggleRangeVisibility = useCallback(() => {
    setCustomRangeVisibility((prevState) => !prevState);
  }, []);

  const { addFilter, searchParams } = useSearchParams();

  const facet = useFacet({ facetKey, path, isSingleValue: true });

  const handleAddFilter = (filter: string): (() => void) => {
    return () => {
      addFilter(`${facetKey}:[${filter}]`, { uniqueForKey: true });
    };
  };

  const [dateRange, setDateRange] = React.useState<{ to: string; from: string }>({ from: '', to: '' });

  const onDateFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const { name, value } = e.target;
      setDateRange((previousDateRange) => ({ ...previousDateRange, [name]: value }));
    },
    []
  );

  useEffect(() => {
    const { to, from } = dateRange;

    if (from && to) {
      const filter =
        DateTime.fromISO(from as string).toMillis() +
        ' TO ' +
        DateTime.fromISO(to as string)
          .endOf('day')
          .toMillis();
      handleAddFilter(filter)();
    }
  }, [dateRange]);

  useEffect(() => {
    const foundDateFilter = searchParams.filters?.some((filter) => filter.startsWith(facetKey));
    if (!foundDateFilter) {
      setDateRange({ from: '', to: '' });
    }
  }, [searchParams.filters]);

  if (!facet.values || facet.values.length === 0) {
    return null;
  }

  const renderDateField = ({
    name,
    label,
    value,
    marginTop
  }: {
    name: string;
    label: string;
    value: string;
    marginTop?: boolean;
  }): JSX.Element => {
    return (
      <TextField
        type="date"
        name={name}
        label={label}
        value={value}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          inputProps: { max: DateTime.now().toFormat('yyyy-MM-dd') }
        }}
        onChange={onDateFieldChange}
        sx={{ width: '100%', marginTop: marginTop ? '1rem' : 0 }}
      />
    );
  };

  return (
    <>
      <Box sx={{ marginTop: { xs: 4, lg: 0 } }}>
        <Box>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              backgroundColor: 'transparent',
              '&:before': {
                display: 'block'
                // background:
                //   'linear-gradient(266deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 30%, rgba(224,224,224,1) 100%)'
              }
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0 }}>
              <Typography variant="button">{title || t(keyPrefix + '.title')}</Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ p: 0 }}>
              {facet.values && (
                <Box>
                  <List sx={{ my: -0.5 }}>
                    {dateFilters.map((dateFilter) => (
                      <ListItem disablePadding key={dateFilter.label} sx={{ py: 0.5 }}>
                        <ListItemButton onClick={handleAddFilter(dateFilter.generateFilter())} sx={{ p: 0 }}>
                          <ListItemText
                            primary={t(`common:dateRange.${dateFilter.key}`) || dateFilter.label}
                            primaryTypographyProps={{ variant: 'body2' }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                    <ListItem sx={{ display: 'block', py: 0.5 }} disablePadding>
                      <ListItemButton onClick={toggleRangeVisibility} sx={{ p: 0 }}>
                        <ListItemText
                          primary={t('common:dateRange.customRange')}
                          primaryTypographyProps={{ variant: 'body2' }}
                        />
                      </ListItemButton>
                      {customRangeVisibility && (
                        <Box sx={{ py: 2 }}>
                          <form>
                            {renderDateField({
                              name: 'from',
                              label: t('common:labelFrom'),
                              value: dateRange.from
                            })}
                            {renderDateField({
                              name: 'to',
                              label: t('common:labelTo'),
                              value: dateRange.to,
                              marginTop: true
                            })}
                          </form>
                        </Box>
                      )}
                    </ListItem>
                  </List>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>
    </>
  );
};

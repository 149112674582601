import { FormContext, useLocale } from '@koopajs/react';
import { ControlledCheckbox } from 'components/temp/ControlledCheckbox';
import { DateTimePicker } from 'components/temp/DateTimePickerTemp';
import { useContext, useEffect } from 'react';
import { committeeMemberRoles } from 'utils/committeeMembers/committeeMemberRoles';
import { committeeMembersTitlesArray } from 'utils/committeeMembers/committeeMemberTitleArray';
import { Box, Button, FormControl, FormLabel, FormGroup } from '@mui/material';
import { SelectTemp } from 'components/temp/SelectTemp';
import { UserChip } from '../../../UserChip';
import { isMeetingTimeDiffValid } from 'utils/isMeetingTimeDifferenceValid';
import { useScreenSize } from 'utils/useScreenSize';
import { visuallyHidden } from '@mui/utils';

interface IEditCommitteeMemberFieldsProps {
  userId: string;
}

export const EditCommitteeMemberFields: React.FC<IEditCommitteeMemberFieldsProps> = (props) => {
  const { userId } = props;
  const { t } = useLocale();
  const keyPrefix = 'OrganizationCommittee.CommitteeMemberRow';

  const context = useContext(FormContext);

  const { isScreenAboveMd } = useScreenSize();

  /* 
    removes sign minutes required if sign minutes unselected
  */
  const signMinutesRoleValue = context.form?.watch(
    `roles[${committeeMemberRoles.indexOf('signMinutes')}].role`
  );
  useEffect(() => {
    if (signMinutesRoleValue === undefined) {
      context.form?.setValue(`roles[${committeeMemberRoles.indexOf('signMinutes')}].isOptional`, undefined);
    }
  }, [signMinutesRoleValue]);

  /* 
    removes sign resolutions required if sign minutes unselected
  */
  const signResolutionsRoleValue = context.form?.watch(
    `roles[${committeeMemberRoles.indexOf('signResolutions')}].role`
  );
  useEffect(() => {
    if (signResolutionsRoleValue === undefined) {
      context.form?.setValue(
        `roles[${committeeMemberRoles.indexOf('signResolutions')}].isOptional`,
        undefined
      );
    }
  }, [signResolutionsRoleValue]);

  const mandateStartAtValue = context.form?.watch('startAt');

  const sxCheckbox = { margin: 0, display: 'flex', '.MuiCheckbox-root': { padding: 0, pr: 0.5 } };
  const sxFormControlLabel = {
    mr: { md: 0 },
    ml: 0,
    '& .MuiFormControlLabel-label': { fontSize: '14px' }
  };
  const sxTableCell = {
    display: { md: 'table-cell' },
    padding: { md: '24px 12px' },
    borderBottom: { md: '1px solid rgba(0, 0, 0, 0.12)' },
    verticalAlign: { md: 'top' }
  };

  return (
    <>
      <Box sx={sxTableCell} role={isScreenAboveMd ? 'cell' : undefined}>
        <UserChip userId={userId} variant="outlined" />
        <DateTimePicker
          name="startAt"
          i18n={{ keyPrefix: keyPrefix + '.FieldMandateStartAt' }}
          type="date"
          inputProps={{ min: '1970-01-01', max: '2099-12-31' }}
          sx={{
            '& .MuiInputBase-input': {
              backgroundColor: 'white'
            }
          }}
        />
        <DateTimePicker
          name="endAt"
          i18n={{ keyPrefix: keyPrefix + '.FieldMandateEndAt' }}
          type="date"
          inputProps={{ min: '1970-01-01', max: '2099-12-31' }}
          sx={{
            '& .MuiInputBase-input': {
              backgroundColor: 'white'
            }
          }}
          isOptional={true}
          validationRules={{
            validate: (value) => {
              if (!mandateStartAtValue) return;

              const valid = isMeetingTimeDiffValid(mandateStartAtValue, value);
              if (!valid) {
                return t(`${keyPrefix + '.FieldMandateEndAt.errorTextBeforeStart'}`);
              }
            }
          }}
        />
      </Box>
      <Box sx={sxTableCell} role={isScreenAboveMd ? 'cell' : undefined}>
        <SelectTemp
          name="title"
          i18n={{ keyPrefix: keyPrefix + '.FieldTitle' }}
          options={committeeMembersTitlesArray.map((title) => ({
            id: title,
            label: t('common:committeeMemberTitles.' + title)
          }))}
          sx={{
            mt: { md: 0 },
            display: 'flex',
            maxWidth: { md: '184px' }
          }}
          isLabelHidden={isScreenAboveMd && true}
          inputProps={{ 'aria-label': keyPrefix + '.FieldTitle.label' }}
        />
      </Box>
      <Box sx={sxTableCell} role={isScreenAboveMd ? 'cell' : undefined}>
        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <FormLabel
            component="legend"
            sx={isScreenAboveMd ? visuallyHidden : { color: 'rgba(0, 0, 0, 0.87)', fontSize: '14px' }}
          >
            {t(keyPrefix + '.FieldSetLegendCreate')}
          </FormLabel>
          <FormGroup>
            {/* Create Meetings */}
            <ControlledCheckbox
              name={`roles[${committeeMemberRoles.indexOf('createMeetings')}].role`}
              value={{ checkedValue: `createMeetings`, uncheckedValue: '' }}
              i18n={{ keyPrefix: keyPrefix + '.FieldCreateMeetings' }}
              isOptional={true}
              sx={{ ...sxCheckbox, mb: { md: '14px' } }}
              sxFormControlLabel={sxFormControlLabel}
            />
            {/* Create Resolutions */}
            <ControlledCheckbox
              name={`roles[${committeeMemberRoles.indexOf('createResolutionProjects')}].role`}
              value={{ checkedValue: `createResolutionProjects`, uncheckedValue: '' }}
              isOptional={true}
              i18n={{ keyPrefix: keyPrefix + '.FieldCreateResolutionProjects' }}
              sx={sxCheckbox}
              sxFormControlLabel={sxFormControlLabel}
            />
          </FormGroup>
        </FormControl>
      </Box>
      <Box sx={sxTableCell} role={isScreenAboveMd ? 'cell' : undefined}>
        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <FormLabel
            component="legend"
            sx={isScreenAboveMd ? visuallyHidden : { color: 'rgba(0, 0, 0, 0.87)', fontSize: '14px' }}
          >
            {t(keyPrefix + '.FieldSetLegendWrite')}
          </FormLabel>
          <FormGroup>
            {/* Write Minutes */}
            <ControlledCheckbox
              name={`roles[${committeeMemberRoles.indexOf('takingNotes')}].role`}
              value={{ checkedValue: `takingNotes`, uncheckedValue: '' }}
              isOptional={true}
              i18n={{ keyPrefix: keyPrefix + '.FieldTakingNotes' }}
              sx={sxCheckbox}
              sxFormControlLabel={sxFormControlLabel}
            />
          </FormGroup>
        </FormControl>
      </Box>
      <Box sx={sxTableCell} role={isScreenAboveMd ? 'cell' : undefined}>
        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <FormLabel
            component="legend"
            sx={isScreenAboveMd ? visuallyHidden : { color: 'rgba(0, 0, 0, 0.87)', fontSize: '14px' }}
          >
            {t(keyPrefix + '.FieldSetLegendEdit')}
          </FormLabel>
          <FormGroup>
            {/* Edit Minutes */}
            <ControlledCheckbox
              name={`roles[${committeeMemberRoles.indexOf('reviewMeetings')}].role`}
              value={{ checkedValue: `reviewMeetings`, uncheckedValue: '' }}
              isOptional={true}
              i18n={{ keyPrefix: keyPrefix + '.FieldTakingNotes' }}
              sx={{ ...sxCheckbox, mb: { md: '14px' } }}
              sxFormControlLabel={sxFormControlLabel}
            />
            {/* Edit Documents */}
            <ControlledCheckbox
              name={`roles[${committeeMemberRoles.indexOf('createDocumentRecords')}].role`}
              value={{ checkedValue: `createDocumentRecords`, uncheckedValue: '' }}
              isOptional={true}
              i18n={{ keyPrefix: keyPrefix + '.FieldCreateDocumentRecords' }}
              sx={sxCheckbox}
              sxFormControlLabel={sxFormControlLabel}
            />
          </FormGroup>
        </FormControl>
      </Box>
      <Box sx={sxTableCell} role={isScreenAboveMd ? 'cell' : undefined}>
        <FormControl component="fieldset" sx={{ mb: 3 }}>
          <FormLabel
            component="legend"
            sx={isScreenAboveMd ? visuallyHidden : { color: 'rgba(0, 0, 0, 0.87)', fontSize: '14px' }}
          >
            {t(keyPrefix + '.FieldSetLegendSign')}
          </FormLabel>
          <FormGroup>
            {/* Sign Minutes */}
            <ControlledCheckbox
              name={`roles[${committeeMemberRoles.indexOf('signMinutes')}].role`}
              value={{ checkedValue: `signMinutes`, uncheckedValue: '' }}
              isOptional={true}
              i18n={{ keyPrefix: keyPrefix + '.FieldSignMinutes' }}
              sx={{ ...sxCheckbox, mb: { md: '14px' } }}
              sxFormControlLabel={sxFormControlLabel}
            />
            {signMinutesRoleValue === 'signMinutes' && (
              <ControlledCheckbox
                name={`roles[${committeeMemberRoles.indexOf('signMinutes')}].isOptional`}
                isOptional={true}
                sx={{ ...sxCheckbox, pl: '24px', mb: { md: '14px' } }}
                value={{ checkedValue: false, uncheckedValue: true }}
                i18n={{ keyPrefix: keyPrefix + '.FieldSignMinutesRequired' }}
                sxFormControlLabel={sxFormControlLabel}
              />
            )}
            {/* Sign Resolutions */}
            <ControlledCheckbox
              name={`roles[${committeeMemberRoles.indexOf('signResolutions')}].role`}
              value={{ checkedValue: `signResolutions`, uncheckedValue: '' }}
              isOptional={true}
              i18n={{ keyPrefix: keyPrefix + '.FieldSignResolutions' }}
              sx={{ ...sxCheckbox, mb: { md: '14px' } }}
              sxFormControlLabel={sxFormControlLabel}
            />
            {signResolutionsRoleValue === 'signResolutions' && (
              <ControlledCheckbox
                name={`roles[${committeeMemberRoles.indexOf('signResolutions')}].isOptional`}
                isOptional={true}
                sx={{ ...sxCheckbox, pl: '24px', mb: { md: '14px' } }}
                value={{ checkedValue: false, uncheckedValue: true }}
                i18n={{ keyPrefix: keyPrefix + '.FieldSignResolutionsRequired' }}
                sxFormControlLabel={sxFormControlLabel}
              />
            )}
          </FormGroup>
        </FormControl>
      </Box>

      <Box sx={sxTableCell} role={isScreenAboveMd ? 'cell' : undefined}>
        <Button type="submit" variant="contained" sx={{ display: 'flex', p: '4px 10px' }}>
          {t('common:labelSave')}
        </Button>
      </Box>
    </>
  );
};

import { useLocale } from '@koopajs/react';
import { TablePagination, Box } from '@mui/material';
import { useCallback } from 'react';
import { IResolution } from 'types';
import { ResolutionsCard } from './ResolutionsCard';

interface IResolutionsListProps {
  resolutions: IResolution[];
  size?: number;
  page?: number;
  totalCount?: number;
  isReloading: boolean;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
}

export const ResolutionsList: React.FC<IResolutionsListProps> = (props) => {
  const { resolutions, size, page, totalCount, isReloading, onPageChange, onSizeChange } = props;

  const { t } = useLocale();

  const handlePageChange = useCallback(
    (e: unknown, newPage: number) => {
      onPageChange(newPage);
    },
    [page]
  );

  const handleSizeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onSizeChange(Number(e.target.value)),
    []
  );

  return (
    <>
      <Box sx={{ opacity: isReloading ? 0.35 : 1 }}>
        {resolutions.map((resolution) => {
          return <ResolutionsCard key={resolution.id} resolution={resolution} />;
        })}
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 20, 50]}
        component="div"
        // sx={{ display: { xs: 'none', md: 'block' } }}
        count={totalCount || 999}
        rowsPerPage={size || 20}
        page={page ? page : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleSizeChange}
        SelectProps={{
          native: true
        }}
        // eslint-disable-next-line react/jsx-no-bind
        labelDisplayedRows={({ from, to, count }) => {
          const transOptions = { from, to, count };

          return count !== -1
            ? t('common:TablePagination.labelDisplayedRowsCount', transOptions)
            : t('common:TablePagination.labelDisplayedRowsMoreThan', transOptions);
        }}
        labelRowsPerPage={t('common:TablePagination.labelRowsPerPage')}
      />
    </>
  );
};

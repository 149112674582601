import { IMeeting } from 'types';
import {
  Stack,
  Typography,
  Divider,
  Box,
  Link as MUILink,
  Skeleton,
  IconButton,
  Avatar,
  Button,
  SxProps,
  Theme
} from '@mui/material';
import { DateTime } from 'luxon';
import { User } from '@koopajs/mui';
import { useLocale, useUserShow } from '@koopajs/react';
import { OpenInNew as OpenInNewIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { MeetingStateChip } from 'components/Meetings/MeetingStateChip';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { useScreenSize } from 'utils/useScreenSize';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import { Link as RouterLink } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { WriteMinutesButton } from '../MeetingCardButtons/WriteMinutesButton';

interface ICalendarViewMeetingCard {
  meeting: IMeeting;
  sx?: SxProps<Theme>;
  isSmallCardOnDesktop?: boolean;
}

export const CalendarViewMeetingCard: React.FC<ICalendarViewMeetingCard> = (props) => {
  const { meeting, sx, isSmallCardOnDesktop } = props;

  const { t } = useLocale();
  const keyPrefix = 'Dashboard.MeetingCard';
  const { user } = useUserShow();

  const {
    canUserContinueMeeting,
    hasMeetingStarted,
    isUserMeetingNoteTakerRole,
    meetingCommittee,
    canUserCurrentlyEditMeeting
  } = useMeetingStatus({
    meeting,
    user
  });

  const { isMobileScreen, isScreenAboveLg, isScreenAboveXl } = useScreenSize();

  const isSmallCard = isSmallCardOnDesktop || !isScreenAboveLg;

  const checkIfMeetingIsToday = (): boolean => {
    if (!meeting.startDateTime) return false;
    const dateToCheck = DateTime.fromISO(meeting.startDateTime);
    const today = DateTime.local();
    return dateToCheck.hasSame(today, 'day');
  };
  const isMeetingToday = checkIfMeetingIsToday();

  const renderViewMeetingIconButton = (): JSX.Element => {
    return (
      <IconButton
        aria-label={t('common:viewMeeting')}
        component={RouterLink}
        to={{
          pathname: `/meetings/${meeting.id}`,
          state: { activePath: '/' }
        }}
        sx={{ color: 'primary.main' }}
      >
        <VisibilityIcon />
      </IconButton>
    );
  };

  const isDateTimeInline = isScreenAboveXl && !isSmallCard;
  const renderDateAndTime = (sx?: SxProps<Theme>) => {
    return (
      <Box sx={sx}>
        <DateTimeStartEnd
          isoStringDate={
            meeting.startDateTime ||
            (meeting?.startDate
              ? DateTime.fromFormat(`${meeting.startDate}`, 'yyyy-MM-dd').toString()
              : undefined)
          }
          separator={isMobileScreen ? undefined : ' | '}
          dateFormat="DATE_HUGE"
          isoStringStart={
            meeting.startDateTime ||
            (meeting.startTime &&
              DateTime.fromFormat(meeting?.startTime as string, 'HH:mm', {
                zone: 'UTC'
              }).toString())
          }
          isoStringEnd={meeting.endDateTime}
          showNoDateNoTime={true}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        padding: '24px',
        borderRadius: '4px',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #dddddd',
        ...sx
      }}
    >
      <Box>
        {/* HEADER */}
        <Stack
          direction={isSmallCard ? 'column' : 'row'}
          justifyContent="space-between"
          flexWrap="wrap"
          gap="16px"
        >
          <Stack
            direction={isSmallCard ? 'column' : 'row'}
            alignItems={isSmallCard ? undefined : 'center'}
            sx={{
              fontSize: '16px'
            }}
          >
            {/* mobile: meeting state chip mobile & view button */}
            {isSmallCard && (
              <Stack flexDirection="row" justifyContent="space-between">
                <Stack direction="row" flexWrap="wrap" gap="16px">
                  <MeetingStateChip meeting={meeting} />
                </Stack>
                <Box>{renderViewMeetingIconButton()}</Box>
              </Stack>
            )}

            {isDateTimeInline && renderDateAndTime({ mr: '16px' })}

            {/* desktop: chip */}
            {!isSmallCard && (
              <Stack direction="row" gap="16px">
                <MeetingStateChip meeting={meeting} sx={{ alignSelf: 'center' }} />
              </Stack>
            )}
          </Stack>

          {/* desktop: edit / view buttons */}
          {!isSmallCard && (
            <Stack direction="row" gap={1.5} alignItems="center">
              <Button
                component={RouterLink}
                to={{
                  pathname: `/meetings/${meeting.id}`,
                  state: { activePath: hasMeetingStarted ? '' : '/' }
                }}
              >
                {t('common:labelView')}
              </Button>
              {canUserCurrentlyEditMeeting && (
                <Button
                  component={RouterLink}
                  to={{
                    pathname: `/meetings/${meeting.id}/edit`,
                    state: { activePath: '/' }
                  }}
                  variant="outlined"
                >
                  {t('common:labelEdit')}
                </Button>
              )}
            </Stack>
          )}
        </Stack>

        {!isDateTimeInline && renderDateAndTime({ mt: '16px' })}
      </Box>

      <Divider sx={{ my: '14px' }} />

      <Box sx={{ width: '100%' }}>
        {/* Title */}
        <Typography
          sx={{ mb: 2, fontSize: '16px', overflow: 'hidden', textOverflow: 'ellipsis' }}
          variant="body1"
          component="h3"
        >
          {meeting.title}
        </Typography>

        {/* Committee, location, participants */}
        <Typography
          variant="body2"
          sx={{
            opacity: '0.6',
            mb: 1,
            display: 'flex',
            flexWrap: 'wrap',
            minWidth: '0px',
            overflow: 'hidden',
            '&& > *': {
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }
          }}
        >
          {meetingCommittee && (
            <>
              <span>{meetingCommittee}</span>
              <span style={{ marginRight: '10px', marginLeft: '10px' }}>&#183;</span>
            </>
          )}

          <span>
            {meeting.location?.type
              ? t(`common:locationType.${meeting.location.type}`)
              : t('common:noLocation')}
          </span>

          <span
            style={{
              marginRight: '10px',
              marginLeft: '10px',
              display: isMobileScreen ? 'none' : 'inline'
            }}
          >
            &#183;
          </span>
          <span style={{ flexBasis: isMobileScreen ? '100%' : '', marginLeft: 0 }}>
            {t(`${keyPrefix}.participantsInvited`, { count: meeting.participants?.length || 0 })}
          </span>
        </Typography>
        {meeting.location?.url && (
          <Box sx={{ mb: 1 }}>
            <MUILink
              href={meeting.location?.url}
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                color: 'secondary.light'
              }}
              target="_blank"
              rel="noopener"
            >
              <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />
              <Typography variant="body2">{t(keyPrefix + '.meetingLink')}</Typography>
            </MUILink>
          </Box>
        )}
        {meeting.location?.address && (
          <Box sx={{ whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'end', mb: 1 }}>
            <LocationOnIcon fontSize="small" sx={{ mt: '2px', mr: 0.5, opacity: '0.6' }} />
            <Typography variant="body2" sx={{ opacity: '0.6' }} noWrap={true} width="350px">
              {meeting.location?.address}
            </Typography>
          </Box>
        )}
      </Box>

      {/* Message box: Someone is taking notes */}
      {hasMeetingStarted &&
        meeting.currentNoteTakerId &&
        isUserMeetingNoteTakerRole &&
        !canUserContinueMeeting && (
          <Box
            sx={{
              padding: '14px',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'left',
              border: '1px solid #dddddd',
              maxWidth: '450px',
              mt: 2
            }}
          >
            <Stack direction="row" spacing={1}>
              <PersonIcon color="action" fontSize="small" />

              <Typography variant="caption" sx={{ color: '#777777', fontSize: '14px' }}>
                <User
                  id={meeting.currentNoteTakerId}
                  // eslint-disable-next-line react/jsx-no-bind
                  render={(profile) => (
                    <>
                      {profile?.username || (
                        <Skeleton variant="text" sx={{ display: 'inline-block', width: '100px' }} />
                      )}
                    </>
                  )}
                />{' '}
                {t(keyPrefix + '.isTakingNotes')}
              </Typography>
            </Stack>
            <Typography variant="caption" sx={{ mt: 1, color: '#777777', fontSize: '14px' }}>
              {t(keyPrefix + '.AskTheUserToQuitMeeting')}
            </Typography>
          </Box>
        )}

      {(canUserContinueMeeting || (canUserCurrentlyEditMeeting && isSmallCard)) && (
        <>
          <Divider sx={{ mt: 14 - 8 + 'px', mb: '14px' }} />
          <Stack
            direction="row"
            justifyContent={canUserContinueMeeting && !isSmallCard ? 'space-between' : undefined}
            flexWrap="wrap"
            gap="12px"
          >
            {/* Start meeting UI */}
            {canUserContinueMeeting && !isSmallCard && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                <Avatar sx={isMeetingToday ? { bgcolor: '#EDF0FF' } : { bgcolor: '#00000014' }}>
                  <PlayArrowIcon
                    sx={isMeetingToday ? { color: '#151E54' } : { color: 'black', opacity: 0.54 }}
                  />
                </Avatar>
                <Typography component="span" variant="body1" color="text.primary">
                  {t('Components.StartMeetingBanner.labelStartMeeting')}
                </Typography>
              </Box>
            )}
            {canUserCurrentlyEditMeeting && isSmallCard && (
              <Button
                component={RouterLink}
                to={{
                  pathname: `/meetings/${meeting.id}/edit`,
                  state: { activePath: '/' }
                }}
                data-cy="dashboard_meeting-card_edit-button"
                variant="outlined"
              >
                {t('common:labelEdit')}
              </Button>
            )}

            {/* Write minutes button */}
            {canUserContinueMeeting && <WriteMinutesButton meeting={meeting} variant="contained" />}
          </Stack>
        </>
      )}
    </Box>
  );
};

import { useCallback, useEffect, useState } from 'react';
import { useComponentVisibility } from '@koopajs/react';
import { ITopic, ITopicResolutionOutcome, VoteOutcome } from 'types';
import { RecordVoteDialogOptions } from './RecordVoteDialogOptions';
import { IUseLiveMeetingResponse } from 'components/Meetings/InProgress/useLiveMeeting';
import { Dialog } from '@koopajs/mui';

export const RecordVote: React.FC = () => {
  const keyPrefix = 'MeetingStateInProgress.ResolutionFields.RecordVoteDialog';
  const dialog = useComponentVisibility('recordVote');
  const {
    liveMeeting,
    defaultValues: topic,
    isResolutionBookSwitchChecked
  } = dialog.getContext() as {
    liveMeeting: IUseLiveMeetingResponse;
    defaultValues: ITopic | undefined;
    isResolutionBookSwitchChecked?: boolean;
  };

  const {
    meeting,
    currentTopicId,
    handleTopicUpdate,
    isCommitteeTakingResolutions,
    topic: liveTopic,
    users
  } = liveMeeting || {};

  const [isUnanimousSelected, setIsUnanimousSelected] = useState(true);

  useEffect(() => {
    // isUnanimousSelected will be true whenever the dialog is opened, unless the topic is set already to not unanimous
    if (dialog.isVisible) {
      if (topic?.resolutionOutcome?.isUnanimous !== undefined) {
        setIsUnanimousSelected(topic?.resolutionOutcome?.isUnanimous);
      } else {
        setIsUnanimousSelected(true);
      }
    }
  }, [dialog.isVisible]);

  const handleSubmit = useCallback(
    async (data: any): Promise<boolean> => {
      const formData = data as {
        minutesApproving: { [k: string]: boolean };
        pastMeetingsToApprove: { [k: string]: boolean };
        resolutionOutcome: { outcome: VoteOutcome; mover: string; seconder: string };
        isResolution?: boolean;
      };

      try {
        const payload: {
          resolutionOutcome: ITopicResolutionOutcome;
          pastMeetingsToApprove?: {
            meetingId: string;
            isApproved: boolean;
          }[];
          isResolution?: boolean;
        } = {
          resolutionOutcome: {}
        };

        if (isUnanimousSelected) {
          payload.resolutionOutcome = {
            isUnanimous: true,
            outcome: formData.resolutionOutcome.outcome,
            mover: formData.resolutionOutcome.mover,
            seconder: formData.resolutionOutcome.seconder
          };
        } else {
          Object.assign(payload, formData);
          payload.resolutionOutcome.isUnanimous = false;
        }

        if (liveTopic?.type === 'pastMinutesApproval') {
          const minutesToApproveArray = [];
          if (formData.pastMeetingsToApprove) {
            for (const [id, value] of Object.entries(formData.pastMeetingsToApprove)) {
              const isMinuteApproved = ['declined', 'reported'].includes(formData.resolutionOutcome.outcome)
                ? false
                : Boolean(value);

              minutesToApproveArray.push({ meetingId: id, isApproved: isMinuteApproved });
            }
          }

          payload.pastMeetingsToApprove = minutesToApproveArray;
        }

        payload.resolutionOutcome.recordedAt = new Date().toISOString();

        if (
          formData.isResolution &&
          formData.resolutionOutcome.outcome !== 'reported' &&
          isCommitteeTakingResolutions
        ) {
          payload.isResolution = true;
        } else {
          payload.isResolution = false;
        }

        const updateTopicResponse = await handleTopicUpdate(payload);

        return updateTopicResponse;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    [meeting?.id, currentTopicId, isUnanimousSelected]
  );

  return (
    <Dialog.Form
      dialogKey="recordVote"
      onSubmit={handleSubmit}
      i18n={{ keyPrefix: keyPrefix + '.DialogForm' }}
      isCancelVisible={true}
      maxWidth="lg"
      dialogProps={{ className: 'rr-block' }}
    >
      {
        <RecordVoteDialogOptions
          liveMeeting={liveMeeting}
          isUnanimousSelected={isUnanimousSelected}
          setIsUnanimousSelected={setIsUnanimousSelected}
          isResolutionBookSwitchChecked={isResolutionBookSwitchChecked}
          users={users}
        />
      }
    </Dialog.Form>
  );
};

import Panorama from '../../../assets/panorama-logo.png';
import PanoramaSmall from '../../../assets/favicon-panorama.png';
import { Box } from '@mui/material';
import { useWorkspaceShow } from '@koopajs/react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface ILogoProps {
  isSmallVersion?: boolean;
  sx?: SxProps<Theme>;
}

export const Logo: React.FC<ILogoProps> = (props) => {
  const { isSmallVersion, sx } = props;
  const { workspace } = useWorkspaceShow();

  const panoramaLogo = isSmallVersion ? PanoramaSmall : Panorama;

  return (
    <Box
      component="img"
      src={workspace?.icon || panoramaLogo}
      alt="Panorama"
      sx={{ maxHeight: '50px', maxWidth: '200px', display: 'block', ...sx }}
    />
  );
};

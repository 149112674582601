import { useLocale } from '@koopajs/react';
import { Stack, Chip, Typography, Box } from '@mui/material';
import { User } from '@koopajs/mui';
import { Person as PersonIcon, TimerOutlined as TimerOutlinedIcon } from '@mui/icons-material';
import { checkIsSmartTopicType } from 'utils/topicTypeArrays';
import { ITopic } from '../../types';
import { PastMeetingsToApproveList } from './PastMinutesApprovalField/PastMeetingsToApproveList';
import RichTextReadOnly from 'components/temp/RichTextMultiLine/RichTextReadOnly';
import { formatTime } from 'utils/DateTime/formatTime';
import { DateTime } from 'luxon';
import { TopicChipConditions } from 'components/TopicChipConditions';

interface ITopicCardMeetingPrintProps {
  topic: ITopic;
  order: number;
  showApprovedPastMeetingsToApprove?: boolean;
}

export const TopicCardMeetingPrint: React.FC<ITopicCardMeetingPrintProps> = (props) => {
  const { topic, order, showApprovedPastMeetingsToApprove } = props;

  const { t, locale } = useLocale();

  return (
    <Box sx={{ p: 3 }}>
      {/* START TIME */}
      {topic.calculatedTopicStartTime ? (
        <Chip
          label={formatTime({
            isoString: DateTime.fromSeconds(topic.calculatedTopicStartTime).toISO(),
            locale
          })}
          sx={{
            mr: 8,
            fontVariantNumeric: 'tabular-nums'
          }}
        />
      ) : (
        <Chip
          label={<TimerOutlinedIcon sx={{ fontSize: '16px' }} />}
          sx={{
            mr: 8,
            '& .MuiChip-label': {
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '50px'
            }
          }}
        />
      )}

      {/* TITLE, OWNER, TOPIC TYPE */}
      <Stack direction="column" spacing={2} width="100%" sx={{ mt: 2 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography sx={{ width: '100%', fontWeight: '400' }}>
            {order}. <>{topic.title ? topic.title : t(`common:topicTypes.${topic.type}`)}</>
          </Typography>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-evenly">
          {/* OWNER */}
          <Stack direction="row" spacing={1} flex={1} alignItems="center">
            <PersonIcon color="action" />
            <Box sx={{ ml: 1 }}>
              <User id={topic.assignedTo} variant="text-only" />
            </Box>
          </Stack>

          {/* TOPIC TYPE */}
          <Box flex={1}>
            <TopicChipConditions topicType={topic.type} color="default" variant="outlined" />
          </Box>
        </Stack>
      </Stack>

      {/* BODY FIELD */}
      {[
        'quorum',
        'nominations',
        'agendaApproval',
        'pastMinutesApproval',
        'ceoReport',
        'information',
        'discussion',
        'resolution',
        'miscellaneous',
        'adjournment'
      ].includes(topic.type) &&
        topic.description && (
          <RichTextReadOnly
            value={topic.description}
            sxEditorContainer={{
              pt: 2
            }}
          />
        )}
      {topic.type === 'pastMinutesApproval' && (
        <PastMeetingsToApproveList
          meetingId={topic.meetingId}
          topicId={topic.id}
          isViewPrint={true}
          topic={topic}
          showApprovedMeetings={showApprovedPastMeetingsToApprove ? true : false}
        />
      )}
    </Box>
  );
};

import { useComponentVisibility, useLocale, useParamsKey, useSearchParams } from '@koopajs/react';
import {
  Typography,
  Box,
  Stack,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { IMeeting, IResolution } from 'types';
import { Layout, Loading } from '@koopajs/mui';
import { CreateButton } from 'components/CreateButton';
import { FilterChips } from '../FilterChips';
import { MobileFloatingButtons } from 'components/MobileFloatingButtons';
import { ResolutionsList } from 'components/Resolutions/ResolutionsList';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { EmptyState } from 'components/EmptyState';
import { NoResultsResetFilters } from 'components/NoResultsResetFilters';
import { ViewMinutes } from 'components/Dialogs/ViewMinutes';
import { ViewResolution } from 'components/Dialogs/ViewResolution';
import { Link as RouterLink } from 'react-router-dom';
import { PageContainer } from 'components/temp/PageContainer';
import { Api } from '@koopajs/app';
import { useResourceList } from 'components/temp/useResourceListTemp';
import { FacetListResolutionsInline } from 'components/Resolutions/FacetListResolutionsInline';
import { Sidebar } from 'components/Sidebars/Sidebar';
import { FacetListResolutionsSidebar } from 'components/Sidebars/Resolutions/FacetListResolutionsSidebar';

interface IResolutionsBookProps {
  resolution?: IResolution;
  isResolutionProcessing?: boolean;
}

export const ResolutionsBook: React.FC<IResolutionsBookProps> = (props) => {
  const { resolution, isResolutionProcessing } = props;

  const [sentWrittenResolutionsCount, setSentWrittenResolutionsCount] = useState(0);

  const { t } = useLocale();
  const keyPrefix = 'Resolutions';

  const { resources: meetings, state: meetingsState } = useResourceList<IMeeting>({
    path: '/meetings',
    searchParams: {
      filters: ['NOT _exists_:boardApprovedAt'],
      size: 50
    }
  });

  const search = useSearchParams();

  const committeeFilter = search.searchParams?.filters?.find((filter) =>
    filter.startsWith('meetingCommitteeId.keyword')
  );
  let currentCommitteeId: undefined | string = undefined;
  if (committeeFilter) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [type, ...value] = committeeFilter.split(':');
    currentCommitteeId = value.join(':').replace(/"/g, '');
  }

  const minutesId = useParamsKey('minutesId');

  const sidebarVisibility = useComponentVisibility('sidebar');

  const handlePageChange = useCallback((page: number): void => {
    search.setSearchParams({ page });
  }, []);

  const handleSizeChange = useCallback((size: number): void => {
    search.setSearchParams({ size, page: undefined });
  }, []);

  useEffect(() => {
    search.setSearchParams({ page: undefined });
  }, [JSON.stringify(search?.searchParams?.filters)]);

  const {
    resources: resolutionsList,
    state: resolutionsState,
    isProcessing,
    totalCount
  } = useResourceList<IResolution>({
    path: '/resolutions',
    searchParams: {
      sort: 'resolutionOutcome.recordedAt:DESC',
      ...search.searchParams,
      filters: [...(search.searchParams?.filters || []), 'state.keyword:sealed']
    }
  });

  useEffect(() => {
    const fetchSentResolutions = async () => {
      const res = await Api.client.get(
        `/resolutions?size=50&filters[]=${encodeURIComponent(
          '_exists_:attachedToEmail AND state.keyword:attached'
        )}`
      );
      return res;
    };
    fetchSentResolutions()
      .then((res) => {
        setSentWrittenResolutionsCount(res.data._metadata.totalCount);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const hasNoMeetings = meetingsState === 'loaded-empty' || meetingsState === 'loaded-no-search-result';

  if (meetingsState === 'loading' || meetingsState === 'reloading' || resolutionsState === 'loading')
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );

  if (hasNoMeetings && resolutionsState === 'loaded-empty')
    return <EmptyState button={<CreateButton otherButtonProps={{ disableElevation: true }} />} />;

  const pendingMinutesCountsObj = {
    pendingMinutesCount: 0,
    resolutionsCount: 0
  };
  meetings.forEach((curMeeting) => {
    if (!curMeeting.minutesEndedAt) return;
    if (currentCommitteeId && !(currentCommitteeId === curMeeting.committeeId)) return;

    const resolutionsCount = curMeeting.topics?.filter((t) => t.isResolution).length || 0;
    pendingMinutesCountsObj.resolutionsCount = pendingMinutesCountsObj.resolutionsCount + resolutionsCount;
    if (resolutionsCount) {
      pendingMinutesCountsObj.pendingMinutesCount = pendingMinutesCountsObj.pendingMinutesCount += 1;
    }
  });

  if (
    resolutionsState === 'loaded-empty' &&
    !pendingMinutesCountsObj.resolutionsCount &&
    !sentWrittenResolutionsCount
  )
    return (
      <EmptyState
        subTitle={t(keyPrefix + '.emptyStateSubTitleNoTopics')}
        description={t(keyPrefix + '.emptyStateDescriptionNoTopics')}
      />
    );

  return (
    <>
      <Helmet>
        <title>{`${t('common:navigation.resolutions')} - Panorama`}</title>
      </Helmet>
      <PageContainer sxChildren={{ px: { xs: '24px', lg: '48px' }, py: '24px' }} className="rr-block">
        <Sidebar facetList={<FacetListResolutionsSidebar />} onHideSidebar={sidebarVisibility.setHidden} />
        <MobileFloatingButtons
          fabData={[
            {
              label: t('common:filters'),
              onClick: sidebarVisibility.setVisible
            }
          ]}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant="h1" sx={{ fontSize: '24px' }}>
            {t(keyPrefix + '.title')}
          </Typography>
          <FacetListResolutionsInline />
        </Box>
        <Box sx={{ my: '24px' }}>
          <FilterChips isProcessing={isProcessing} />
        </Box>
        {Boolean(
          (pendingMinutesCountsObj.resolutionsCount && meetingsState === 'loaded') ||
            sentWrittenResolutionsCount
        ) && (
          <Accordion
            elevation={0}
            sx={{
              '&:before': {
                display: 'none'
              },
              border: '1px solid #dddddd',
              borderRadius: '4px',
              p: 1,
              my: 1
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="pending-resolutions-content"
              id="pending-resolutions-header"
            >
              <Box sx={{ display: 'flex' }}>
                <PlaylistAddIcon sx={{ opacity: 0.6, mr: 0.5 }} />
                <Typography>
                  {t(keyPrefix + '.accordionTitle', {
                    count:
                      Number(pendingMinutesCountsObj.resolutionsCount) + Number(sentWrittenResolutionsCount)
                  })}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="column" spacing={2}>
                {Boolean(pendingMinutesCountsObj.resolutionsCount) && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: { xs: 'flex-start', md: 'center' },
                      flexDirection: { xs: 'column', md: 'row' },
                      gap: { xs: '14px', md: 0 }
                    }}
                  >
                    <Box>
                      {t(
                        pendingMinutesCountsObj.pendingMinutesCount === 1
                          ? `${keyPrefix}.noticeResolutionsToAdoptOneMinute`
                          : `${keyPrefix}.noticeResolutionsToAdoptMultipleMinutes`,
                        {
                          count: pendingMinutesCountsObj.resolutionsCount,
                          minutesCount: pendingMinutesCountsObj.pendingMinutesCount
                        }
                      )}
                    </Box>
                    <Button
                      component={RouterLink}
                      to="/pending-review"
                      sx={{ p: { xs: 0, md: '6px 8px' }, minWidth: { xs: 0, md: '64px' } }}
                    >
                      {t('common:labelView')}
                    </Button>{' '}
                  </Box>
                )}
                {Boolean(sentWrittenResolutionsCount) && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: { xs: 'flex-start', md: 'center' },
                      flexDirection: { xs: 'column', md: 'row' },
                      gap: { xs: '14px', md: 0 }
                    }}
                  >
                    <Box>
                      {t(
                        `${keyPrefix}.noticeWrittenResolutionsToAdopt`,

                        {
                          count: sentWrittenResolutionsCount
                        }
                      )}
                    </Box>
                    <Button
                      component={RouterLink}
                      to="/to-sign"
                      sx={{ p: { xs: 0, md: '6px 8px' }, minWidth: { xs: 0, md: '64px' } }}
                    >
                      {t('common:labelView')}
                    </Button>{' '}
                  </Box>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        )}
        {(resolutionsState === 'loaded' || resolutionsState === 'reloading') && (
          <ResolutionsList
            resolutions={resolutionsList}
            totalCount={totalCount}
            size={search.searchParams.size}
            page={search.searchParams.page}
            isReloading={resolutionsState === 'reloading'}
            onPageChange={handlePageChange}
            onSizeChange={handleSizeChange}
          />
        )}
        {resolutionsState === 'loaded-no-search-result' && <NoResultsResetFilters />}
        <ViewResolution resolution={resolution} isResolutionProcessing={isResolutionProcessing} />
        <ViewMinutes minutesId={minutesId} pathOnClose="/resolutions" />
        {/* adds space at the bottom for filters button */}
        <Box sx={{ display: { lg: 'none' }, height: `${40 + 30}px` }} />
      </PageContainer>
    </>
  );
};

import { useCallback, useMemo, useState } from 'react';
import { useLocale, useResourceList, useUserShow, useParamsKey } from '@koopajs/react';
import { Typography, Box, Stack, Button, Divider, Badge, Tabs, Tab } from '@mui/material';
import { IResolution, ICommittee, IMeeting, IMinute } from 'types';
import { Loading } from '@koopajs/mui';
import { EmptyState } from 'components/EmptyState';
import { MeetingPendingReviewCard } from 'components/Dashboard/MeetingPendingReviewCard';
import { ResolutionPendingReviewCard } from 'components/Dashboard/ResolutionPendingReviewCard';
import { CreateButton } from 'components/CreateButton';
import { NoMeetingMessage } from 'components/Dashboard/NoMeetingMessage';
import { MeetingCards } from 'components/Dashboard/MeetingCards';
import { Helmet } from 'react-helmet';
import { ToSignList } from 'components/Dashboard/ToSign/ToSignList';
import { useScreenSize } from 'utils/useScreenSize';
import { PageContainer } from 'components/temp/PageContainer';
import { CalendarView } from 'components/Dashboard/CalendarView';

export const Dashboard: React.FC = () => {
  const { user } = useUserShow();
  const { isMobileScreen } = useScreenSize();

  const { resources: meetings, state: meetingsState } = useResourceList<IMeeting>({
    path: '/meetings',
    searchParams: {
      size: 50,
      filters: ['NOT _exists_:boardApprovedAt']
    }
  });

  const { resources: meetingsPendingReview } = useResourceList<IMeeting>({
    path: '/meetings-to-review',
    searchParams: {
      size: 50
    }
  });

  const { resources: minutesToSign } = useResourceList<IMinute>({
    path: '/minutes',
    searchParams: {
      size: 50,
      filters: ['NOT _exists_:signaturesCompletedAt']
    }
  });

  const { resources: resolutionsToSign } = useResourceList<IResolution>({
    path: '/resolutions',
    searchParams: {
      size: 50,
      filters: ['NOT _exists_:signaturesCompletedAt', 'NOT state.keyword:draft']
    }
  });

  const { resources: resolutionsPendingReview, state: resolutionsPendingReviewState } =
    useResourceList<IResolution>({
      path: '/resolutions/to-review',
      searchParams: {
        size: 50
      }
    });

  const sortedItemsToSign = useMemo(() => {
    const obj: {
      currentUser: (IResolution | IMinute)[];
      otherUsers: (IResolution | IMinute)[];
    } = {
      currentUser: [],
      otherUsers: []
    };

    const itemsToSign = [
      ...minutesToSign.map((m): IMinute => ({ ...m, model: 'Minute' })),
      ...resolutionsToSign.map((r): IResolution => ({ ...r, model: 'Resolution' }))
    ];
    itemsToSign.forEach((item) => {
      // eslint-disable-next-line no-unused-expressions
      item.membersLeftToSign?.some((m) => m.userId === user?.id)
        ? obj.currentUser.push(item)
        : obj.otherUsers.push(item);
    });

    return obj;
  }, [JSON.stringify(minutesToSign), JSON.stringify(resolutionsToSign)]);

  const urlParam = useParamsKey('key') || '';

  const { t } = useLocale();
  const keyPrefix = 'Dashboard';

  const { resources: committees } = useResourceList<ICommittee>({ path: '/committees', useCache: true });

  const committeeObj: { [k: string]: string } = {};
  committees.map((committee) => {
    committeeObj[committee.id] = committee.name;
  });

  const myUpcomingMeetings = useMemo(
    () =>
      meetings.filter(
        (meeting) =>
          meeting.participants?.map((participant) => participant.userId).includes(user?.id as string) &&
          !meeting.minutesEndedAt
      ),
    [meetings]
  );

  const otherUpcomingMeetings = useMemo(
    () =>
      meetings.filter(
        (meeting) =>
          !meeting.participants?.map((participant) => participant.userId).includes(user?.id as string) &&
          !meeting.minutesEndedAt
      ),
    [meetings]
  );

  const upcomingMeetingsTabs = [
    {
      id: 0,
      label: isMobileScreen ? t(keyPrefix + '.tabMyMeetingsMobile') : t(keyPrefix + '.tabMyMeetings'),
      key: 'my-meetings'
    },
    {
      id: 1,
      label: t(keyPrefix + '.tabOtherMeetings'),
      key: 'other-meetings'
    }
  ];
  const [upcomingMeetingsTabId, setUpcomingMeetingsTabId] = useState(0);
  const handleChangeUpcomingMeetingTabs = useCallback(
    (event: React.SyntheticEvent, newTabId: number) => {
      setUpcomingMeetingsTabId(newTabId);
    },
    [upcomingMeetingsTabId]
  );
  const isMyMeetingsSelected = upcomingMeetingsTabId === 0;
  const isOtherMeetingsSelected = upcomingMeetingsTabId === 1;

  const toSignTabs = [
    { id: 0, label: t(keyPrefix + '.tabMySignatures'), key: 'my-signatures' },
    { id: 1, label: t(keyPrefix + '.tabPendingSignatures'), key: 'pending-signatures' }
  ];
  const [toSignTabId, setToSignTabId] = useState(0);
  const handleChangeToSignTabs = useCallback(
    (event: React.SyntheticEvent, newTabId: number) => {
      setToSignTabId(newTabId);
    },
    [toSignTabId]
  );

  const selectedItemsToSign =
    toSignTabId === 0 ? sortedItemsToSign.currentUser : sortedItemsToSign.otherUsers;

  const hasNoMeetings = meetingsState === 'loaded-empty' || meetingsState === 'loaded-no-search-result';
  const hasNoPendingResolutions = resolutionsPendingReviewState === 'loaded-empty';

  if (meetingsState === 'loading')
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );

  if (hasNoMeetings && hasNoPendingResolutions)
    return <EmptyState button={<CreateButton otherButtonProps={{ disableElevation: true }} />} />;

  return (
    <>
      <Helmet>
        <title>{`${t('common:navigation.dashboard')} - Panorama`}</title>
      </Helmet>

      <PageContainer sxChildren={{ px: { xs: '24px', lg: '48px' }, py: '24px' }} className="rr-block">
        <Typography variant="h1" sx={{ fontSize: '24px' }}>
          {t(keyPrefix + '.title')}
        </Typography>
        {(urlParam === 'pending-review' || urlParam === 'to-sign') && (
          <Typography variant="h2" sx={{ marginTop: '24px', fontWeight: 500, fontSize: '20px' }}>
            {urlParam === 'pending-review' && t(keyPrefix + '.titleToReview')}
            {urlParam === 'to-sign' && t(keyPrefix + '.titleToSign')}
          </Typography>
        )}
        {!urlParam && isMobileScreen && (
          <Typography variant="h2" sx={{ marginTop: '24px', fontWeight: 500, fontSize: '20px' }}>
            {t(keyPrefix + '.titleUpcomingMeetings')}
          </Typography>
        )}
        {!urlParam && (
          <Tabs
            value={upcomingMeetingsTabId}
            onChange={handleChangeUpcomingMeetingTabs}
            sx={{ display: 'inline-flex', my: 2 }}
          >
            {upcomingMeetingsTabs.map(({ id, label, key }) => {
              return <Tab key={id} label={label} data-cy={`dashboard_${key}-tab`} />;
            })}
          </Tabs>
        )}
        {Boolean(myUpcomingMeetings.length) && (
          <>
            <Box sx={{ display: isMyMeetingsSelected && !urlParam ? 'block' : 'none' }}>
              <CalendarView meetings={myUpcomingMeetings} />
            </Box>
            <Typography variant="h2" sx={{ marginTop: '24px', fontWeight: 500, fontSize: '20px' }}>
              {t(keyPrefix + '.titleAllMyUpcomingMeetings')}
            </Typography>
          </>
        )}
        {/* Upcoming meetings */}
        {isMyMeetingsSelected && !urlParam && (
          <>
            {myUpcomingMeetings.length > 0 ? (
              <Box sx={{ marginTop: '24px' }}>
                <MeetingCards meetings={myUpcomingMeetings} />
              </Box>
            ) : (
              <NoMeetingMessage text={t(keyPrefix + '.noMyUpcomingMeetings')} />
            )}
          </>
        )}
        {isOtherMeetingsSelected && !urlParam && (
          <>
            {otherUpcomingMeetings.length > 0 ? (
              <Box sx={{ marginTop: '24px' }}>
                <MeetingCards meetings={otherUpcomingMeetings} />
              </Box>
            ) : (
              <NoMeetingMessage text={t(keyPrefix + '.noOtherUpcomingMeetings')} />
            )}
          </>
        )}
        {/* Minutes pending */}
        {urlParam === 'pending-review' && (
          <>
            {meetingsPendingReview.length > 0 || resolutionsPendingReview.length > 0 ? (
              <Stack spacing={3} sx={{ marginTop: '24px' }}>
                <>
                  {resolutionsPendingReview.map((resolution) => {
                    return (
                      <div key={resolution.id}>
                        <ResolutionPendingReviewCard resolution={resolution} user={user} />
                      </div>
                    );
                  })}
                  {meetingsPendingReview.map((meeting: IMeeting) => {
                    return (
                      <div key={meeting.id}>
                        <MeetingPendingReviewCard meeting={meeting} user={user} />
                      </div>
                    );
                  })}
                </>
              </Stack>
            ) : (
              <NoMeetingMessage text={t(keyPrefix + '.noItemsToReview')} />
            )}
          </>
        )}
        {urlParam === 'to-sign' && (
          <>
            <Tabs
              value={toSignTabId}
              onChange={handleChangeToSignTabs}
              sx={{ display: 'inline-flex', mt: 2, mb: 1 }}
            >
              {toSignTabs.map((tab) => {
                return <Tab key={tab.id} label={tab.label} data-cy={`dashboard_${tab.key}-tab`} />;
              })}
            </Tabs>

            {selectedItemsToSign.length > 0 ? (
              <ToSignList itemsToSign={selectedItemsToSign} isSignable={Boolean(toSignTabId === 0)} />
            ) : (
              <NoMeetingMessage
                text={
                  toSignTabId === 0 ? t(keyPrefix + '.noMyItemsToSign') : t(keyPrefix + '.noOtherItemsToSign')
                }
              />
            )}
          </>
        )}
        <Box sx={{ display: { lg: 'none' }, height: `${40 + 30}px` }} />
      </PageContainer>
    </>
  );
};

import { Chip } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { CommitteeMemberRole } from 'types';

interface IPermissionRoleChipProps {
  role: CommitteeMemberRole;
  isOptional?: boolean;
  sx?: SxProps<Theme>;
  isLongText?: boolean;
}

export const PermissionRoleChip: React.FC<IPermissionRoleChipProps> = (props) => {
  const { role, isOptional, sx, isLongText } = props;

  const { t } = useLocale();
  const keyPrefix = 'Organization.PermissionsRoleChip';

  const labels: Record<CommitteeMemberRole, string> = {
    createMeetings: isLongText ? t(keyPrefix + '.createMeetings') : t(keyPrefix + '.meetings'),
    takingNotes: isLongText ? t(keyPrefix + '.takingNotes') : t(keyPrefix + '.minutes'),
    reviewMeetings: isLongText ? t(keyPrefix + '.reviewMeetings') : t(keyPrefix + '.minutes'),
    signMinutes: isLongText ? t(keyPrefix + '.signMinutes') : t(keyPrefix + '.minutes'),
    signResolutions: isLongText ? t(keyPrefix + '.signResolutions') : t(keyPrefix + '.resolutions'),
    createResolutionProjects: isLongText
      ? t(keyPrefix + '.createResolutionProjects')
      : t(keyPrefix + '.resolutions'),
    createDocumentRecords: isLongText ? t(keyPrefix + '.createDocumentRecords') : t(keyPrefix + '.documents')
  };

  const labelColors: Record<CommitteeMemberRole, string> = {
    createMeetings: '#FFE1BB',
    takingNotes: '#EDF0FF',
    reviewMeetings: '#EDF0FF',
    signMinutes: '#EDF0FF',
    signResolutions: '#D1D5E7',
    createResolutionProjects: '#D1D5E7',
    createDocumentRecords: '#CAE8E5'
  };

  return (
    <Chip
      label={`${labels[role] || role}${isOptional === false ? ' *' : ''}`}
      sx={{ backgroundColor: labelColors[role] || '#E0E0E0', ...sx }}
    />
  );
};

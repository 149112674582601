import { Ref, useLocale, useResourceList } from '@koopajs/react';
import { NewCheckbox } from 'components/temp/NewCheckbox';
import { IMeetingRef, ITopic } from 'types';
import { FormLabel, Box } from '@mui/material';
import { formatDate } from 'utils/DateTime/formatDate';

interface IPastMeetingAdoptionProps {
  topic: ITopic;
}

export const PastMeetingAdoption: React.FC<IPastMeetingAdoptionProps> = (props) => {
  const { topic } = props;

  const { t, locale } = useLocale();
  const keyPrefix = 'MeetingStateInProgress.ResolutionFields.RecordVoteDialog';

  const { resources: meetingsToApprove } = useResourceList({
    path: `/meetings/${topic?.meetingId}/topics/${topic?.id}/minutes-to-approve`
  });

  const hasMeetingsToApprove = meetingsToApprove.some((m) => !m.boardApprovedAt);

  return (
    <Box sx={{ my: 1 }}>
      {hasMeetingsToApprove && <FormLabel component="legend">{t(keyPrefix + '.labelPastMinutes')}</FormLabel>}
      {topic?.pastMeetingsToApprove?.map(({ meetingId, isApproved }) => {
        return (
          <Ref content={`ref:meeting:${meetingId}`} key={meetingId}>
            {(item) => {
              const minutes = item as IMeetingRef;
              if (!minutes || minutes?.boardApprovedAt) return null;

              return (
                <NewCheckbox
                  name={`pastMeetingsToApprove[${meetingId}]`}
                  i18n={{
                    keyPrefix: 'common:CheckboxLabelInterpolation',
                    options: {
                      label: `${
                        minutes?.startDateTime
                          ? `${formatDate({
                              isoString: minutes.startDateTime,
                              format: 'DATE_FULL',
                              locale
                            })}`
                          : t('common:noDate')
                      } - ${minutes?.label}`
                    }
                  }}
                  isOptional={true}
                  sx={{ pr: '16px' }}
                  // defaults to checked for first vote, else will take saved outcome
                  isChecked={topic.resolutionOutcome ? isApproved : true}
                />
              );
            }}
          </Ref>
        );
      })}
    </Box>
  );
};

import { IMeeting } from 'types';
import { Stack, Box } from '@mui/material';
import { MeetingInProgressSidebar } from 'components/Meetings/InProgress/Sidebar';
import { MeetingInProgressForm } from 'components/Meetings/InProgress/Form';
import { ApproveMinutesDialog } from 'components/Meetings/InProgress/Header/ApproveMinutes/Dialog';
import { AgendaApprovalDialog } from 'components/Dialogs/AgendaApprovalDialog';
import { useLiveMeeting } from 'components/Meetings/InProgress/useLiveMeeting';

interface ILiveMeetingProps {
  meeting: IMeeting;
}
export const LiveMeeting: React.FC<ILiveMeetingProps> = (props) => {
  const { meeting } = props;

  const liveMeeting = useLiveMeeting({ meeting });

  return (
    <>
      <ApproveMinutesDialog liveMeeting={liveMeeting} />
      <AgendaApprovalDialog liveMeeting={liveMeeting} />
      <Stack direction="row" sx={{ height: '100vh' }} className="rr-block">
        <Box
          width={{ md: '360px', lg: '430px' }}
          component="nav"
          sx={{
            overflow: 'auto',
            height: '100%',
            display: { xs: 'none', md: 'block' },
            flexShrink: 0,
            backgroundColor: '#EEEEEE',
            borderRight: '1px solid #BDBDBD'
          }}
        >
          <MeetingInProgressSidebar liveMeeting={liveMeeting} />
        </Box>

        <Box sx={{ height: '100%', minWidth: 0, width: '100%' }} data-cy="meeting-in-progress_main-section">
          <MeetingInProgressForm liveMeeting={liveMeeting} />
        </Box>
      </Stack>
    </>
  );
};

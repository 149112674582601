import DashboardIcon from '@mui/icons-material/Dashboard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupsIcon from '@mui/icons-material/Groups';
import NotesIcon from '@mui/icons-material/Notes';
import FolderIcon from '@mui/icons-material/Folder';
import LockIcon from '@mui/icons-material/Lock';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useComponentVisibility, useLocale, useResourceList, useUserShow } from '@koopajs/react';
import { Button, Box, Badge, Stack } from '@mui/material';
import { useRouteMatch, Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IResolution, IMeeting, IMinute } from 'types';
import { useFeatureGate } from '@statsig/react-bindings';

interface INavLinksProps {}

export const NavLinks: React.FC<INavLinksProps> = (props) => {
  const [activeRootPage, setActiveRootPage] = useState<string>('');
  const match = useRouteMatch<{ id: string }>('/:id');

  const location = useLocation();

  useEffect(() => {
    const stateActiveRootPage = (location.state as { activeRootPage?: string })?.activeRootPage;
    if (stateActiveRootPage !== undefined) {
      setActiveRootPage(stateActiveRootPage);
    } else {
      setActiveRootPage(match?.params?.id || '');
    }
  }, [match?.params?.id, location?.state]);

  const [activePath, setActivePath] = useState<string>('');

  useEffect(() => {
    const stateActivePath = (location.state as { activePath?: string })?.activePath;
    if (stateActivePath) {
      setActivePath(stateActivePath);
    } else {
      setActivePath(location.pathname || '');
    }
  }, [location.pathname, location?.state]);

  const { t } = useLocale();
  const { user } = useUserShow();
  const navMenu = useComponentVisibility('navMenu');

  const { resources: meetingsPendingReview } = useResourceList<IMeeting>({
    path: '/meetings-to-review',
    searchParams: {
      size: 50
    }
  });
  const userHasMeetingsToReview = meetingsPendingReview.some((m) =>
    m.reviewers?.some((r) => r.userId === user?.id && !r.latestReview)
  );

  const { resources: minutesToSign } = useResourceList<IMinute>({
    // TODO: is there a less hacky way of getting around redux here?
    path:
      '/minutes?filters[]=' +
      ['NOT _exists_:signaturesCompletedAt', `membersLeftToSign.userId:"${user?.id}"`]
        .map((s) => encodeURIComponent(s))
        .join(',')
  });

  const { resources: resolutionsToSign } = useResourceList<IResolution>({
    path:
      '/resolutions?filters[]=' +
      ['NOT _exists_:signaturesCompletedAt', `membersLeftToSign.userId:"${user?.id}"`]
        .map((s) => encodeURIComponent(s))
        .join(',')
  });

  const { resources: resolutionsToReview } = useResourceList<IResolution>({
    path:
      '/resolutions?filters[]=' +
      ['state.keyword:draft', `reviewers.userId:"${user?.id}"`, `NOT reviews.userId:"${user?.id}"`]
        .map((s) => encodeURIComponent(s))
        .join(',')
  });

  const { value: isDocumentRecordsEnabled } = useFeatureGate('document-records-preview');

  const navLinksData = [
    {
      path: '/',
      id: 'index',
      activeIcon: <DashboardIcon />,
      inactiveIcon: <DashboardIcon />,
      text: t('common:navigation.dashboard'),
      pageNames: ['', 'pending-review', 'to-sign', 'meetings'],
      notificationIcon:
        userHasMeetingsToReview ||
        resolutionsToReview?.length > 0 ||
        resolutionsToSign?.length > 0 ||
        minutesToSign?.length > 0,
      subMenu: [
        {
          path: '/',
          id: 'index',
          text: t('common:navigation.dashboardUpcomingMeetings'),
          highlightRegex: /^\/$/,
          notificationIcon: false
        },
        {
          path: '/pending-review',
          id: 'pending-review',
          text: t('common:navigation.dashboardToAdopt'),
          highlightRegex: /^\/pending-review$/,
          notificationIcon: userHasMeetingsToReview || resolutionsToReview?.length > 0
        },
        {
          path: '/to-sign',
          id: 'to-sign',
          text: t('common:navigation.dashboardToSign'),
          pageNames: ['to-sign'],
          highlightRegex: /^\/to-sign$/,
          notificationIcon: resolutionsToSign?.length > 0 || minutesToSign?.length > 0
        }
      ]
    },
    {
      path: '/past-meetings',
      id: 'past-meetings',
      activeIcon: <CalendarTodayIcon />,
      inactiveIcon: <CalendarTodayIcon />,
      text: t('common:navigation.pastMeetings'),
      pageNames: ['past-meetings']
    },
    {
      path: '/minutes',
      id: 'minutes',
      activeIcon: <AccessTimeIcon />,
      inactiveIcon: <AccessTimeIcon />,
      text: t('common:navigation.minutes'),
      pageNames: ['minutes']
    },
    {
      path: '/resolutions',
      id: 'resolutions',
      activeIcon: <NotesIcon />,
      inactiveIcon: <NotesIcon />,
      text: t('common:navigation.resolutions'),
      pageNames: ['resolutions']
    },
    {
      path: '/document-records',
      id: 'document-records',
      activeIcon: <FolderIcon />,
      inactiveIcon: <FolderIcon />,
      text: t('common:navigation.documents'),
      pageNames: ['document-records'],
      isHidden: !isDocumentRecordsEnabled
    },
    {
      path: '/in-camera',
      id: 'in-camera',
      activeIcon: <LockIcon />,
      inactiveIcon: <LockIcon />,
      text: t('common:navigation.inCamera'),
      pageNames: ['in-camera']
    },
    {
      path: '/organization',
      activeIcon: <GroupsIcon />,
      inactiveIcon: <GroupsIcon />,
      id: 'organization',
      text: t('common:navigation.organization'),
      pageNames: ['organization'],
      subMenu: [
        {
          path: '/organization',
          id: 'organization-groups',
          text: t('common:navigation.organizationGroups'),
          highlightRegex: /^\/organization(\/[a-zA-Z0-9]{26})?$/,
          notificationIcon: false
        },
        {
          path: '/organization/members',
          id: 'organization-members',
          text: t('common:navigation.organizationMembers'),
          highlightRegex:
            /^\/organization\/members(\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})?$/,
          notificationIcon: false
        }
      ]
    }
  ];

  return (
    <Stack spacing={3} role="list">
      {navLinksData
        .filter((data) => !data.isHidden)
        .map((data) => {
          const { path, activeIcon, inactiveIcon, text, pageNames, subMenu, id, notificationIcon } = data;
          const isCurrentPage = pageNames.includes(activeRootPage);
          return (
            <Box key={path} role="listitem">
              <Box>
                <Button
                  sx={{
                    position: 'relative',
                    justifyContent: 'flex-start',
                    py: 1,
                    px: 2.5,
                    backgroundColor: isCurrentPage ? '#b7c8d54f' : '',
                    width: '100%',
                    borderRadius: 0
                  }}
                  component={Link}
                  to={path}
                  onClick={navMenu.setHidden}
                  startIcon={isCurrentPage ? activeIcon : inactiveIcon}
                  data-cy={`navlinks_menu-item_${id}-button`}
                >
                  {notificationIcon && (
                    <Badge variant="dot" color="secondary" sx={{ position: 'absolute', left: '10px' }} />
                  )}
                  {text}
                </Button>
              </Box>
              {subMenu && (
                <Stack
                  role="list"
                  gap={0.5}
                  sx={{ mt: 0.5, display: { lg: isCurrentPage ? 'flex' : 'none' } }}
                >
                  {subMenu.map((item) => {
                    const isCurrentPageItem = item.highlightRegex.test(activePath);

                    return (
                      <Box key={item.path} role="listitem">
                        <Button
                          sx={{
                            py: 1,
                            pr: 2.5,
                            pl: 6,
                            backgroundColor: isCurrentPageItem ? '#b7c8d54f' : '',
                            width: '100%',
                            justifyContent: 'flex-start',
                            borderRadius: 0,
                            '& .MuiButton-endIcon': {
                              ml: 'auto'
                            }
                          }}
                          component={Link}
                          to={item.path}
                          onClick={navMenu.setHidden}
                          startIcon={
                            <>{item.notificationIcon && <Badge variant="dot" color="secondary" />}</>
                          }
                          data-cy={`navlinks_submenu-item_${item.id}-button`}
                        >
                          <Box
                            component="span"
                            sx={{
                              display: 'block'
                            }}
                          >
                            {item.text}
                          </Box>
                        </Button>
                      </Box>
                    );
                  })}
                </Stack>
              )}
            </Box>
          );
        })}
    </Stack>
  );
};

import React, { useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Chip,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { useLocale, Ref, useUserShow, IUseSearchParamsResponse } from '@koopajs/react';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import prettyBytes from 'pretty-bytes';
import { RenderDocumentIcon } from '../RenderDocumentIcon';
import { DateTime } from 'luxon';
import { formatDate } from 'utils/DateTime/formatDate';
import { ICommittee, IDocumentRecord, IDocumentRef } from 'types';
import { AddDocumentRecordButton } from './AddDocumentRecordButton';
import { Link as RouterLink } from 'react-router-dom';
import { getCurrentUserRoles } from 'utils/getCurrentUserRoles';
import { SearchBar } from './SearchBar';

interface IDocumentsTableProps {
  documentRecords: IDocumentRecord[];
  totalCount?: number;
  listState: 'reloading' | 'loading' | 'loaded-error' | 'loaded-empty' | 'loaded-no-search-result' | 'loaded';
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
  canUserUploadDocuments: boolean;
  committeesUserCanUploadTo: ICommittee[];
  addDocumentRecordButtonProps?: {
    defaultValues?: { committeeId?: string; category?: string };
    isCommitteeFieldDisabled?: boolean;
    isCategoryFieldDisabled?: boolean;
  };
  search: IUseSearchParamsResponse;
  isCategoryColumnHidden?: boolean;
  isCommitteeColumnHidden?: boolean;
}

export const DocumentsTable: React.FC<IDocumentsTableProps> = (props) => {
  const {
    documentRecords,
    totalCount,
    listState,
    onPageChange,
    onSizeChange,
    canUserUploadDocuments,
    addDocumentRecordButtonProps,
    committeesUserCanUploadTo,
    search,
    isCategoryColumnHidden,
    isCommitteeColumnHidden
  } = props;

  const { t, locale } = useLocale();
  const keyPrefix = 'DocumentRecords.DocumentsTable';

  const { user } = useUserShow();

  const handlePageChange = useCallback(
    (e: unknown, newPage: number) => {
      onPageChange(newPage);
    },
    [search.searchParams.page]
  );

  const handleSizeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onSizeChange(Number(e.target.value)),
    []
  );

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
      data-cy="documents-table"
      id="documents-table"
    >
      <Typography component="h2" sx={{ fontSize: '20px', fontWeight: 500 }}>
        {t(keyPrefix + '.title')}
      </Typography>
      {/* search bar */}
      {search && <SearchBar search={search} isReloading={listState === 'reloading'} />}

      <Paper sx={{ width: '100%' }} variant="outlined">
        <TableContainer sx={{ borderBottom: '1px solid #E0E0E0' }} data-cy="documents-table_container">
          <Table
            sx={{ minWidth: { xs: 'auto', lg: 650 }, opacity: listState === 'reloading' ? 0.35 : 1 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '1px' }}>{t(keyPrefix + '.type')}</TableCell>
                <TableCell align="left">{t(keyPrefix + '.document')}</TableCell>
                <TableCell align="left">{t(keyPrefix + '.lastUpdated')}</TableCell>
                {!isCategoryColumnHidden && (
                  <TableCell align="left" sx={{ display: { xs: 'none', lg: 'table-cell' } }}>
                    {t(keyPrefix + '.category')}
                  </TableCell>
                )}
                {!isCommitteeColumnHidden && (
                  <TableCell align="left" sx={{ display: { xs: 'none', lg: 'table-cell' } }}>
                    {t(keyPrefix + '.group')}
                  </TableCell>
                )}
                <TableCell
                  align="left"
                  sx={{
                    display: { xs: 'none', lg: 'table-cell' }
                  }}
                >
                  {t(keyPrefix + '.size')}
                </TableCell>
                <TableCell align="left" sx={{ display: { xs: 'none', sm: 'table-cell' } }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {documentRecords?.map((documentRecord) => {
                const lastDocumentId = documentRecord.lastDocumentId;

                const isUserDocumentRecordCreatorRole = getCurrentUserRoles({
                  activeCommitteeMembers: documentRecord.activeCommitteeMembers,
                  userId: user?.id
                }).includes('createDocumentRecords');

                return lastDocumentId ? (
                  <Ref content={`ref:document:${lastDocumentId}`} key={documentRecord.id}>
                    {(item) => {
                      const document = item as IDocumentRef;
                      const {
                        lastDocumentActiveSince,
                        committeeId,
                        lastDocumentTitle,
                        reviewInterval,
                        toReviewAt
                      } = documentRecord;

                      const shouldDocumentBeUpdated =
                        reviewInterval && lastDocumentActiveSince
                          ? DateTime.now() >
                            DateTime.fromISO(lastDocumentActiveSince).toUTC().plus(reviewInterval)
                          : toReviewAt
                          ? DateTime.now() > DateTime.fromISO(toReviewAt).toUTC()
                          : false;

                      const shouldUserUpdateTheDocument =
                        isUserDocumentRecordCreatorRole && shouldDocumentBeUpdated;

                      const linkToDocumentProps = {
                        component: RouterLink,
                        to: `/document-records/${documentRecord?.id}`
                      };

                      const sxTableCellLink = {
                        padding: 0,
                        position: 'relative',
                        height: { xs: shouldUserUpdateTheDocument ? '100px' : '72px', sm: '72px' }
                      };

                      const sxTableCellLinkChild = {
                        display: 'flex',
                        alignItems: 'center',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        padding: 2,
                        width: '100%',
                        color: 'inherit',
                        textDecoration: 'none'
                      };

                      return (
                        <TableRow
                          key={lastDocumentTitle}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                              textDecoration: 'none',
                              color: 'inherit'
                            }
                          }}
                          data-cy="documents-table_row"
                          data-committee-id={documentRecord.committeeId}
                        >
                          <TableCell sx={sxTableCellLink}>
                            <Box
                              sx={sxTableCellLinkChild}
                              // removes tab index for mobile so only the title field is tabbable
                              {...linkToDocumentProps}
                              tabIndex={-1}
                            >
                              <RenderDocumentIcon fileType={document?.attachedFile.format} />
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              maxWidth: { xs: '150px', md: '350px' },
                              minWidth: { xs: 0, sm: '250px' },
                              ...sxTableCellLink
                            }}
                            align="left"
                            component="th"
                            scope="row"
                          >
                            <Box
                              sx={{
                                ...sxTableCellLinkChild,
                                alignItems: { xs: 'flex-start', sm: 'center' },
                                justifyContent: { xs: 'center', sm: 'initial' },
                                flexDirection: { xs: 'column', sm: 'row' }
                              }}
                              {...linkToDocumentProps}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                sx={{
                                  maxWidth: '100%',
                                  minWidth: 0
                                }}
                                className="rr-mask"
                              >
                                <Box
                                  sx={{
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    wordBreak: 'break-word'
                                  }}
                                >
                                  {lastDocumentTitle}
                                </Box>
                              </Typography>

                              {shouldUserUpdateTheDocument && (
                                <Chip
                                  label={t(keyPrefix + '.toUpdateChip')}
                                  color="warning"
                                  sx={{ ml: { xs: 0, sm: 1 }, mt: { xs: 1, sm: 0 } }}
                                />
                              )}
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ ...sxTableCellLink, maxWidth: { sm: '180px' }, width: { sm: '180px' } }}
                          >
                            <Box sx={sxTableCellLinkChild} {...linkToDocumentProps} tabIndex={-1}>
                              {lastDocumentActiveSince &&
                                formatDate({ isoString: lastDocumentActiveSince, locale, isUTC: true })}
                            </Box>
                          </TableCell>
                          {!isCategoryColumnHidden && (
                            <TableCell
                              align="left"
                              sx={{
                                display: { xs: 'none', lg: 'table-cell' },
                                width: '200px',
                                minWidth: '200px',
                                ...sxTableCellLink
                              }}
                            >
                              <Box sx={sxTableCellLinkChild} {...linkToDocumentProps} tabIndex={-1}>
                                {t(`common:documentRecordCategories.${documentRecord.category}`)}
                              </Box>
                            </TableCell>
                          )}
                          {!isCommitteeColumnHidden && (
                            <TableCell
                              align="left"
                              sx={{
                                display: { xs: 'none', lg: 'table-cell' },
                                width: '200px',
                                minWidth: '200px',
                                ...sxTableCellLink
                              }}
                            >
                              <Box sx={sxTableCellLinkChild} {...linkToDocumentProps} tabIndex={-1}>
                                <Box
                                  sx={{
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    wordBreak: 'break-word'
                                  }}
                                >
                                  {useCommitteeName(committeeId)}
                                </Box>
                              </Box>
                            </TableCell>
                          )}
                          <TableCell
                            align="left"
                            sx={{
                              whiteSpace: 'nowrap',
                              display: { xs: 'none', lg: 'table-cell' },
                              width: '1px',
                              ...sxTableCellLink
                            }}
                          >
                            <Box sx={sxTableCellLinkChild} {...linkToDocumentProps} tabIndex={-1}>
                              {prettyBytes(document?.attachedFile.sizeBytes || 0)}
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ display: { xs: 'none', sm: 'table-cell' }, width: '1px' }}
                          >
                            <Button
                              variant={shouldUserUpdateTheDocument ? 'contained' : 'text'}
                              //href={`/document-records/${documentRecord.id}`}
                              component={RouterLink}
                              to={`/document-records/${documentRecord?.id}`}
                              data-cy="documents-table_row_open-button"
                            >
                              {t(keyPrefix + '.viewButtonLabel')}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    }}
                  </Ref>
                ) : null;
              })}
            </TableBody>
          </Table>
          {canUserUploadDocuments ? (
            <Box sx={documentRecords.length > 0 ? { borderTop: '1px solid rgb(224, 224, 224)' } : {}}>
              <AddDocumentRecordButton
                isDropTextVisible={['loaded-empty', 'loaded-no-search-result'].includes(listState)}
                sxContainer={{
                  border: '2px dashed #8187A9',
                  mx: 3,
                  my: 1,
                  py: 1.75,
                  px: 3
                }}
                committees={committeesUserCanUploadTo}
                {...addDocumentRecordButtonProps}
              />
            </Box>
          ) : ['loaded-empty', 'loaded-no-search-result'].includes(listState) ? (
            <Box sx={{ m: 3, textAlign: 'center' }}>{t(keyPrefix + '.noDocumentsText')}</Box>
          ) : null}
        </TableContainer>
        <TablePagination
          sx={{
            '& .MuiInputBase-root': {
              marginRight: { xs: 1, sm: '32px' }
            },
            '& .MuiTablePagination-actions': {
              marginLeft: { xs: 1, sm: '20px' },
              '& .MuiIconButton-root': {
                padding: { xs: 0, sm: '8px' }
              }
            }
          }}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalCount || 0}
          rowsPerPage={search.searchParams.size || 10}
          page={search.searchParams.page ? search.searchParams.page : 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleSizeChange}
          SelectProps={{
            native: true
          }}
          // eslint-disable-next-line react/jsx-no-bind
          labelDisplayedRows={({ from, to, count }) => {
            const transOptions = { from, to, count };

            return count !== -1
              ? t('common:TablePagination.labelDisplayedRowsCount', transOptions)
              : t('common:TablePagination.labelDisplayedRowsMoreThan', transOptions);
          }}
          labelRowsPerPage={t('common:TablePagination.labelRowsPerPage')}
        />
      </Paper>
    </Box>
  );
};

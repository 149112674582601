import { Box, IconButton, Stack } from '@mui/material';
import { useTimer } from 'react-timer-hook';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import { useLocale } from '@koopajs/react';

interface ICountdownTimerProps {
  timeSeconds: number;
}

export const CountdownTimer: React.FC<ICountdownTimerProps> = (props) => {
  const { timeSeconds } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.CountdownTimer';

  const time = new Date();
  time.setSeconds(time.getSeconds() + timeSeconds);

  const { totalSeconds, seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({
    expiryTimestamp: time
  });

  const warningTime = timeSeconds * 0.2;

  const isTimeAlmostUp = totalSeconds <= warningTime;

  return (
    <Stack direction="row" sx={{ textAlign: 'right' }}>
      <Box
        sx={{
          color: isTimeAlmostUp ? '#c62828' : '',
          fontVariantNumeric: 'tabular-nums',
          fontSize: '48px',
          lineHeight: 1
        }}
      >
        {Boolean(days) && (
          <>
            <Box component="span" sx={{ display: 'inline-block' }}>
              {days}:
            </Box>
          </>
        )}
        {Boolean(hours) && (
          <>
            <Box component="span" sx={{ display: 'inline-block' }}>
              {hours}:
            </Box>
          </>
        )}
        <Box component="span" sx={{ display: 'inline-block' }}>
          {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
        </Box>
      </Box>
      <Stack sx={{ justifyContent: 'center', pl: '14px' }}>
        {totalSeconds === 0 ? (
          // hidden icon for correct spacing
          <IconButton sx={{ p: 0.5, visibility: 'hidden' }}>
            <PauseIcon sx={{ fontSize: '16px' }} />
          </IconButton>
        ) : isRunning ? (
          <IconButton onClick={pause} aria-label={t(keyPrefix + '.pause')} sx={{ p: 0.5 }}>
            <PauseIcon sx={{ fontSize: '16px' }} />
          </IconButton>
        ) : (
          <IconButton onClick={resume} aria-label={t(keyPrefix + '.resume')} sx={{ p: 0.5 }}>
            <PlayArrowIcon sx={{ fontSize: '16px' }} />
          </IconButton>
        )}
        <IconButton
          onClick={() => {
            const time = new Date();
            time.setSeconds(time.getSeconds() + timeSeconds);
            restart(time);
          }}
          aria-label={t(keyPrefix + '.restart')}
          sx={{ p: 0.5 }}
        >
          <ReplayIcon sx={{ fontSize: '16px' }} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

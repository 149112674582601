import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import { useLocale } from '@koopajs/react';
import girlyogaimage from 'assets/girlyogaimage.svg';
interface IEmptyCommitteeStateProps {
  imageStyle?: React.CSSProperties;
}

export const EmptyCommitteeState: React.FC<IEmptyCommitteeStateProps> = (props) => {
  const { imageStyle } = props;

  const { t } = useLocale();
  const keyPrefix = 'OrganizationCommittee.EmptyCommittee';

  return (
    <>
      <Box sx={{ padding: { xs: '6px', sm: '18px' } }}>
        <Container sx={{ textAlign: 'center', mt: 4 }}>
          <Box>
            <Typography variant="h5" component="h5">
              {t(keyPrefix + '.title')}
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              {t(keyPrefix + '.writeToUs')}&nbsp;
              <a href="mailto:support@usepanorama.com" style={{ color: '#0c79d0', textDecoration: 'none' }}>
                support@usepanorama.com
              </a>
              &nbsp;
              {t(keyPrefix + '.toAddMembers')}
            </Typography>
          </Box>

          <Box>
            <img src={girlyogaimage} srcSet="" loading="lazy" style={{ width: '80%', ...imageStyle }} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

import { Loading } from '@koopajs/mui';
import { useLocale, useParamsKey, useResourceShow } from '@koopajs/react';
import { EmptyState } from 'components/EmptyState';
import { ResolutionsBook } from 'components/ResolutionStates/ResolutionsBook';
import { IResolution } from 'types';
import { Button, Stack } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { WrittenResolution } from 'components/ResolutionStates/WrittenResolution';
import { DelayComponentRender } from 'components/DelayComponentRender';
import { ToSignPageResolution } from 'components/Dashboard/ToSign/ToSignPageResolution';
import { extractErrorMessage } from 'utils/extractErrorMessage';

export const ResolutionStateConditions: React.FC = () => {
  const resolutionId = useParamsKey('id');

  const location = useLocation();

  const { t } = useLocale();

  const {
    resource: resolution,
    isProcessing,
    errorMessage
  } = useResourceShow<IResolution>({
    path: '/resolutions',
    id: resolutionId
  });

  const extractedErrorMessage = extractErrorMessage(errorMessage);
  // resolutions book
  if (!resolutionId) return <ResolutionsBook />;

  if (!resolution) {
    if (isProcessing) {
      return (
        <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
          <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
        </Stack>
      );
    } else if (errorMessage && errorMessage !== 'Error 404: Not Found') {
      return (
        <DelayComponentRender
          delay={1250}
          component={
            // TODO: this error message should probably use i18n and be more generic so it is also in French
            <EmptyState
              title={extractedErrorMessage}
              // description={t('ResolutionStatesBase.EmptyState.description')}
              button={
                <Button component={RouterLink} to="/" variant="contained" disableElevation>
                  {t('NotFound.EmptyState.goToDashboardButton')}
                </Button>
              }
            />
          }
        />
      );
    } else {
      return (
        <DelayComponentRender
          delay={1250}
          component={
            <EmptyState
              title={t('ResolutionStatesBase.EmptyState.title')}
              description={t('ResolutionStatesBase.EmptyState.description')}
              button={
                <Button component={RouterLink} to="/" variant="contained" disableElevation>
                  {t('NotFound.EmptyState.goToDashboardButton')}
                </Button>
              }
            />
          }
        />
      );
    }
  }

  if (resolution.state === 'sealed') {
    const isSignPage = location.pathname.includes('/sign');

    if (isSignPage) {
      if (resolution.attachedToMeetingTopic) return <ToSignPageResolution resolution={resolution} />;
      if (resolution.attachedToEmail) return <WrittenResolution resolution={resolution} />;
    }

    return <ResolutionsBook resolution={resolution} isResolutionProcessing={isProcessing} />;
  } else if (resolution.state === 'votedOn') {
    return <ResolutionsBook resolution={resolution} isResolutionProcessing={isProcessing} />;
  } else if (resolution.state === 'draft' || resolution.state === 'attached') {
    return <WrittenResolution resolution={resolution} />;
  }

  if (isProcessing) {
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );
  }

  return null;
};

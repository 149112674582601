import { useCallback, useState, useRef, useEffect } from 'react';
import { ButtonProps, MenuItem, Divider, Button, Menu, Stack } from '@mui/material';
import { useLocale, useResourceList, useUserShow } from '@koopajs/react';
import { DateTime } from 'luxon';
import { CreateMeetingDialog } from './Dialogs/CreateMeetingDialog';
import { CreateWrittenResolutionDialog } from './Dialogs/CreateWrittenResolutionDialog';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { ICommittee, ICommitteeMember } from 'types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Add as AddIcon } from '@mui/icons-material';

interface ICreateProps {
  sxButton?: SxProps<Theme>;
  otherButtonProps?: ButtonProps;
  isDividerVisible?: boolean;
  sxDivider?: SxProps<Theme>;
}

export const CreateButton: React.FC<ICreateProps> = (props) => {
  const { isDividerVisible, sxDivider, sxButton, otherButtonProps } = props;
  const [dialogOpen, setDialogOpen] = useState<'createMeeting' | 'createWrittenResolution' | ''>('');

  const { user } = useUserShow();
  const today = DateTime.now().toISODate();

  const { t } = useLocale();

  const { resources: committees } = useResourceList<ICommittee>({
    path: '/committees',
    useCache: true
  });

  const committeesTakingResolutionsIds = committees.filter((c) => c.isTakingResolutions).map((c) => c.id);

  const { resources: currentUserCreateItemsCommitteeMembers } = useResourceList<ICommitteeMember>({
    path: '/committee-members',
    searchParams: {
      filters: [
        `userId:"${user?.id}"`,
        '(roles.role:"createMeetings" OR roles.role:"createResolutionProjects")',
        `startAt:[* TO ${today}]`,
        `(endAt:[${today} TO *] OR (NOT _exists_:endAt))`
      ]
    }
  });

  const isUserMeetingCreator = currentUserCreateItemsCommitteeMembers.some((cm) =>
    cm.roles?.some((role) => role.role === 'createMeetings')
  );

  const isUserResolutionCreator = currentUserCreateItemsCommitteeMembers
    .filter((cm) => cm.roles?.some((role) => role.role === 'createResolutionProjects'))
    // check that committee can take resolutions
    .some((cm) => committeesTakingResolutionsIds.includes(cm.committeeId));

  const closeDialog = useCallback(() => {
    setDialogOpen('');
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClickCreateButton = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelectOption = useCallback((item: 'createMeeting' | 'createWrittenResolution') => {
    return () => {
      setDialogOpen(item);
      handleCloseMenu();
    };
  }, []);
  const [createButtonWidth, setCreateButtonWidth] = useState(0);
  const createButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const addCreateButton = createButtonRef.current;
    if (addCreateButton) {
      const resizeObserver = new ResizeObserver(() => {
        setCreateButtonWidth(addCreateButton.offsetWidth);
      });
      resizeObserver.observe(addCreateButton);
      return () => resizeObserver.disconnect();
    }
  }, []);

  return (
    <>
      {isUserMeetingCreator && (
        <CreateMeetingDialog isOpen={dialogOpen === 'createMeeting'} onClose={closeDialog} />
      )}
      {isUserResolutionCreator && (
        <CreateWrittenResolutionDialog
          isOpen={dialogOpen === 'createWrittenResolution'}
          onClose={closeDialog}
        />
      )}

      {isUserMeetingCreator && isUserResolutionCreator ? (
        <>
          <Button
            aria-controls={menuOpen ? 'create-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
            variant="contained"
            onClick={handleClickCreateButton}
            data-cy="create-button"
            ref={createButtonRef}
            sx={{ ...sxButton, p: 0 }}
            {...otherButtonProps}
          >
            <Stack flexDirection="row" gap="6px" sx={{ flex: 1, p: '8px 12px', textAlign: 'left' }}>
              <AddIcon />
              {t('Components.CreateButton.labelCreate')}
            </Stack>
            <Stack sx={{ borderLeft: '1px solid #3F51B5', p: '8px 10px' }}>
              <ArrowDropDownIcon />
            </Stack>
          </Button>
          <Menu
            id="create-menu"
            MenuListProps={{
              'aria-labelledby': 'create-button'
            }}
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleCloseMenu}
            PaperProps={{ style: { minWidth: `${createButtonWidth}px` } }}
          >
            {isUserMeetingCreator && (
              <MenuItem
                onClick={handleSelectOption('createMeeting')}
                disableRipple
                data-cy="create-button_create-meeting"
              >
                {t('Components.CreateButton.labelCreateMeeting')}
              </MenuItem>
            )}
            {isUserResolutionCreator && (
              <MenuItem
                onClick={handleSelectOption('createWrittenResolution')}
                disableRipple
                data-cy="create-button_create-resolution"
              >
                {t('Components.CreateButton.labelCreateResolution')}
              </MenuItem>
            )}
          </Menu>
        </>
      ) : isUserMeetingCreator ? (
        <Button
          onClick={handleSelectOption('createMeeting')}
          variant="contained"
          startIcon={<AddIcon />}
          sx={sxButton}
          data-cy="create-meeting-button"
          {...otherButtonProps}
        >
          {t('Components.CreateButton.labelCreateMeeting')}
        </Button>
      ) : isUserResolutionCreator ? (
        <Button
          onClick={handleSelectOption('createWrittenResolution')}
          variant="contained"
          startIcon={<AddIcon />}
          sx={sxButton}
          data-cy="create-meeting-button"
          {...otherButtonProps}
        >
          {t('Components.CreateButton.labelCreateResolution')}
        </Button>
      ) : null}
      {(isUserMeetingCreator || isUserResolutionCreator) && isDividerVisible && <Divider sx={sxDivider} />}
    </>
  );
};

import { Box, Typography, Alert } from '@mui/material';
import { BodyField } from './Fields/BodyField';
import { IUseLiveMeetingResponse } from '../useLiveMeeting';
import { MeetingInProgressFormController } from './FormController';
import { RecordVote } from '../Header/RecordVote';
import { ResourcePicker } from 'components/ResourcePicker';
import { TopicTitle } from '../Header/TopicTitle';

interface INominationsFields {
  liveMeeting: IUseLiveMeetingResponse;
}

export const NominationsFields: React.FC<INominationsFields> = (props) => {
  const { presentParticipants, t } = props.liveMeeting;

  const keyPrefix = 'MeetingStateInProgress.NominationsFields';

  return (
    <>
      {presentParticipants.length > 0 && (
        <MeetingInProgressFormController liveMeeting={props.liveMeeting}>
          <TopicTitle liveMeeting={props.liveMeeting} />
          <Alert severity="info" color="info" variant="outlined" sx={{ backgroundColor: '#f6fbfa' }}>
            {t(keyPrefix + '.alert')}
          </Alert>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: { xs: 0.5, md: 4 }
            }}
          >
            <Box sx={{ width: '100%' }} data-cy="meeting-in-progress_select-president-field">
              <Typography variant="caption" sx={{ ml: 0.5 }}>
                {t(keyPrefix + '.labelPresident')}
              </Typography>

              <ResourcePicker
                i18n={{ keyPrefix: keyPrefix + '.ResourcePickerPresident' }}
                name="meetingPresidentId"
                resources={presentParticipants.map((user) => ({ id: user.id, label: user.username }))}
                isOpenOnFocus
                isDisabledCloseOnSelect
                translatedLabel=""
                translatedLabelMultipleSelected=""
                translatedErrorText=""
                sx={{ mt: 1 }}
              />
            </Box>
            <Box sx={{ width: '100%' }} data-cy="meeting-in-progress_select-secretary-field">
              <Typography variant="caption" sx={{ ml: 0.5 }}>
                {t(keyPrefix + '.labelSecretary')}
              </Typography>
              <ResourcePicker
                i18n={{ keyPrefix: keyPrefix + '.ResourcePickerSecretary' }}
                name="meetingSecretaryId"
                resources={presentParticipants.map((user) => ({ id: user.id, label: user.username }))}
                isOpenOnFocus
                isDisabledCloseOnSelect
                translatedLabel=""
                translatedLabelMultipleSelected=""
                translatedErrorText=""
                sx={{ mt: 1 }}
              />
            </Box>
          </Box>
          <RecordVote liveMeeting={props.liveMeeting} />
          <BodyField variant="small" />
        </MeetingInProgressFormController>
      )}
    </>
  );
};

import { IMinute } from 'types';
import { Stack, Typography, Box, Button, Tooltip, Chip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Person as PersonIcon } from '@mui/icons-material';
import { useLocale, User } from '@koopajs/react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { List, ListItem } from '@mui/material';
import { calculateMissingSignatureCount } from 'utils/calculateMissingSignatureCount';
import { useCommitteeName } from 'components/hooks/useCommitteeName';

interface IToSignItemMinuteProps {
  minute: IMinute;
  isSignable?: boolean;
}

export const ToSignItemMinute: React.FC<IToSignItemMinuteProps> = (props) => {
  const { minute, isSignable } = props;

  const { t } = useLocale();
  const keyPrefix = 'ToSign.ToSignItem';

  const itemToSign = minute;

  const minutesType = useCommitteeName(itemToSign?.committeeId as string);

  const toSignUrl = `/minutes/${itemToSign.id}/sign`;

  return (
    <>
      <Stack
        direction={{ xs: 'column-reverse', sm: 'row' }}
        sx={{ justifyContent: 'space-between', marginBottom: 2, alignItems: { sm: 'center' } }}
        gap={{ xs: 2, sm: 0 }}
      >
        <Typography sx={{ fontSize: '18px' }} variant="h3">
          {itemToSign.title}
        </Typography>

        <Chip
          label={t('common:boardMinutes')}
          sx={{ mr: 'auto', ml: { sm: 6 }, backgroundColor: '#EDF0FF' }}
        />

        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}
        >
          <Button
            variant={isSignable ? 'contained' : 'text'}
            component={RouterLink}
            to={{ pathname: toSignUrl, state: { activePath: '/to-sign', activeRootPage: '' } }}
            sx={{ whiteSpace: 'nowrap' }}
            data-cy="minutes-to-sign-card_cta"
          >
            {isSignable ? t(`${keyPrefix}.signMinutesButtonLabel`) : t(`${keyPrefix}.viewMinutesButtonLabel`)}
          </Button>
        </Box>
      </Stack>
      <Box sx={{ opacity: '0.6', mt: { xs: 2 } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-start', md: 'center' },
            gap: '16px'
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <CalendarTodayIcon fontSize="small" />
            <Typography variant="body2">
              {itemToSign.startDateTime ? (
                <DateTimeStartEnd
                  isoStringDate={itemToSign.startDateTime}
                  dateFormat="DATE_FULL_NO_YEAR"
                  separator={` ${t('common:at')} `}
                  isoStringStart={itemToSign.startDateTime}
                />
              ) : (
                <i>{t('common:noDate')}</i>
              )}
            </Typography>
          </Stack>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Typography sx={{ display: { xs: 'none', md: 'inline' } }}>&#183;</Typography>
            <Typography variant="body2">
              {itemToSign?.location ? (
                t(`common:locationType.${itemToSign?.location?.type}`)
              ) : (
                <i>{t('common:noLocation')}</i>
              )}
            </Typography>
            {itemToSign?.committeeId && (
              <>
                <Typography sx={{ mr: 2 }}>&#183;</Typography>
                <Typography variant="body2">{minutesType}</Typography>
              </>
            )}
          </Box>

          {itemToSign.membersLeftToSign && itemToSign.membersLeftToSign?.length > 0 && (
            <Tooltip
              title={
                <>
                  <List>
                    {itemToSign.membersLeftToSign?.map((member) => {
                      return (
                        <User key={member.userId} id={member.userId}>
                          {(profile) => {
                            return (
                              <>
                                <ListItem disableGutters key={profile?.id}>
                                  <>
                                    <PersonIcon color="action" />
                                    <Typography>
                                      {profile?.username} {!member.isOptional && <>*</>}
                                    </Typography>
                                  </>
                                </ListItem>
                              </>
                            );
                          }}
                        </User>
                      );
                    })}
                  </List>
                </>
              }
            >
              <Typography variant="body2" sx={{ display: 'flex', ml: { md: 'auto' } }}>
                <WarningAmberIcon fontSize="small" sx={{ mr: 1 }} />
                {t(`${keyPrefix}.signaturesMissing`, {
                  count: calculateMissingSignatureCount(itemToSign)
                })}
              </Typography>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'column',
          alignItems: 'flex-start',
          mt: 2
        }}
      >
        <Button
          variant={isSignable ? 'contained' : 'text'}
          to={{ pathname: toSignUrl, state: { activePath: '/to-sign', activeRootPage: '' } }}
          component={RouterLink}
          // data-cy="minutes-to-sign-card_cta"
        >
          {isSignable ? t(`${keyPrefix}.signMinutesButtonLabel`) : t(`${keyPrefix}.viewMinutesButtonLabel`)}
        </Button>
      </Box>
    </>
  );
};

import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import { ITopic } from 'types';
import { User } from '@koopajs/mui';
import { useLocale } from '@koopajs/react';
import { IUserPublicProfile } from '@koopajs/app';

interface INominationsFieldProps {
  topic: ITopic;
  isEditable?: boolean;
  users?: IUserPublicProfile[];
  isReview?: boolean;
}

export const NominationsField: React.FC<INominationsFieldProps> = (props) => {
  const { topic, isReview } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.TopicCard.NominationsField';

  return (
    <>
      {(topic.meetingPresidentId || topic.meetingSecretaryId) && (
        <Stack
          direction="row"
          sx={{ backgroundColor: isReview ? '' : '#F3F3F3', borderRadius: '4px', pt: 1 }}
        >
          {topic.meetingPresidentId && (
            <Box width="50%">
              <Typography variant="caption">{t(keyPrefix + '.assemblyPresident')}</Typography>
              <List>
                <ListItem disableGutters>
                  <User id={topic.meetingPresidentId} variant="text-only" />
                </ListItem>
              </List>
            </Box>
          )}
          {topic.meetingSecretaryId && (
            <Box width="50%">
              <Typography variant="caption">{t(keyPrefix + '.assemblySecretary')}</Typography>
              <List>
                <ListItem disableGutters>
                  <User id={topic.meetingSecretaryId} variant="text-only" />
                </ListItem>
              </List>
            </Box>
          )}
        </Stack>
      )}
    </>
  );
};

import React from 'react';
import { Button } from '@mui/material';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

const IconWrapper = ({
  icon,
  size
}: {
  icon: React.ReactElement;
  size?: 'small' | 'large';
}): React.ReactElement | null => {
  const width = size === 'small' ? '18px' : '24px';
  const height = size === 'small' ? '18px' : '24px';

  return icon ? React.cloneElement(icon, { width, height }) : null;
};

export const RenderButtonLink: React.FC<{
  to: LinkProps['to'];
  label: React.ReactNode;
  icon?: React.ReactElement;
  size?: 'small' | 'large';
  disabled?: boolean;
}> = ({ to, label, icon, disabled, size = 'small' }) => {
  return (
    <Button
      disabled={disabled}
      size={size}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        textDecoration: 'none',
        textTransform: 'none',
        color: 'secondary.light',
        px: size === 'small' ? '10px' : '22px',
        py: size === 'small' ? '4px' : '8px',
        borderRadius: '4px',
        border: '1px solid',
        borderColor: 'secondary.light',
        '&:hover': {
          borderColor: 'secondary.light'
        }
      }}
      variant="outlined"
      component={RouterLink}
      to={to}
      startIcon={icon ? <IconWrapper icon={icon} size={size} /> : null}
    >
      {label}
    </Button>
  );
};

import { Box, Stack, Typography, Button, Tooltip } from '@mui/material';
import { IApprovedTopic } from 'types';
import { useLocale } from '@koopajs/react';
import { UserList } from 'components/UserList';
import {
  Person as PersonIcon,
  FilePresent as FilePresentIcon,
  AccessTime as AccessTimeIcon,
  Lock as LockIcon
} from '@mui/icons-material';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { formatDate } from 'utils/DateTime/formatDate';
import { RenderButtonLink } from 'components/RenderButtonLink';
interface IInCameraCardProps {
  inCamera: IApprovedTopic;
}

export const InCameraCard: React.FC<IInCameraCardProps> = (props) => {
  const { inCamera } = props;

  const { t, locale } = useLocale();
  const keyPrefix = 'InCamera.InCameraCard';

  const history = useHistory();

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          padding: '24px',
          borderRadius: '4px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          my: 2,
          border: '1px solid #dddddd'
        }}
        data-cy="in-camera-card"
        data-committee-id={inCamera.meetingCommitteeId}
      >
        <>
          {/* Card header */}
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box data-cy="in-camera-card_header">
              <Typography
                variant="body1"
                component="h3"
                fontSize="16px"
                data-cy="in-camera-card_meeting-title"
              >
                {inCamera.meetingTitle}
              </Typography>
            </Box>
            <Button
              startIcon={<LockIcon />}
              component={RouterLink}
              to={{
                pathname: `/in-camera/${inCamera.id}`,
                search: history.location?.search
              }}
              data-cy="in-camera-card_view-button"
            >
              {t(keyPrefix + '.viewButtonLabel')}
            </Button>
          </Stack>
          {/* Authorized people */}
          {inCamera.visibleBy && inCamera.visibleBy.length > 0 && (
            <Tooltip title={<UserList userIds={inCamera.visibleBy} icon={<PersonIcon color="action" />} />}>
              <Box sx={{ maxWidth: '400px' }}>
                <Typography variant="body1" color="action" fontSize="14px">
                  {t(keyPrefix + '.authorizedPeople', { count: inCamera.visibleBy.length })}
                </Typography>
              </Box>
            </Tooltip>
          )}
          {/* Documents count*/}
          {inCamera.documentsIds && inCamera.documentsIds.length > 0 ? (
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <FilePresentIcon />
              <Typography>{t(keyPrefix + '.documents', { count: inCamera.documentsIds.length })}</Typography>
            </Stack>
          ) : null}
          {/* Held on */}
          {inCamera.meetingStartDateTime && (
            <Typography variant="body2" sx={{ opacity: '0.6', mt: 1 }}>
              {t(keyPrefix + '.heldOnDate', {
                date: formatDate({
                  isoString: inCamera.meetingStartDateTime,
                  locale,
                  format: 'DATE_FULL'
                })
              })}
            </Typography>
          )}
        </>
      </Box>
    </>
  );
};

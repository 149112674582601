import { useLocale, useSearchParams } from '@koopajs/react';
import { Box, Button, Menu, Typography } from '@mui/material';
import { useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import React, { useCallback, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export interface ISortByFacetProps {
  sortOptions: { key: string; direction: 'asc' | 'desc'; label: string }[];
  testId?: string;
}

export const SortByFacet: React.FC<ISortByFacetProps> = (props) => {
  const { sortOptions, testId } = props;
  const search = useSearchParams();

  const { t } = useLocale();

  const [sortedBy, setSortedBy] = useState<string | undefined>(undefined);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    search.setSearchParams({ sort: sortedBy ? `${sortedBy}:${sortDirection}` : undefined });
  }, [sortedBy, sortDirection]);

  const handleSortChange = useCallback(
    (key: string, direction: 'asc' | 'desc') => {
      if (sortedBy === key && direction === sortDirection) {
        // setSortDirection(undefined);
        setSortedBy(undefined);
      } else {
        setSortDirection(direction);
        setSortedBy(key);
      }
    },
    [sortedBy, sortDirection]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const menuOpen = Boolean(anchorEl);

  const handleClickSelectGroup = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelectOption = (key: string, direction?: 'asc' | 'desc'): (() => void) => {
    return () => {
      handleSortChange(key, direction || 'asc');
      handleCloseMenu();
    };
  };

  const [buttonWidth, setButtonWidth] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const button = buttonRef.current;
    if (button) {
      const resizeObserver = new ResizeObserver(() => {
        setButtonWidth(button.offsetWidth);
      });
      resizeObserver.observe(button);
      return () => resizeObserver.disconnect();
    }
  }, []);

  return (
    <>
      <Button
        aria-controls={menuOpen ? 'sort-by-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        variant="text"
        onClick={handleClickSelectGroup}
        data-cy={testId}
        ref={buttonRef}
        sx={{
          color: 'inherit',
          typography: 'body2',
          textTransform: 'none'
        }}
        id="sort-by-button"
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2"> {t('Components.Facets.SortBy.title')}</Typography>
          <KeyboardArrowDownIcon fontSize="small" />
        </Box>
      </Button>
      <Menu
        id={'sort-by-menu'}
        MenuListProps={{
          'aria-labelledby': 'sort-by-button'
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        PaperProps={{ style: { minWidth: `${buttonWidth}px` }, elevation: 0, variant: 'outlined' }}
      >
        {sortOptions?.map((option) => {
          const { key, direction, label } = option;

          return (
            <MenuItem
              key={option.label}
              onClick={handleSelectOption(key, direction)}
              sx={{
                backgroundColor: sortDirection === direction && sortedBy === key ? '#edf0ff' : 'inherit',
                typography: 'body2'
              }}
            >
              {label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

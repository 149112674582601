import { Typography, Box, Stack, Link as MuiLink } from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import { useLocale } from '@koopajs/react';
import { ITopic, IActiveCommitteeMembers } from 'types';
import { IUser } from '@koopajs/app';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { UsersCheckboxesRows } from 'components/UsersCheckboxesRows';
import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { getCommitteeMembersTitlesObj } from 'utils/getCommitteeMembersTitlesObj';

interface IInCameraFieldProps {
  userHasEditAccess?: boolean;
  isEditable: boolean;
  isReview?: boolean;
  handleSetIsEditable?: () => void;
  topic: ITopic;
  users: IUser[];
  activeCommitteeMembers?: IActiveCommitteeMembers[];
}

export const InCameraField: React.FC<IInCameraFieldProps> = (props) => {
  const {
    isEditable,
    userHasEditAccess,
    handleSetIsEditable,
    isReview,
    topic,
    users,
    activeCommitteeMembers
  } = props;

  const { t } = useLocale();
  const keyPrefix = 'Components.TopicCard.InCameraField';

  const committeeMembersTitlesObj = getCommitteeMembersTitlesObj(activeCommitteeMembers);

  return (
    <>
      {/* LINK TO IN CAMERA DIALOG - CODE commented out for the release - to add back and fix it later*/}
      {/* {isReview &&
        topic.visibleBy?.some(() => currentUser?.id) &&
        (topic.documentsIds?.length || topic.notes) && (
          <Box className="noprint">
            <Button
              component={Link}
              to={`/in-camera/${topic.id}`}
              sx={{
                textTransform: 'none',
                color: 'secondary.light',
                py: 0.2,
                px: 1,
                mt: 1
              }}
              endIcon={<OpenInNewIcon fontSize="inherit" />}
            >
              {t(keyPrefix + '.viewPrivateContent')}
            </Button>
          </Box>
        )} */}

      {/* PERMISSIONS */}

      {userHasEditAccess && !isEditable && !isReview && (
        <Box>
          {topic.visibleBy && topic.visibleBy.length > 0 ? (
            <Stack direction="row" spacing={2} alignItems="flex-end" sx={{ ml: 1 }}>
              {topic.visibleBy.length === 1 ? (
                <PersonIcon color="action" />
              ) : (
                <PeopleAltIcon color="action" />
              )}
              <Typography>{t(keyPrefix + '.permissionsSet', { count: topic.visibleBy.length })}</Typography>
            </Stack>
          ) : (
            <MuiLink
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'secondary.light',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: '400',
                textDecoration: 'none'
              }}
              onClick={handleSetIsEditable}
            >
              <WarningAmberIcon sx={{ mr: 1 }} />
              {t(keyPrefix + '.selectPermissions')}
            </MuiLink>
          )}
        </Box>
      )}

      {isEditable && (
        <>
          <Stack direction="row" alignItems="center" spacing={2}>
            <PeopleAltIcon color="action" />
            <Typography variant="body1">{t(keyPrefix + '.permissions')}</Typography>
          </Stack>
          <Typography variant="caption">
            <Trans i18nKey={keyPrefix + '.selectPeople-html'} t={t} />
          </Typography>

          {/* PERMISSIONS */}
          <Box sx={{ pt: '14px' }}>
            <UsersCheckboxesRows
              usersIds={users.map((u) => u.id)}
              defaultValues={topic.visibleBy}
              formName="visibleBy"
              isCheckboxLabelAvatar
              committeeMembersTitles={committeeMembersTitlesObj}
            />
          </Box>
        </>
      )}
    </>
  );
};

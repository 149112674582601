import { useLocale } from '@koopajs/react';
import { Box, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { ICommitteeMember } from 'types';
import { formatDate } from 'utils/DateTime/formatDate';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

interface IMandateStartEndDatesProps {
  committeeMember: ICommitteeMember;
}

export const MandateStartEndDates: React.FC<IMandateStartEndDatesProps> = (props) => {
  const { committeeMember } = props;

  const { locale, t } = useLocale();
  const keyPrefix = 'Organization.MandateStartEndDates';

  let textColor: string | undefined = undefined;
  let icon: React.ReactNode | undefined = undefined;
  let tooltipText: string | undefined = undefined;

  const dateTimeNow = DateTime.now().toUTC();
  const dateSixtyDaysFromNow = DateTime.now().toUTC().plus({ day: 60 }).startOf('day');

  const dateMandateExpiryEndOfDay = DateTime.fromISO(committeeMember?.endAt || '')
    .toUTC()
    .endOf('day');

  if (committeeMember.endAt && dateTimeNow > dateMandateExpiryEndOfDay) {
    textColor = '#F44336';
    icon = <ErrorOutlineOutlinedIcon sx={{ verticalAlign: 'bottom', fontSize: '20px' }} />;
    tooltipText = t(keyPrefix + '.mandateExpiredWarning');
  } else if (committeeMember.endAt && dateSixtyDaysFromNow > dateMandateExpiryEndOfDay) {
    textColor = '#ED6C02';
    icon = <ReportProblemOutlinedIcon sx={{ verticalAlign: 'bottom', fontSize: '20px' }} />;
    tooltipText = t(keyPrefix + '.mandateExpiringWarning');
  }

  return (
    <Tooltip title={tooltipText}>
      <Box component="span" data-cy="mandate-start-end-dates" sx={{ color: textColor }}>
        {icon}
        {icon && ' '}
        {formatDate({ isoString: committeeMember.startAt, locale, isUTC: true })} -{' '}
        {committeeMember.endAt ? (
          formatDate({
            isoString: committeeMember.endAt,
            format: 'DATE_FULL',
            locale,
            isUTC: true
          })
        ) : (
          <span style={{ color: 'gray' }}>∅</span>
        )}
      </Box>
    </Tooltip>
  );
};

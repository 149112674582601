import { Alert, Link as MuiLink } from '@mui/material';
import { useLocale } from '@koopajs/react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

export const ReferralBanner: React.FC = () => {
  const { t } = useLocale();

  return (
    <Alert
      sx={{
        borderRadius: '0',
        borderWidth: '0 0 1px 0',
        borderStyle: 'solid',
        borderColor: '#CDD1E5',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: '#EDF0FF',
        py: 0
      }}
      icon={<VolunteerActivismIcon sx={{ color: '#8187A9', fontSize: '18px' }} />}
    >
      <MuiLink
        href={t('Header.recommendPanoramaUrl')}
        sx={{
          alignItems: 'center',
          textDecoration: 'none',
          color: 'secondary.light'
        }}
        target="_blank"
        rel="noreferrer"
      >
        {t('Header.recommendPanorama')}{' '}
        <OpenInNewIcon fontSize="small" sx={{ fontSize: '18px', verticalAlign: 'text-bottom' }} />
      </MuiLink>
    </Alert>
  );
};

import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocale, useParamsKey, useResourceShow, useUserShow } from '@koopajs/react';
import {
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Stack,
  Button,
  Paper,
  Avatar,
  Divider,
  Chip
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { MeetingStateChip } from 'components/Meetings/MeetingStateChip';
import { PageContainer } from 'components/temp/PageContainer';
import { IMeeting } from 'types';
import { MeetingViewHeaderCard } from 'components/Meetings/View/MeetingViewHeaderCard';
import { MeetingViewTopicCard } from 'components/Meetings/View/MeetingViewTopicCard';
import {
  TimerOutlined as TimerOutlinedIcon,
  ArrowBack as ArrowBackIcon,
  ListAlt as ListAltIcon
} from '@mui/icons-material';
import { RenderPrintButton } from 'components/RenderPrintButton';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import puzzleImage from 'assets/people-completing-puzzle.png';
import { calculateTopicsStartTime } from 'utils/calculateTopicsStartTime';
import { EventsTimeline } from 'components/EventsTimeline';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { StartMeetingBanner } from 'components/Meetings/StartMeetingBanner';
import { MeetingPrintVersion } from '../components/Meetings/MeetingPrintVersion';
import { MeetingLoadingOrError } from 'components/Meetings/MeetingLoadingOrError';

export const MeetingView: React.FC = () => {
  const meetingId = useParamsKey('meetingId');

  const keyPrefix = 'MeetingView';

  const { t } = useLocale();

  const {
    resource: meeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({ path: '/meetings', id: meetingId });

  const { user } = useUserShow();

  const {
    hasMeetingStarted,
    meetingDuration,
    isUserMeetingCreatorRole,
    isMeetingAgendaSent,
    canUserCurrentlyEditMeeting
  } = useMeetingStatus({
    meeting,
    user
  });

  const userHasEditAccess = Boolean(isUserMeetingCreatorRole && !hasMeetingStarted);

  const isAgendaVisible = isMeetingAgendaSent || userHasEditAccess || hasMeetingStarted;

  const topics = meeting && calculateTopicsStartTime(meeting);
  const timelineEvents = generateMeetingTimeline(meeting);
  const printComponentRef = useRef(null);

  if (!meeting) {
    return <MeetingLoadingOrError isProcessing={isProcessing} errorMessage={errorMessage} />;
  }

  return (
    <>
      <Helmet>
        <title>{`${t('common:viewMeeting')} - Panorama`}</title>
      </Helmet>
      {isAgendaVisible && (
        <Box sx={{ display: 'none' }}>
          <MeetingPrintVersion meeting={{ ...meeting, topics }} ref={printComponentRef} />
        </Box>
      )}
      <PageContainer
        sxChildren={{ padding: { xs: 3, md: '24px 56px' } }}
        className="rr-block"
        testId="meeting-view-page"
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Link underline="hover" color="inherit" component={RouterLink} to={'/'} sx={{ cursor: 'pointer' }}>
            {t('common:navigation.dashboard')}
          </Link>
          <Typography color="text.primary">{meeting?.title}</Typography>
        </Breadcrumbs>
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
            sx={{ mt: 4, mb: 2 }}
          >
            <Button
              component={RouterLink}
              to="/"
              variant="outlined"
              sx={{ minWidth: 0, px: '5px', display: { sm: 'none' }, mr: '14px' }}
              aria-label={t('common:labelBack')}
            >
              <ArrowBackIcon sx={{ color: 'primary.main' }} />
            </Button>
            <Typography
              data-cy="meeting-view_title"
              variant="h1"
              sx={{ display: 'inline-block', fontWeight: 400, fontSize: '24px' }}
            >
              {meeting?.title}
            </Typography>

            {/* STATE CHIP */}
            {meeting.minutesStartedAt && (
              <MeetingStateChip meeting={meeting} sx={{ ml: { xs: 'auto', sm: 3 } }} />
            )}

            {canUserCurrentlyEditMeeting && (
              <Button
                component={RouterLink}
                to={{
                  pathname: `/meetings/${meeting.id}/edit`,
                  state: { activePath: '/' }
                }}
                sx={{ ml: 'auto' }}
              >
                {t('common:labelEdit')}
              </Button>
            )}
          </Stack>
        </Box>

        <Box
          sx={{
            background: 'linear-gradient(to right, transparent 32px, #bdbdbd 8px)',
            backgroundSize: '40px',
            backgroundRepeat: 'no-repeat'
          }}
        >
          {/* MEETING DETAILS */}
          {meeting && <MeetingViewHeaderCard meeting={meeting} />}

          {/* TOPICS */}
          <Paper
            variant="outlined"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 3 }}
            data-cy="meeting-view_topics"
          >
            {isAgendaVisible ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3
                  }}
                >
                  <Avatar sx={{ bgcolor: '#EDF0FF' }}>
                    <ListAltIcon sx={{ color: '#151E54' }} />
                  </Avatar>
                  <Typography component="span" variant="body1" color="text.primary">
                    {t(keyPrefix + '.agendaLabel')}
                  </Typography>
                  {!hasMeetingStarted && (
                    <Chip
                      label={
                        isMeetingAgendaSent
                          ? t(keyPrefix + '.chipAgendaSent')
                          : t(keyPrefix + '.chipAgendaInPreparation')
                      }
                      color={isMeetingAgendaSent ? 'success' : 'default'}
                      data-cy="meeting-view_agenda_state-chip"
                    />
                  )}
                </Box>
                <Divider />
                {topics?.map((topic, index) => {
                  const isLastTopic = topics && topics.length && index === topics.length - 1; //fix this
                  return (
                    <>
                      <MeetingViewTopicCard topic={topic} order={index + 1} />
                      {!isLastTopic && <Divider />}
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                    py: { xs: '30px', md: '60px' },
                    px: '30px',
                    textAlign: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <Typography variant="h6" component="p" data-cy="meeting-view_agenda-in-preparation-text">
                    {t(keyPrefix + '.agendaInPreparation')}
                  </Typography>
                  <Box
                    component="img"
                    loading="lazy"
                    sx={{
                      maxWidth: '482px',
                      width: '100%'
                    }}
                    alt=""
                    src={puzzleImage}
                  />
                </Box>
              </>
            )}
          </Paper>

          <StartMeetingBanner meeting={meeting} sx={{ mt: 3 }} />
        </Box>
        {/* FOOTER */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 2,
            mt: 3
          }}
        >
          {isAgendaVisible && (
            <Box
              sx={{
                display: 'flex',
                alignSelf: 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
                flexDirection: { xs: 'column-reverse', sm: 'row' },
                gap: { xs: 2, sm: 0 }
              }}
            >
              <EventsTimeline timelineEvents={timelineEvents} />

              <Typography variant="body1" sx={{ display: 'flex' }}>
                <TimerOutlinedIcon color="action" sx={{ mr: 1 }} />
                {t(keyPrefix + '.estimatedDurationLabel', { time: meetingDuration })}
              </Typography>
            </Box>
          )}

          <Box sx={{ width: '100%' }}>
            {/* mobile back and print */}
            <Box width={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                component={RouterLink}
                to="/"
                variant="outlined"
                sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
                aria-label={t('common:labelBack')}
              >
                <ArrowBackIcon sx={{ color: 'primary.main' }} />
              </Button>

              {isAgendaVisible && (
                <RenderPrintButton
                  componentRef={printComponentRef}
                  variant="outlined"
                  sx={{ display: { sm: 'none' } }}
                />
              )}
            </Box>
            {/* desktop back and print */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2
              }}
            >
              <Button component={RouterLink} to="/" sx={{ display: { xs: 'none', sm: 'inline-flex' } }}>
                {t('common:labelBack')}
              </Button>
              {isAgendaVisible && (
                <RenderPrintButton
                  componentRef={printComponentRef}
                  variant="outlined"
                  sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </PageContainer>
    </>
  );
};

/* eslint-disable react/jsx-no-bind */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  IconButton,
  LinearProgress,
  DialogActions,
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Grid,
  Typography,
  Tooltip,
  Box
} from '@mui/material';
import cc from 'classnames';
import { useDropzone } from 'react-dropzone';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import UploadIcon from '@mui/icons-material/Upload';
import EditIcon from '@mui/icons-material/Edit';
import { useLocale as useCoreLocale } from '@koopajs/mui/dist/useCoreLocale';
import { Trans } from 'react-i18next';
import { ICoreProps } from '@koopajs/mui';

const i18nTransComponents: { [k: string]: React.ReactElement } = { div: <div /> };

export interface IImageEditorProps extends ICoreProps {
  //photoUrl?: string;
  workspaceName?: string;
  username?: string;
  appLogoSrc?: string;

  handleValueChange: (photo: Blob) => Promise<void>;
  readOnly?: boolean;
}

export const ImageEditor: React.FC<IImageEditorProps> = (props: IImageEditorProps) => {
  const { handleValueChange, readOnly, isDisabled, appLogoSrc, sx, i18n } = props;

  const { t } = useCoreLocale({ ...i18n, coreKeyPrefix: 'SettingsImageEditor' });

  const [isOpen, setIsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File>();
  const [imagePreview, setImagePreview] = useState<string>();

  useEffect(() => {
    if (!selectedImage) {
      setImagePreview(undefined);
      return;
    }

    const url = URL.createObjectURL(selectedImage);
    setImagePreview(url);

    return () => URL.revokeObjectURL(url);
  }, [selectedImage]);

  const onDrop = useCallback((dropped: File[]) => {
    setSelectedImage(dropped[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ['image/jpeg', 'image/png']
  });

  const handleClose = (): void => {
    setSelectedImage(undefined);
    setIsOpen(false);
  };

  const submit = async (e: React.BaseSyntheticEvent): Promise<void> => {
    setIsProcessing(true);

    if (handleValueChange && selectedImage) {
      await handleValueChange(selectedImage);
    }

    handleClose();
    setIsProcessing(false);
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth={true}>
        <DialogTitle>{t('dialogTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 1 }}>{t('dialogDescription')}</DialogContentText>

          <Box
            {...getRootProps()}
            sx={{
              border: isDragActive ? '1px solid #9e9e9e' : '1px dotted #9e9e9e',
              borderRadius: 5,
              textAlign: 'center',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              padding: 2,
              fontSize: '0.8em',
              color: isDragActive ? '#9e9e9e' : '#9e9e9e',
              backgroundColor: isDragActive ? '#eeeeee' : ''
            }}
            className={cc('classes.root', { ['classes.active']: isDragActive })}
          >
            {!selectedImage ? (
              <div style={{ cursor: 'pointer' }}>
                <div>
                  <AddPhotoAlternateIcon />
                </div>
                <div>
                  <Trans i18nKey="dialogContent-html" t={t} components={i18nTransComponents} />
                </div>
              </div>
            ) : (
              <div style={{ cursor: 'pointer', position: 'relative' }}>
                <IconButton sx={{ position: 'absolute', top: 0, right: 0 }}>
                  <EditIcon />
                </IconButton>
                <div>
                  <img style={{ maxWidth: 250, maxHeight: 250, marginTop: '4px' }} src={imagePreview} />
                </div>
              </div>
            )}
            <input {...getInputProps()} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={isProcessing}>
            {t('dialogCloseLabel')}
          </Button>
          <Button
            onClick={submit}
            color="primary"
            variant="contained"
            disabled={isProcessing || !selectedImage}
          >
            {t('dialogSubmitLabel')}
          </Button>
        </DialogActions>
        <LinearProgress style={{ visibility: isProcessing ? 'inherit' : 'hidden' }} />
      </Dialog>

      {!readOnly && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          onClick={() => setIsOpen(true)}
          style={{ cursor: 'pointer' }}
          sx={sx}
        >
          <Grid item>
            <Typography display="block" variant="caption" color="textSecondary">
              {t('label')}
            </Typography>

            <Typography display="block" color="textSecondary" variant="body2">
              {t('description')}
            </Typography>

            {appLogoSrc ? (
              <Grid item>
                <img
                  style={{ maxWidth: 250, maxHeight: 250, marginTop: '4px', objectFit: 'contain' }}
                  src={appLogoSrc}
                />
              </Grid>
            ) : (
              <Typography sx={{ fontStyle: 'italic' }}>{t('placeholder')}</Typography>
            )}
          </Grid>
          <Grid item>
            <Tooltip title={appLogoSrc ? t('tooltipLabelEdit') : t('tooltipLabelUpload')}>
              <IconButton disabled={isDisabled || isProcessing}>
                {appLogoSrc ? <EditIcon /> : <UploadIcon />}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}

      {readOnly && (
        <Grid container justifyContent="space-between" alignItems="center" sx={sx}>
          <Grid item>
            <Typography display="block" variant="caption" color="textSecondary">
              {t('label')}
            </Typography>

            <Typography display="block" color="textSecondary">
              {t('description')}
            </Typography>

            <img style={{ width: 250, marginTop: '4px' }} src={appLogoSrc} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

import { useLocale, useUserShow } from '@koopajs/react';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import { StartMeeting } from 'components/Modals/StartMeeting';
import { useCallback, useState } from 'react';
import { IMeeting } from 'types';
import { Stack, Tooltip, Button } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface IWriteMinutesButton {
  meeting: IMeeting;
  variant?: 'outlined' | 'contained';
}

export const WriteMinutesButton: React.FC<IWriteMinutesButton> = (props) => {
  const { meeting, variant } = props;

  const { t } = useLocale();
  const keyPrefix = 'Dashboard.MeetingCard';

  const { user } = useUserShow();
  const [isStartMeetingModalOpen, setIsStartMeetingModalOpen] = useState(false);

  const {
    isMeetingInPreparation,
    isMeetingNoticeSent,
    isMeetingAgendaSent,
    isMeetingMissingParticipants,
    hasMeetingStarted,
    isMeetingReadyToStart
  } = useMeetingStatus({
    meeting,
    user
  });

  const handleOpenStartMeetingModal = useCallback(() => {
    setIsStartMeetingModalOpen(true);
  }, []);

  const handleCloseStartMeetingModal = useCallback(() => {
    setIsStartMeetingModalOpen(false);
  }, []);

  const handleDisableStartMeetingTooltip = (): string => {
    if (isMeetingInPreparation) {
      return t(keyPrefix + '.tooltipDisableStartMeetingInPreparation');
    }
    if (isMeetingMissingParticipants) {
      return t(keyPrefix + '.tooltipDisableStartMeetingMissingParticipants');
    }
    if (isMeetingNoticeSent && !isMeetingAgendaSent) {
      return t(keyPrefix + '.tooltipDisableStartMeetingAgendaUnsent');
    } else {
      return '';
    }
  };

  return (
    <>
      <StartMeeting
        meeting={meeting}
        userId={user?.id}
        isOpen={isStartMeetingModalOpen}
        onClose={handleCloseStartMeetingModal}
      />
      <Stack
        direction={{ sm: 'row' }}
        spacing={1}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        sx={{ mt: { xs: 1, md: 0 } }}
      >
        <Tooltip title={!hasMeetingStarted ? handleDisableStartMeetingTooltip() : ''}>
          <div>
            <Button
              variant={variant}
              onClick={handleOpenStartMeetingModal}
              disabled={!isMeetingReadyToStart && !hasMeetingStarted}
              startIcon={<PlayArrowIcon />}
              data-cy="dashboard_meeting-card_write-minutes-button"
            >
              {t(`${keyPrefix}.labelWriteMinutes`)}
            </Button>
          </div>
        </Tooltip>
      </Stack>
    </>
  );
};

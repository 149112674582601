import { IUseResourceListResponse, useLocale } from '@koopajs/react';
import { ICommittee } from 'types';
import { CommitteeCard } from 'components/Organization/OrganizationCommittees/CommitteeCard';
import { Typography, Box } from '@mui/material';
import { Loading } from '@koopajs/mui';

interface IOrganizationCommitteesProps {
  committeesUseResourceList: IUseResourceListResponse<ICommittee>;
}

export const OrganizationCommittees: React.FC<IOrganizationCommitteesProps> = (props) => {
  const { t } = useLocale();
  const keyPrefix = 'Organization.OrganizationCommittees';

  const { committeesUseResourceList } = props;

  const { resources: committees, state } = committeesUseResourceList;

  return (
    <>
      <Typography variant="h2" sx={{ marginTop: '24px', fontWeight: 500, fontSize: '20px' }}>
        {t(keyPrefix + '.title')}
      </Typography>
      <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {state === 'loading' || state === 'reloading' ? (
          <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
        ) : (
          <>
            {committees.map((c) => {
              return <CommitteeCard committee={c} key={c.id} />;
            })}
          </>
        )}
      </Box>
    </>
  );
};

import { useFacet } from '@koopajs/react';
import { Box, Button, Menu, Typography } from '@mui/material';
import { useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import React, { useCallback, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { User } from '@koopajs/react';

export interface IUserFacetProps {
  path: string;
  facetKey: string;
  testId?: string;
  label: string;
}

export const UserFacet: React.FC<IUserFacetProps> = (props) => {
  const { path, facetKey, testId, label } = props;

  const facet = useFacet({ facetKey, path, isSingleValue: true });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const menuOpen = Boolean(anchorEl);

  const handleClickSelectGroup = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelectOption = useCallback(
    (itemId: string) => {
      return () => {
        facet.addFacetFilter(itemId)(undefined);
        handleCloseMenu();
      };
    },
    [facet, handleCloseMenu]
  );

  const [buttonWidth, setButtonWidth] = useState(0);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const button = buttonRef.current;
    if (button) {
      const resizeObserver = new ResizeObserver(() => {
        setButtonWidth(button.offsetWidth);
      });
      resizeObserver.observe(button);
      return () => resizeObserver.disconnect();
    }
  }, []);

  if (!facet.values || facet.values.length === 0) {
    return null;
  }
  return (
    <>
      <Button
        aria-controls={menuOpen ? `${label}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        variant="text"
        onClick={handleClickSelectGroup}
        data-cy={testId}
        ref={buttonRef}
        sx={{ color: 'inherit', typography: 'body2', textTransform: 'none' }}
        id={`${label}-button`}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2">{label}</Typography>

          <KeyboardArrowDownIcon fontSize="small" />
        </Box>
      </Button>
      <Menu
        id={`${label}-menu`}
        MenuListProps={{
          'aria-labelledby': `${label}-button`
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        PaperProps={{ style: { minWidth: `${buttonWidth}px` }, elevation: 0, variant: 'outlined' }}
      >
        {facet.values?.map((option) => (
          <MenuItem
            key={option.key}
            onClick={handleSelectOption(option.key)}
            sx={{ backgroundColor: option.key === facet.selectedValue ? '#edf0ff' : 'inherit' }}
          >
            <User id={option.key}>
              {(profile) =>
                profile ? (
                  <Typography variant="body2" component="span">
                    {profile?.username}
                  </Typography>
                ) : (
                  <Typography component="span" />
                )
              }
            </User>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

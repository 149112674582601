import React, { useState, useEffect, useCallback } from 'react';
import { useFacet, useResourceList } from '@koopajs/react';
import { Box, Typography, Button, Grow, Stack } from '@mui/material';
import { useLocale } from '@koopajs/react';
import { ICommittee } from 'types';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Api } from '@koopajs/app';
import { Link as RouterLink } from 'react-router-dom';
import { Loading } from '@koopajs/mui';

interface ICommitteeFolderListProps {}

export const CommitteeFolderList: React.FC<ICommitteeFolderListProps> = (props) => {
  const { t } = useLocale();
  const keyPrefix = 'DocumentRecords.CommitteeFolderList';

  const { resources: committees } = useResourceList<ICommittee>({
    path: '/committees'
  });

  const [committeeIdsCanUploadDocuments, setCommitteeIdsCanUploadDocuments] = useState<string[]>([]);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const facet = useFacet({ facetKey: 'committeeId.keyword', path: '/document-records' });

  //check in each committee if there are members with createDocumentRecords role
  useEffect(() => {
    const fetchCommitteeMembers = async (): Promise<void> => {
      const promises = committees.map((committee) => {
        const res = Api.client.get(
          '/committee-members?size=5&filters[]=' +
            ['roles.role:"createDocumentRecords"', `committeeId:${committee.id}`]
              .map((s) => encodeURIComponent(s))
              .join(',')
        );
        return res;
      });

      setIsProcessing(true);
      try {
        const results = await Promise.all(promises);
        const committeeIds = results
          .filter((r) => r.data.resources.length > 0)
          .map((r) => r.data.resources[0].committeeId);
        setCommitteeIdsCanUploadDocuments(committeeIds);
      } catch (err) {
        console.error(err);
      }
      setIsProcessing(false);
    };
    fetchCommitteeMembers().catch((err) => console.error(err));
  }, [committees.length]);

  //filter committees that have members with createDocumentRecords role
  const filteredCommittees = committees.filter(
    (c) => committeeIdsCanUploadDocuments.includes(c.id) || (facet.getCountForKey(c.id) || 0) > 0
  );

  const theme = useTheme();

  const isScreenAboveLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isScreenAboveXl = useMediaQuery(theme.breakpoints.up('xl'));

  const getInitialMaxCommitteesToShow = (): number => {
    if (isScreenAboveXl) return 7;
    if (isScreenAboveLg) return 5;
    return 3;
  };

  //initial number of committees to show (2 rows)
  const maxCommitteesToShow = getInitialMaxCommitteesToShow();

  const areAllCommitteesVisible = filteredCommittees.length <= maxCommitteesToShow;

  const [maxItemsToShow, setMaxItemsToShow] = useState(maxCommitteesToShow);

  useEffect(() => {
    setMaxItemsToShow(getInitialMaxCommitteesToShow());
  }, [isScreenAboveLg, isScreenAboveXl]);

  const handleShowMoreCommittees = useCallback(() => {
    setMaxItemsToShow(filteredCommittees?.length);
  }, [filteredCommittees?.length]);

  const handleShowLessCommittees = useCallback(() => {
    const newMaxCommitteesToShow = isScreenAboveXl ? 7 : isScreenAboveLg ? 5 : 3;
    setMaxItemsToShow(newMaxCommitteesToShow);
  }, [filteredCommittees?.length, maxItemsToShow]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Typography component="h2" sx={{ fontSize: '20px', fontWeight: 500 }}>
        {t(keyPrefix + '.title')}
      </Typography>
      {isProcessing ? (
        <Stack alignItems="center">
          <Loading sx={{ backgroundColor: 'transparent', position: 'static' }} />
        </Stack>
      ) : (
        <Box>
          <Box
            sx={{
              display: 'grid',
              gap: '24px',
              gridTemplateColumns: {
                xs: '1fr',
                sm: '1fr 1fr',
                md: '1fr 1fr',
                lg: '1fr 1fr 1fr',
                xl: '1fr 1fr 1fr 1fr'
              }
            }}
          >
            {filteredCommittees?.map((committee, index) => (
              <Grow
                in={index < maxItemsToShow}
                style={{ transformOrigin: '0 0 0' }}
                key={committee.id}
                {...(index < maxItemsToShow ? { timeout: 1000 } : {})}
                unmountOnExit
              >
                <Box
                  component={RouterLink}
                  data-cy="document-records_committee-folder"
                  to={`/document-records/committees/${committee.id}`}
                  role="button"
                  tabIndex={0}
                  sx={{
                    color: 'inherit',
                    textDecoration: 'inherit',
                    padding: '14px',
                    backgroundColor: '#FFF',
                    borderRadius: '4px',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: '14px',
                    cursor: 'pointer'
                  }}
                >
                  <Typography sx={{ display: 'flex' }}>
                    <FolderSharedIcon sx={{ mr: 1 }} color="action" /> {committee.name} (
                    {facet.getCountForKey(committee.id) || 0})
                  </Typography>
                </Box>
              </Grow>
            ))}

            {filteredCommittees.length > maxItemsToShow && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignSelf: 'center'
                }}
              >
                <Button size="large" onClick={handleShowMoreCommittees}>
                  {t(keyPrefix + '.showAllGroups')} <KeyboardArrowDownIcon />
                </Button>
              </Box>
            )}
            {filteredCommittees.length === maxItemsToShow && !areAllCommitteesVisible && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignSelf: 'center'
                }}
              >
                <Button size="large" onClick={handleShowLessCommittees}>
                  {t(keyPrefix + '.hideGroups')} <KeyboardArrowUpIcon />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

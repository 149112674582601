import { Loading } from '@koopajs/mui';
import { useComponentVisibility, useLocale, useParamsKey, useSearchParams } from '@koopajs/react';
import { useCallback, useEffect } from 'react';
import { IMeeting } from 'types';
import { Stack, Typography, Box } from '@mui/material';
import { EmptyState } from 'components/EmptyState';
import { CreateButton } from 'components/CreateButton';
import { Helmet } from 'react-helmet';
import { PageContainer } from 'components/temp/PageContainer';
import { FilterChips } from 'components/FilterChips';
import { NoResultsResetFilters } from 'components/NoResultsResetFilters';
import { PastMeetingsList } from 'components/PastMeetings/PastMeetingsList';
import { MobileFloatingButtons } from 'components/MobileFloatingButtons';
import { ViewPastMeeting } from 'components/Dialogs/ViewPastMeeting';
import { useResourceList } from 'components/temp/useResourceListTemp';
import { ViewMinutes } from 'components/Dialogs/ViewMinutes';
import { FacetListPastMeetingsInline } from 'components/PastMeetings/FacetListPastMeetingsInline';
import { Sidebar } from 'components/Sidebars/Sidebar';
import { FacetListPastMeetingsSidebar } from 'components/Sidebars/PastMeetings/FacetListPastMeetingsSidebar';

export const PastMeetings: React.FC = () => {
  const { t } = useLocale();
  const keyPrefix = 'PastMeetings';

  const search = useSearchParams();

  const pastMeetingId = useParamsKey('id');
  const minutesId = useParamsKey('minutesId');

  const sidebarVisibility = useComponentVisibility('sidebar');

  const handlePageChange = useCallback((page: number): void => {
    search.setSearchParams({ page });
  }, []);

  const handleSizeChange = useCallback((size: number): void => {
    search.setSearchParams({ size, page: undefined });
  }, []);

  useEffect(() => {
    search.setSearchParams({ page: undefined });
  }, [JSON.stringify(search?.searchParams?.filters)]);

  const {
    resources: pastMeetings,
    state: pastMeetingsState,
    isProcessing,
    totalCount
  } = useResourceList<IMeeting>({
    path: '/past-meetings',
    searchParams: {
      sort: 'startDateTime:DESC',
      ...search.searchParams
    }
  });

  const { resources: meetings, state: meetingsState } = useResourceList<IMeeting>({
    path: '/meetings',
    searchParams: {
      filters: ['NOT _exists_:boardApprovedAt'],
      size: 50
    }
  });

  if (pastMeetingsState === 'loading' || meetingsState === 'loading' || meetingsState === 'reloading')
    return (
      <Stack sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'absolute' }} />
      </Stack>
    );

  const hasNoMeetings = meetingsState === 'loaded-empty' || meetingsState === 'loaded-no-search-result';

  // there are no meetings and no past meetings
  if (hasNoMeetings && pastMeetingsState === 'loaded-empty')
    return <EmptyState button={<CreateButton otherButtonProps={{ disableElevation: true }} />} />;

  // there are meetings but no past meetings
  if (pastMeetingsState === 'loaded-empty' && meetingsState === 'loaded')
    return (
      <EmptyState
        subTitle={t(keyPrefix + '.emptyStateSubTitleNoMinutes')}
        description={t(keyPrefix + '.emptyStateDescriptionNoMinutes')}
      />
    );

  return (
    <>
      <Helmet>
        <title>{`${t('common:navigation.pastMeetings')} - Panorama`}</title>
      </Helmet>
      <PageContainer sxChildren={{ px: { xs: '24px', lg: '48px' }, py: '24px' }} className="rr-block">
        <Sidebar facetList={<FacetListPastMeetingsSidebar />} onHideSidebar={sidebarVisibility.setHidden} />
        <MobileFloatingButtons
          fabData={[
            {
              label: t('common:filters'),
              onClick: sidebarVisibility.setVisible
            }
          ]}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h1" sx={{ fontSize: '24px' }}>
            {t(keyPrefix + '.title')}
          </Typography>
          <FacetListPastMeetingsInline />
        </Box>
        <Box sx={{ my: '24px' }}>
          <FilterChips isProcessing={isProcessing} />
        </Box>
        {(pastMeetingsState === 'loaded' || pastMeetingsState === 'reloading') && (
          <PastMeetingsList
            meetings={pastMeetings}
            totalCount={totalCount}
            size={search.searchParams.size}
            page={search.searchParams.page}
            isReloading={pastMeetingsState === 'reloading'}
            onPageChange={handlePageChange}
            onSizeChange={handleSizeChange}
          />
        )}
        <ViewPastMeeting pastMeetingId={pastMeetingId} pathOnClose="/past-meetings" />
        <ViewMinutes minutesId={minutesId} pathOnClose="/past-meetings" />
        {pastMeetingsState === 'loaded-no-search-result' && <NoResultsResetFilters />}
      </PageContainer>
    </>
  );
};

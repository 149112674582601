import { createTheme, Theme } from '@mui/material/styles';
import '../../theme.css';

export const theme: Theme = createTheme({
  palette: {
    primary: {
      light: '#EDF0FF', // bg color
      main: '#151E54' // accent color
    },
    secondary: {
      light: '#0c79d0', // For all links
      main: '#ed6b04' // orange if needed
    },
    info: {
      main: '#95D1CC'
    },
    success: {
      main: '#C7E590'
    },
    warning: {
      main: '#F6B989'
    },
    error: {
      main: '#C5221F',
      light: '#F4433680'
    }
  },
  typography: {
    fontFamily: 'Circular Std'
  }
});

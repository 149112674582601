import { IMeeting } from 'types';
import { useCallback, useState, useRef } from 'react';
import { useUserShow, useLocale, useParamsKey, useResourceShow } from '@koopajs/react';
import { Button, Box, Stack, Typography, Paper, Divider } from '@mui/material';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { RenderPrintButton } from 'components/RenderPrintButton';
import { ViewMinutesPrintVersion } from 'components/Dialogs/ViewMinutesPrintVersion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { isCustomResNumFormat } from 'utils/isCustomResNumFormat';
import { MinutesHeaderCard } from 'components/MinutesHeaderCard';
import { generateMeetingTimeline } from 'utils/generateMeetingTimeline';
import { PageContainer } from 'components/temp/PageContainer';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import { ViewPastMeeting } from 'components/Dialogs/ViewPastMeeting';
import { ValidateMinutesButton } from 'components/Meetings/Review/ValidateMinutesButton';
import { TopicCardMinutes } from 'components/TopicCard/TopicCardMinutes';
import { EventsTimeline } from 'components/EventsTimeline';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { MeetingLoadingOrError } from 'components/Meetings/MeetingLoadingOrError';
import { useMeetingStatus } from 'components/hooks/useMeetingStatus';
import { Loading } from '@koopajs/mui';

export const i18nTransComponents: { [k: string]: React.ReactElement } = {
  span: <span style={{ color: 'grey' }} />
};

export const MeetingReview: React.FC = (props) => {
  const meetingId = useParamsKey('meetingId');
  const {
    resource: meeting,
    isProcessing,
    errorMessage
  } = useResourceShow<IMeeting>({
    path: '/meetings',
    id: meetingId
  });

  const { user: currentUser } = useUserShow();

  const { hasMeetingEnded } = useMeetingStatus({ meeting, user: currentUser });

  const history = useHistory();

  const doesUserHaveAccessToPage = hasMeetingEnded;
  if (meeting && !doesUserHaveAccessToPage) {
    history.push(`/meetings/${meetingId}`);
  }

  const topics = meeting?.topics;

  const urlKey = useParamsKey('key');
  const isAgendaVisible = urlKey === 'agenda';

  const isCustomResNum = isCustomResNumFormat(meeting?.committeeId as string);

  const componentRef = useRef(null);

  const { t } = useLocale();

  const keyPrefix = 'MeetingStateReview';

  const [topicIdEditable, setTopicIdEditable] = useState('');

  const isUserReviewer =
    currentUser?.id && meeting?.reviewers?.some((reviewer) => reviewer.userId === currentUser.id);

  const hasUserAlreadyReviewed =
    currentUser && meeting?.reviews?.map((signature) => signature.userId).includes(currentUser?.id);

  const userHasEditAccess = !meeting?.boardApprovedAt && isUserReviewer ? true : false;

  const uniqueUserReviewsCount = meeting?.reviewers?.filter((reviewers) => reviewers.latestReview).length;

  const handleSetTopicEditable = useCallback((topicId: string) => {
    return () => setTopicIdEditable(topicId);
  }, []);

  const handleUnsetTopicEditable = useCallback(() => {
    setTopicIdEditable('');
  }, []);

  const timelineEvents = generateMeetingTimeline(meeting);

  const committeeType = useCommitteeName(meeting?.committeeId as string);

  if (!meeting) {
    return <MeetingLoadingOrError isProcessing={isProcessing} errorMessage={errorMessage} />;
  } else if (!doesUserHaveAccessToPage) {
    // loading component while we wait for the redirect to the view page
    return (
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Loading sx={{ backgroundColor: 'transparent', position: 'static' }} />
      </Stack>
    );
  }
  return (
    <PageContainer
      sxChildren={{ padding: { xs: 3, md: '24px 56px' } }}
      className="rr-block"
      testId="meeting-review-page"
    >
      <Box sx={{ display: 'none' }}>
        <ViewMinutesPrintVersion minutes={meeting} topics={topics} ref={componentRef} isReview={true} />
      </Box>

      <Breadcrumbs
        hierarchyArray={[{ path: '/pending-review', text: t('common:navigation.dashboard') }]}
        pageTitle={userHasEditAccess ? t(keyPrefix + '.titleReview') : t(keyPrefix + '.titleView')}
      />

      <Box>
        <Box sx={{ mt: 4, mb: 2 }}>
          <Stack
            direction={{ xs: 'column-reverse', sm: 'row' }}
            justifyContent={{ sm: 'space-between' }}
            alignItems={{ sm: 'center' }}
          >
            <Stack direction="row" alignItems="center">
              <Button
                component={RouterLink}
                to="/"
                variant="outlined"
                sx={{ minWidth: 0, px: '5px', display: { sm: 'none' }, mr: '14px' }}
                aria-label={t('common:labelBack')}
              >
                <ArrowBackIcon sx={{ color: 'primary.main' }} />
              </Button>

              <Typography variant="h1" sx={{ display: 'inline-block', fontWeight: 500, fontSize: '20px' }}>
                {userHasEditAccess ? t(keyPrefix + '.titleReview') : t(keyPrefix + '.titleView')}
              </Typography>
            </Stack>
            {meeting?.reviewers && meeting?.reviewers?.length > 0 && (
              <Typography variant="body2" sx={{ textAlign: 'right', opacity: 0.6, mb: { xs: 1, sm: 0 } }}>
                {t(keyPrefix + '.reviewCountOfTotal', {
                  reviewsMade: uniqueUserReviewsCount,
                  reviewsPossible: meeting.reviewers.length
                })}
              </Typography>
            )}
          </Stack>
          {userHasEditAccess && currentUser && (
            <Stack alignItems={{ sm: 'flex-end' }} sx={{ mt: { xs: 3, sm: 1 } }}>
              <ValidateMinutesButton meetingId={meeting.id} isRevalidate={hasUserAlreadyReviewed} />
            </Stack>
          )}
        </Box>

        <Stack
          component={Paper}
          direction={{ md: 'row' }}
          variant="outlined"
          sx={{
            py: 1,
            px: 3,
            my: 3,
            alignItems: { xs: 'flex-start', md: 'center' },
            justifyContent: 'space-between'
          }}
          gap={1}
        >
          <Box>
            {t(keyPrefix + '.pastMeetingBannerText', { title: meeting.title, committee: committeeType })}
          </Box>
          <Button
            component={RouterLink}
            to={{
              pathname: `/meetings/${meeting.id}/review/agenda`,
              state: { activePath: '/pending-review' }
            }}
          >
            {t('common:viewMeeting')}
          </Button>
        </Stack>

        <MinutesHeaderCard minutes={meeting} userHasEditAccess={userHasEditAccess} />
        <Paper variant="outlined">
          <Stack spacing={2}>
            {topics?.map((topic, index) => {
              return (
                <Box key={topic.id}>
                  <TopicCardMinutes
                    topic={topic}
                    handleSetTopicEditable={handleSetTopicEditable}
                    order={index + 1}
                    isEditable={topic.id === topicIdEditable}
                    onSubmitSuccess={handleUnsetTopicEditable}
                    userHasEditAccess={userHasEditAccess}
                    meeting={meeting}
                    isMeeting={true}
                    isCustomResNum={isCustomResNum}
                  />
                  {index !== topics.length - 1 && <Divider sx={{ mx: 3 }} />}
                </Box>
              );
            })}
          </Stack>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <EventsTimeline timelineEvents={timelineEvents} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            my: 4,
            alignItems: 'flex-start'
          }}
        >
          <>
            <Button component={RouterLink} to="/pending-review" sx={{ display: { xs: 'none', sm: 'block' } }}>
              {t('common:labelBack')}
            </Button>
            <Button
              component={RouterLink}
              to="/pending-review"
              variant="outlined"
              sx={{ minWidth: 0, px: '5px', display: { sm: 'none' } }}
              aria-label={t('common:labelBack')}
            >
              <ArrowBackIcon sx={{ color: 'primary.main' }} />
            </Button>
            <Box sx={{ display: 'flex' }}>
              <RenderPrintButton componentRef={componentRef} variant="outlined" />

              {userHasEditAccess && currentUser && (
                <Box sx={{ ml: 2 }}>
                  <ValidateMinutesButton meetingId={meeting.id} isRevalidate={hasUserAlreadyReviewed} />
                </Box>
              )}
            </Box>
          </>
        </Box>
      </Box>

      <ViewPastMeeting
        pastMeetingId={isAgendaVisible ? meeting.id : undefined}
        pathOnClose={`/meetings/${meeting.id}/review`}
        routerStateOnClose={{ activePath: '/pending-review' }}
      />
    </PageContainer>
  );
};

import { useLocale } from '@koopajs/react';
import { TablePagination, Box } from '@mui/material';
import { useCallback } from 'react';
import { IMinute } from 'types';
import { MinutesCard } from './MinutesCard';

interface IMinutesListProps {
  minutes: IMinute[];
  size?: number;
  page?: number;
  totalCount?: number;
  isReloading: boolean;
  onPageChange: (page: number) => void;
  onSizeChange: (size: number) => void;
}

export const MinutesList: React.FC<IMinutesListProps> = (props) => {
  const { minutes: minutesList, size, page, totalCount, isReloading, onPageChange, onSizeChange } = props;

  const { t } = useLocale();
  const keyPrefix = 'Minutes.MinutesList.TablePagination';

  const handlePageChange = useCallback(
    (e: unknown, newPage: number) => {
      onPageChange(newPage);
    },
    [page]
  );

  const handleSizeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onSizeChange(Number(e.target.value)),
    []
  );

  return (
    <>
      <Box sx={{ opacity: isReloading ? 0.35 : 1 }}>
        {minutesList.map((minutes) => {
          return <MinutesCard key={minutes.id} minutes={minutes} />;
        })}
      </Box>
      <TablePagination
        // TODO: should add getItemAriaLabel with i18n for a11y users
        rowsPerPageOptions={[5, 20, 50]}
        component="div"
        // sx={{ display: { xs: 'none', md: 'block' } }}
        count={totalCount || 999}
        rowsPerPage={size || 20}
        page={page ? page : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleSizeChange}
        SelectProps={{
          native: true
        }}
        // eslint-disable-next-line react/jsx-no-bind
        labelDisplayedRows={({ from, to, count }) => {
          const transOptions = { from, to, count };

          return count !== -1
            ? t(keyPrefix + '.labelDisplayedRowsCount', transOptions)
            : t(keyPrefix + '.labelDisplayedRowsMoreThan', transOptions);
        }}
        labelRowsPerPage={t(keyPrefix + '.labelRowsPerPage')}
      />
    </>
  );
};

import React, { useCallback } from 'react';
import { useLocale } from '@koopajs/react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Divider,
  Stack,
  Link as MUILink,
  Chip
} from '@mui/material';
import { ParticipantsList } from 'components/ParticipantsList';
import { DateTimeStartEnd } from 'components/DateTimeStartEnd';
import { IMeeting } from '../../../types';
import { useCommitteeName } from 'components/hooks/useCommitteeName';
import {
  OpenInNew as OpenInNewIcon,
  MailOutline as MailOutlineIcon,
  CalendarToday as CalendarTodayIcon,
  LocationOn as LocationOnIcon
} from '@mui/icons-material';

interface IMeetingViewHeaderCardProps {
  meeting: IMeeting;
}

export const MeetingViewHeaderCard: React.FC<IMeetingViewHeaderCardProps> = (props) => {
  const { meeting } = props;
  const keyPrefix = 'MeetingView.MeetingViewHeaderCard';

  const { t } = useLocale();
  const meetingType = useCommitteeName(meeting?.committeeId as string);

  return (
    <Paper sx={{ p: 3, my: 3, borderRadius: '4px' }} variant="outlined">
      <Box sx={{ display: { sm: 'flex' } }}>
        <List sx={{ p: 0, display: 'flex', flexDirection: 'column', gap: 3, width: '100%' }}>
          <ListItem sx={{ p: 0 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 3
              }}
            >
              <Avatar sx={{ bgcolor: '#EDF0FF' }}>
                <MailOutlineIcon sx={{ color: '#151E54' }} />
              </Avatar>
              <Typography component="span" variant="body1" color="text.primary">
                {t(keyPrefix + '.noticeLabel')}
              </Typography>
              {!meeting.minutesStartedAt && (
                <Chip
                  label={
                    meeting.invitationSentAt
                      ? t(keyPrefix + '.chipNoticeSent')
                      : t(keyPrefix + '.chipNoticeInPreparation')
                  }
                  color={meeting.invitationSentAt ? 'success' : 'default'}
                  data-cy="meeting-view_notice_state-chip"
                />
              )}
            </Box>
          </ListItem>
          <Divider />
          <ListItem sx={{ p: 0 }}>
            <Typography
              sx={{
                display: 'inline',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                minWidth: '0px'
              }}
              component="span"
              variant="h6"
              color="text.primary"
              data-cy="meeting-view_header-card_title"
            >
              {meeting.title} | {meetingType}
            </Typography>
          </ListItem>
          <ListItem sx={{ p: 0 }}>
            <Stack key="date" sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <CalendarTodayIcon color="action" sx={{ opacity: 1 }} fontSize="small" />
              <Typography variant="body2" sx={{ display: 'inline' }} color="text.secondary">
                <DateTimeStartEnd
                  isoStringDate={meeting?.startDateTime}
                  dateFormat="DATE_FULL_NO_YEAR"
                  separator={` ${t('common:labelFrom2')} `}
                  isoStringStart={meeting.startDateTime}
                  isoStringEnd={meeting.endDateTime}
                />
              </Typography>
            </Stack>
          </ListItem>

          {(meeting.location?.type || meeting.location?.url || meeting.location?.address) && (
            <ListItem
              sx={{
                p: 0,
                alignItems:
                  meeting.location?.type === 'hybrid' || meeting.location?.type === 'inPerson'
                    ? 'flex-start'
                    : 'center',
                gap: 1,
                flexWrap: 'wrap',
                flexDirection: {
                  xs: meeting.location?.type === 'hybrid' && meeting.location.url ? 'column' : 'row',
                  sm: 'row'
                }
              }}
            >
              <>
                <Typography variant="body2" color="text.secondary">
                  {t(`common:locationType.${meeting.location?.type}`)}
                </Typography>
              </>{' '}
              {meeting.location?.url && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      mx: 3,
                      display: { xs: meeting.location?.type === 'hybrid' ? 'none' : 'block', sm: 'block' }
                    }}
                  >
                    &#183;
                  </Box>
                  <MUILink
                    href={meeting.location?.url}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                      color: 'secondary.light'
                    }}
                    target="_blank"
                    rel="noopener"
                  >
                    <OpenInNewIcon fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="body2">{t(keyPrefix + '.meetingLink')}</Typography>
                  </MUILink>
                </Box>
              )}{' '}
              {meeting.location?.address && (
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Box
                    sx={{
                      mx: 3,
                      display: {
                        xs: meeting.location.type === 'inPerson' || !meeting.location.url ? 'block' : 'none',
                        sm: 'block'
                      }
                    }}
                  >
                    &#183;
                  </Box>
                  <Typography sx={{ display: 'inline', opacity: '0.6' }} variant="body2">
                    <Box sx={{ whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'start' }}>
                      <LocationOnIcon fontSize="small" sx={{ mt: '2px', mr: 0.5, opacity: '0.6' }} />
                      <Box>{meeting.location?.address}</Box>
                    </Box>
                  </Typography>
                </Box>
              )}
            </ListItem>
          )}

          <ListItem alignItems="flex-start" sx={{ flexDirection: 'column', p: 0 }}>
            <ListItemText
              primary={t('common:guests')}
              primaryTypographyProps={{ variant: 'caption', sx: { opacity: 0.6 } }}
            />
            {meeting.participants && meeting.participants.length > 0 && (
              <ParticipantsList participants={meeting.participants} />
            )}
          </ListItem>
        </List>
      </Box>
    </Paper>
  );
};
